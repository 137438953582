import {
  PaletteMode,
  ThemeOptions,
} from '@mui/material'

import { MuiSwitchOptions } from './components/mui-switch-options'

type TOwnerState = {
  color: 'primary' | 'secondary' | 'warning'
  variant: 'outlined' | 'contained' | 'text'
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    //md: 768,
    // lg: 1024,
    // xl: 1280,
    md: 900,
    lg: 1280,
    xl: 1440,
  },
}

export const palette = {
  primary: {
    main: '#1F5F8D',
    light: '#DFF1FF',
    contrastText: '#fff',
    gradient: // deprecated, use background.primary.gradient instead
      'linear-gradient(136deg, rgba(0, 82, 180,1) -10.24%, rgba(29, 231, 183, 0.70) 212.35%)',
    disabled: '#c6c6c6',
  },
  secondary: {
    main: '#00BD90',
    contrastText: '#fff',
  },
  info: {
    main: 'rgb(240 240 240/var(--tw-bg-opacity))',
    contrastText: '#000',
  },
  warning: {
    main: '#F3BA2F',
    light: '#fae0a2',
    contrastText: '#fff',
  },
  error: {
    main: '#EB5757',
    light: '#FDE9EE',
  },
  grey: {
    600: '#2D2F38',
    500: '#666666',
    400: '#878990',
    300: '#C6C6C6',
    200: '#EAEAEA',
    100: '#F8F8F8',
  },
  dark_green: {
    main: '#090F35',
  },
  neutral_black: {
    main: '#1E1E1E',
  },
  text: {
    primary: '#2D2F38',
    tertiary: '#666666',
  },
  background: {
    default: '#F4F6F6',
    primary: {
      main: '#1F5F8D',
      gradient:
        'linear-gradient(136deg, rgba(0, 82, 180,1) -10.24%, rgba(29, 231, 183, 0.70) 212.35%)',
      disabled: '#c5dff2',
    },
  },
}
const interFontFamily = 'Inter Variable'
const montserratFontFamily = 'Montserrat Variable'

const typography = {
  fontFamily: interFontFamily,
  h1: {
    fontFamily: montserratFontFamily,
    color: palette.text.primary,
    fontSize: '30px',
    '@media (min-width: 768px)': {
      fontSize: '36px',
    },
    fontWeight: 700,
  },
  h2: {
    fontFamily: interFontFamily,
  },
  h3: {
    fontFamily: interFontFamily,
  },
  h4: {
    fontSize: '32px',
    fontWeight: 700,
    color: palette.grey[600],
    fontFamily: montserratFontFamily,
  },
  h5: {
    fontSize: '28px',
    fontWeight: 700,
    color: palette.grey[600],
    fontFamily: montserratFontFamily,
  },
  h6: {
    fontFamily: interFontFamily,
    fontWeight: 700,
  },
  body1: {
    fontFamily: interFontFamily,
    color: palette.text.primary,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: interFontFamily,
    color: palette.text.tertiary,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
}

const components = {
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: () => ({
        color: '#999999',
        '&.Mui-checked': {
          color: palette.primary.main,
        },
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: TOwnerState }) => ({
        textTransform: 'none',
        borderRadius: '52px',
        '&.Mui-disabled': {
          background:
            ownerState.variant === 'contained' ? palette[ownerState.color].main : 'inherit',
          border:
            ownerState.variant === 'outlined'
              ? `1px solid ${palette[ownerState.color].main}`
              : 'inherit',
          color:
            ownerState.variant === 'contained'
              ? palette[ownerState.color].contrastText
              : palette.primary.disabled,
          opacity: ownerState.variant === 'contained' ? 0.8 : 0.6,
        },
      }),
    },
    variants: [
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          border: 'none',
          background: palette.background.primary.gradient,
          '&.Mui-disabled': {
            background: palette.background.primary.disabled,
          },
        },
      },
      {
        props: { variant: 'contained', color: 'info' },
        style: {
          border: 'none',
          backgroundColor: palette.info.main,
          '&:hover': {
            backgroundColor: '#1A4F75 !important',
            color: 'white',
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          outline: '1px solid #1f5f8d',
          border: 'none !important',
          color: `${palette.primary.main}`,
          '&:hover': {
            outline: '1px solid #FFFFFF',
            background: palette.background.primary.gradient,
            color: 'white',
          },
          '&.Mui-disabled': {
            background: 'transparent',
            outline: 'none',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          border: 'none',
          background: 'transparent',
          color: palette.text.primary,
          '&:hover': {
            background: 'transparent',
          },
        },
      },
      {
        props: { variant: 'text', color: 'info' },
        style: {
          color: palette.primary.main,
        },
      },
      {
        props: { size: 'large' },
        style: {
          padding: '16px 16px',
          borderRadius: '52px',
          fontSize: '18px',
          fontWeight: '600',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          padding: '12px 16px',
          fontSize: '16px',
          fontWeight: '600',
        },
      },
      {
        props: { size: 'small' },
        style: {
          padding: '8px',
          fontSize: '14px',
        },
      },
    ],
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
      size: 'medium',
    },
    
  },
  MuiSwitch: MuiSwitchOptions,
}

const getThemeOptions = (mode: PaletteMode) =>
({
  palette: {
    mode,
    ...palette,
  },
  typography,
  breakpoints,
  components,
} as ThemeOptions)

export default getThemeOptions
