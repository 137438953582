import Rollbar from 'rollbar'

export const rollbarClientConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  nodeSourceMaps: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
}

export const rollbarClientInstance = new Rollbar(rollbarClientConfig)

export const rollbarServerInstance = new Rollbar({
  accessToken: process.env.ROLLBAR_SERVER_TOKEN,
  environment: process.env.NODE_ENV,
  nodeSourceMaps: true,
})
