import { useSession } from 'next-auth/react'

const useIwAuth = () => {
  const { data: session, status } = useSession()
  const isLoggedIn = status === 'authenticated'

  return { status, isLoggedIn, currentUser: session?.user }
}

export default useIwAuth
