// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale = {
  header: {
    vietnamese: 'Tiếng Việt',
    english: 'English',
    home: 'Home',
    referral: 'Exclusive deals',
    wire_race: 'Wire Race',
    transaction: 'Transaction',
    transaction_init: 'Transfer',
    transaction_history: 'Transaction history',
  },
  transaction: {
    title: 'Transactions',
    in_progress: 'In progress',
    completed: 'Completed',
    sending: 'Sending',
    cancelled: 'Cancelled',
    failed: 'Failed',
    title_detail: 'Transactions details',
    transfer_money: 'Transfer money',
    tracking: 'Tracking',
    details: 'Details',
    you_set_up_transfer: 'You set up your transfer',
    waiting_for_your_money: 'We are waiting for your money',
    waiting_for_your_money_description:
      "If you’ve sent the money from your bank to Rewire, we will let you know when it's arrived",
    receive_usd: 'We receive your USD',
    payout_vnd: 'We pay out your VND',
    recipient_receives_vnd: 'Your recipient receives the VND',
    cancel_transfer: 'Cancel transfer',
    back_to_home: 'Back to home',
    information: 'Transaction information',
  },
  transfer: {
    bank_account_number: 'Bank account number',
    bank_name: 'Bank name',
    bank_account_name: 'Bank account name',
    email: 'Email',
    phone_number: 'Phone number',
    next: 'Next',
    add_recipient_bank: "Add recipient's bank",
    information: 'information',
    review: 'Review',
    review_description: 'Please double check information before continue to payment',
    recipient: 'Recipient',
    amount: 'Amount',
    you_send: 'You send',
    recipient_receives: 'Recipient receives',
    change: 'Change',
    continue_to_payment: 'Continue to payment',
    more: 'More',
    less: 'Less',
    fee: 'Fee',
    amount_to_be_converted: 'Amount to be converted',
    Rate_24h: 'Rate (24hs)',
    tolerance: 'Slippage tolerance',
  },
  home: {
    swap: 'Swap',
    transfer_to_friends: 'Transfer to friends and family',
    low_fee_easy: 'Low Fee. Easy',
    swap_for: 'for',
    slippage_tolerance: 'Slippage tolerance',
    fee: 'Fee',
    minimum_received: 'Minimum received',
    minimum_received_help_text:
      'Even if the price of the tokens shifts due to other trades, you will receive at least this amount or the transaction will revert. This amount is determined by your slippage tolerance',
    swap_completed: 'Swap completed',
    swap_failed: 'Swap failed',
    you_swapped: 'You swapped',
    for_approximately: 'for approximately',
    view_on_renec_explorer: 'View on RENEC Explorer',
    enter_amount: 'Enter an amount',
    insufficient_balance: 'Insufficient balance',
    maximum: 'Maximum',
    minimum: 'Minimum',
    rate: 'Rate',
    price_impact: 'Price impact',
    get_started: 'Get Started',
    you_send: 'You Send',
    recipient_receive: 'Recipient will receive',
    price_impact_warning: 'Price impact warning',
    reduce_the_swapping_amount: 'Reduce the swapping amount to lower the price impact',
    price_impact_help_text:
      'The difference between the market price and estimated price due to trade size',
    transaction_fee_in_renec_notice: (fee: string) =>
      `Renec Foundation will cover the gas fee of ${fee} RENEC so you can enjoy a hassle-free transaction experience.`,
    swap_error_troubleshooting: {
      title: 'Troubleshooting',
      descriptions: {
        hint_1: 'Try again with at least 0.01 RENEC in your wallet',
        hint_2: 'Or try again with a smaller swap amount to avoid large slippage',
      },
    },
    sign_in: 'Sign In',
    sign_up: 'Sign Up',
    sign_up_description: 'Join us and explore a whole new world of international money transfers!',
    sign_in_with_google: 'Sign in with Google',
    facebook_continue: 'Facebook (coming soon)',
    apple_continue: 'Apple (coming soon)',
    send_money: 'Send money abroad',
    zero_fee: 'With Zero Fee. Instantly',
    coming_soon: 'Coming soon',
    draft_transaction: 'Draft Transaction',
  },
  sell: {
    sell_btn: 'Sell',
    sell_reVND_to_VND: 'Sell reVND to VND',
    input_info_remind: 'Input your info',
    my_info: 'My info',
    my_contact_info: 'My contact info',
    withdraw_to: 'Withdraw to',
    buyer_awaiting: 'Buyer awaiting',
    payment_awaiting: 'Payment awaiting',
    in_dispute: 'In dispute',
    in_release: 'Seller released',
    btn_release: 'Release',
    btn_cancel: 'Cancel',
    btn_dispute: 'Dispute',
    phone_number: 'Phone number',
    bank_name: 'Bank name',
    bank_account_number: 'Bank account number',
    bank_account_name: 'Bank account name',
    form_validation: {
      required: (fieldName: string) => `${fieldName} is required.`,
      invalid: (fieldName: string) => `${fieldName} is invalid.`,
      should_not_contains_special_characters: (fieldName: string) =>
        `${fieldName} should not contains special characters.`,
    },
    save: 'Save',
    btn_release_help_text: (bond: string) =>
      `If you have received the payment, click on Release to get back ${bond} Bond.`,
    btn_dispute_help_text: 'if you have not received the payment.',
    more_details: 'More details',
    trade_ref: 'Trade ref',
    buyer_address: 'Buyer address',
    copied_buyer_address_snackbar: 'Copied buyer address to clipboard.',
    buyer_has_kyc: 'Buyer has KYC with Remitano',
    for_approximately: 'for approximately',
    sell_fail: 'Sell order failed',
  },
  deposit: {
    buy_btn: 'Buy',
    buy_reVND_btn: 'Buy reVND',
    no_trades_order_found: 'No trades order found.',
    bank_name: 'Bank name',
    bank_account_number: 'Bank account number',
    bank_account_name: 'Bank account name',
    transfer_note: 'Content',
    reference: 'Reference',
    transfer_title: (fiatAmount: string, fiatCurrency: string) =>
      `Please pay <span style='font-weight:600;color:white'>${fiatAmount}</span> ${fiatCurrency} to the bank account below.`,
    hide_bank_title: 'Hide bank account',
    show_bank_title: 'Show bank account',
    warning_connect_wallet: 'Please connect wallet to see order information',
    not_found_order: 'This order could not be found',
    want_to_buy: 'I want to buy',
    scan_qr_code_to_complete_transaction: 'Scan the following QR code to complete the transaction.',
    made_payment_btn: 'I had paid',
    made_payment_popup_title: 'Wait for confirmation',
    made_payment_popup_msg: (waitingTime: number) =>
      `Please wait for the buyer to confirm the payment within a ${waitingTime}-minute duration.`,
    wait_confirmation_btn: 'Wait buyer confirmation',
    dispute_popup_title: 'Disputed trade',
    dispute_popup_msg:
      "The arbitrator will contact you and the seller through Chat or the provided Contact Information. You will receive reVND if arbitrator can verify the proof of your payment. The seller's bond will serve as the fee for the arbitrator.",
    request_arbitration_btn: 'Request arbitration',
    keep_waiting_btn: 'Keep waiting',
  },
  common: {
    balance: 'Max',
    estimated: 'Estimated',
    details: 'Details',
    connect_wallet: 'Connect wallet',
    how_to_use_nemo: 'How to use NemoSwap?',
    disconnect: 'Disconnect',
    copied: 'Copied',
    copy_address: 'Copy address',
    connect: 'Connect',
    connecting: 'Connecting',
    copy: 'Copy',
    buy: 'Buy',
    connected: 'Connected',
    change_wallet: 'Change wallet',
    token_picker_filter_by: 'Search',
    token_picker_no_tokens_found: 'No tokens found',
    bond: 'Bond',
    bond_tooltip: 'Receive your bond back upon successful transaction',
    token_picker_please_select: 'Input your token mint address here',
    please_wait: 'Please wait...',
    copied_address_snackbar: 'Copied token address to clipboard.',
    failed: 'Failed',
    something_wrong: 'Something went wrong. Please try again later!',
    policy: {
      terms_of_service: 'Terms of Service',
      terms_of_use: 'Terms of use',
      privacy_policy: 'Privacy Policy',
      first: 'By registering, you accept our',
      and: 'and',
      last: '',
    },
    powered_by: 'Powered by RENEC Blockchain',
    login: 'Login',
    logout: 'Logout',
    expand_less: 'See less',
    expand_more: 'See more',
    no_data: 'No data',
    loading: 'Loading...',
    just_a_moment_loading_data: 'Just a moment, loading data...',
    bonus: 'Bonus',
  },
  wallet_ui: {
    dialog_heading: 'Connect a wallet to continue',
    first_time_connect_notice: 'For first time connect, you might need to Create Wallet first.',
    install_demon_notice_heading: 'Install Demon wallet',
    install_demon_notice_body:
      'It will take you to a new website to install the Demon Wallet. Once the installation is complete, please refresh NemoSwap.',
    detected: 'Detected',
    not_detected: 'Not detected',
  },
  meta: {
    home: {
      title: 'Instantwire | Official Website',
      description: 'Safe and fast wire transfers locally with 0 fee',
      thumb: '/thumb-share-ins-en.png',
    },
    account: {
      title: 'Instantwire | Account Management',
      description: 'Manage your account on Instantwire for safe and smooth wire transfers',
    },
    landing: {
      title: 'Instantwire | Free Wire Transfer',
      description:
        'Free global wire transfer service, utilizing the peer-to-peer transfer model combined with blockchain security',
    },
    login: {
      title: 'Instantwire | Login',
      description:
        'Sign up or Login to start your journey with our free, safe and smooth wire transfering service',
    },
    referral: {
      title: 'Instantwire | Referral Program',
      description:
        'Refer friends to create a money transfer order, and both of you will receive benefits. Unlimited sharing, unlimited benefits.',
      social_share_description:
        'Tham gia ngay danh sách chờ của Instantwire để nhận thật nhiều phần thưởng hấp dẫn và không giới hạn – càng giới thiệu nhiều, càng nhận thưởng lớn!',
    },
    term_and_conditions: {
      title: 'Instantwire | Term',
      description:
        'These Terms and Conditions govern your access to and use of the Instantwire Site and Services',
    },
    privacy_policy: {
      title: 'Instantwire | Privacy Policy',
      description:
        'Privacy policy govern your access to and use of the Instantwire Site and Services',
    },
    transaction_history: {
      title: 'Instantwire | Transaction History',
      description:
        'Track your global wire transfer transaction history with completed and absolutely accurate information',
    },
    transfer_detail: {
      title: 'Instantwire | Transfer Detail',
      description: 'View details of wire transfer transaction with absolutely accurate information',
    },
    transfer_init: {
      title: 'Instantwire | Transfer Initiate',
      description: 'Start your free wire transfer transaction with hassle-free interface',
    },
    wire_race: {
      title: 'Instantwire | Wire Race Event',
      description:
        'Join the Instantwire waitlist and climb the ranks for a chance to win big! The more friends you invite, the larger the prize becomes.',
    },
    bank_detail: {
      title: 'Instantwire | Recipient Detail',
      description: 'Fill in your Recipient Detail to transfer money on Instantwire',
    },
    kyc: {
      title: 'KYC on Bridge',
      start_title: 'Start KYC on Bridge',
      start_button: 'Start verifying to get $3 now',
      first_part_description: 'You’re about to start KYC with ',
      second_part_description:
        ', Instantwire’s trusted KYC partner. Bridge will securely verify your identity to ensure a safe and compliant experience on our platform. Rest assured, your information is handled with the highest standards of privacy and security.',
      verify_status: 'Verification Status',
      status_pending: 'PENDING APPROVAL',
      status_pending_description: 'Your document is being processed.',
      back_to_home: 'Back to home',
      status_rejected: 'FAILED',
      status_rejected_description: 'Your document needs to be updated.',
      verify_again: 'Verify again',
      status_approved: 'APPROVED',
      continue: 'Continue',
      check_latest_status: 'Check latest status',
      account_temporary_blocked: 'Account temporary blocked',
      kyc_blocked_third_failed:
        'Your KYC attempt has failed for the third time. Please wait <b>1 hour</b> before trying again.',
      kyc_blocked_fourth_failed:
        'Your fourth KYC attempt has failed. Please wait <b>24 hours</b> before trying again.',
      kyc_blocked_fifth_failed:
        'Your account has been temporarily locked due to exceeding the maximum number of KYC verification attempts (5 attempts). For security reasons and to prevent fraudulent activities, you will need to contact our Customer Support team to review and potentially unlock your account.',
      unusual_activity_detected: 'Unusual Activity Detected',
      unusual_activity_detected_desc_1:
        "We've detected multiple failed KYC verification attempts from this device. For security reasons, KYC verification has been temporarily disabled for this device.",
      unusual_activity_detected_desc_2:
        'If you believe this is an error, please contact our support team.',
      unusual_activity_detected_desc_3:
        'Note: This restriction applies to all accounts accessing from this device.',
      unavailable_title: 'KYC Service Not Available in Your Region',
      unavailable_desc:
        'Currently, Instantwire does not support the identity verification (KYC) process in your country or territory. We are working to expand our services and will notify you as soon as support becomes available in your region.',
    },
  },
  main_menu: {
    home: 'Home',
    transactions: 'Transactions',
    recipients: 'Recipients',
    settings: 'Settings',
  },
  error: {
    back_to_home: 'Back to Home',
    err_404: {
      title: 'Oop! Nothing here...',
    },
    err_500: {
      title: 'System Error!',
      description: 'Apologies for the inconvenience, we are currently undergoing on it!',
    },
    maintenance: {
      title: 'System Maintenance',
      description: 'Apologies for the inconvenience, we are currently undergoing maintenance!',
    },
  },
  footer: {
    title: 'Discover the Difference',
    description:
      'Instantwire enables quick, secure, and affordable transfers from the United States to Vietnam, making financial connections with loved ones easy.',
    main_menu: 'Main Menu',
    main_menu_home: 'Home',
    main_menu_exclusive_deals: 'Exclusive deals',
    main_menu_wire_race: 'Wire Race',
    company_others: 'Others',
    company_policy: 'Privacy policy',
    company_terms: 'Terms of service',
    privacy_policy: 'Private policy',
    about_us: 'About us',
  },
  pre_footer: {
    title: 'Skip the wait, send money in a flash!',
    description: 'Enjoy the freedom of instant transfers and skip the unnecessary hassle',
    send_money_now: 'Send money now',
  },
  liquidity: {
    loading_positions: 'Loading positions...',
    no_positions_found: 'No positions found.',
    position: {
      status: 'Status:',
      status_above_range: 'Above range',
      status_in_range: 'In range',
      status_below_range: 'Below range',
      current_amount: 'Current value:',
      uncollected_fees: 'Uncollected fees:',
      apr: 'APR:',
      stop: 'Stop',
      stopping: 'Stopping...',
    },
    create_pool_form: {
      header: 'Create new pool',
      button_submit: 'Create',
      button_submitting: 'Creating...',
      initial_price: 'Initial price',
      fee_tier: 'Fee tier for initial deposit',
      success_message: 'Pool has been successfully created and submitted for review.',
      error_message: 'Failed to create or submit pool for review. Please try again.',
      message_dialog_button_close: 'Close',
      button_pool_duplicate: 'Pool already exists',
      swap_prices: 'Swap prices',
    },
    bar: {
      pools: 'Pools',
      portfolio: 'Portfolio',
      create_pool_button: 'Create Pool',
    },
    pool: {
      total_liquidity: 'Total liquidity',
      volume_24h: '24h volume',
      APR: 'APR',
      deposit: 'Deposit',
    },
    pool_search_placeholder: 'Token mint address, token name or pool address',
    no_results_found: 'No results found matching your search criteria.',
    no_pools_found: 'No pools found.',
    open_position: {
      add_new_position: 'Add new Position',
      deposit_ratio: 'Deposit ratio',
      current_price: 'Current price',
      min_price: 'Min price',
      max_price: 'Max price',
      max_balance: 'Max',
      total: 'Total',
      deposit_button: 'Deposit',
      revert_prices_icon: 'Revert prices',
      errors: {
        must_be_gt_min_price: 'Must be > Min price',
        must_be_lt_max_price: 'Must be < Max price',
        must_be_a_number: 'Must be a number',
      },
    },
  },
  quota: {
    copied_address_snackbar: 'Copied address to clipboard.',
    profile: 'Profile',
    level: 'Level',
    kyc: 'KYC',
    now: 'now',
    unverified: 'Unverified',
  },
  core_tokens_list: {
    title: 'Top tokens on NemoSwap',
    table_headers: {
      token_name: 'Token name',
      price: 'Price',
      about: 'About',
      action: 'Action',
    },
    token_detail: {
      title: 'Token info',
      stats: 'Stats',
      volume_24h: '24H volume',
      link: 'Link',
      website: 'Website',
      close: 'Close',
    },
    search_placeholder: 'Search by name, symbol, or mint address',
    more: 'More',
    trade: 'Trade',
    detail: 'Detail',
  },
  terms_of_use: 'Terms of use',
  survey: {
    back: 'Back',
    description:
      'With great exchange rate and zero currency conversion fees, <span class="font-bold">Instantwire</span> helps you effortlessly and safely exchange your local currency and send money to your loved ones abroad.',
    answer_question: 'To join to the wait list, please answer 4 questions below.',
    thank_you: 'Thank you',
    added_email: 'We have added your email address to the wait list.',
    priority_access: 'Interested in priority access?',
    invite:
      'Invite your friends and family to join <span class="font-bold">Instantwire</span>. The more you refer, the earlier you will get access.',
    share_now: 'Share now',
    share_via: 'Share this link via:',
    copy_link: 'Or copy link',
    copy: 'Copy',
    copied: 'Copied!',
    done: 'Done',
    submit_error: 'Something went wrong. Try again',
    answer_required_question: 'Please answer all the questions',
    people_ahead_of_you: (counter: number) =>
      `<span class="font-extrabold">${counter.toLocaleString('en')}</span> People ahead of you`,
    reservation_held: (email: string) =>
      `This reservation is held for <span class="font-bold">${email}</span>`,
  },
  landing: {
    hero: {
      slogan: 'Connect Globally, Transfer Locally',
      connect:
        'Bringing the world closer to you through P2P transactions, we ensure your money stays secure within your country and reaches recipients worldwide swiftly.',
      work: 'See How We Work',
      reward: 'Get the <b>"0 Fee money transfer"</b> offers for your first order.',
      no_of_users_created_transaction: 'users have created money transfer requests',
      no_fee: 'No transfer fee',
      arrive: "Money will reach the Recipient's bank account within",
      arrive_time: '15 minutes',
      arrive_description:
        'Transactions requiring additional verification may take longer, up to 24 hours.',
    },
    hero_waitlist: {
      title_wait_list: (isMobile: boolean) =>
        isMobile
          ? 'Connect globally, transfer locally with <br/><span class="text-iw-secondary">0 fee</span>'
          : 'Connect globally, transfer<br/> locally with <span class="text-iw-secondary">0 fee</span>',
      title: (isMobile: boolean) =>
        isMobile
          ? 'Send Money <br/>from the United States to Vietnam<br/> <span class="text-iw-secondary">within 15 minutes</span>'
          : 'Send Money from the United States to Vietnam <span class="text-iw-secondary">within 15 minutes</span>',
      with_no_fee: '<span class="text-iw-secondary">0 fee</span>',
      description:
        'Easily send and convert USD to VND directly to bank accounts in Vietnam – fast, secure, and cost-effective.',
      supported_country: 'Available at',
      join_wait_list_gmail: 'Join waitlist and earn rewards',
      users_on_the_wait_list: 'users have joined the waitlist. Totally free!',
      view_intro: 'Watch introduction',
    },
    stats: {
      title: 'Numbers that showcase our success',
      items: [
        { title: '200+', description: 'Transactions' },
        { title: '97%', description: 'Client satisfaction' },
        { title: '< 8hrs', description: 'Average transfer time' },
        { title: '100+', description: 'Amazing clients' },
      ],
    },
    early_benefits: {
      title: 'Early access benefits',
      items: [
        { title: 'Skip the line', description: 'Be among the first to experience Instantwire' },
        { title: 'Earn top rewards', description: 'Top referrers get $10+!' },
        { title: 'Points that pay off', description: 'Redeem points for cash or cool stuff' },
        { title: 'Know it first', description: 'Get exclusive news and perks.' },
      ],
    },
    why_to_choice: {
      title: 'Why choose <span class="text-iw-primary">Instantwire</span>?',
      description:
        'Instantwire leverages blockchain technology to provide a fast, secure, and affordable money transfer solution, allowing you to confidently connect financially with your loved ones.',
      items: [
        {
          title: 'Fast Transfers',
          description:
            'Funds sent from the U.S. to Vietnam reach the Recipient’s account instantly, with a maximum of only 15 minutes.',
        },
        {
          title: 'Top Security',
          description:
            'With high-level security technology and 24/7 customer support, Instantwire protects your information and funds, giving you peace of mind with every transfer.',
        },
        {
          title: 'Cost-Effective',
          description:
            'Instantwire offers attractive transfer fees, helping you save on costs while ensuring speed and security for each transaction.',
        },
      ],
      send_money_now: 'Send money now',
      title_gap_cta:
        'Create a money transfer in just moments with <span class="text-iw-primary">Instantwire</span>',
    },
    hero_all_set: {
      title: 'You are all set!',
      description:
        'Your email has been successfully added to our sign-up list. Stay tuned for more exciting news coming your way!',
      follow_us: 'Follow us on social media',
      kyc_now: 'KYC now',
      later: 'Later',
      complete_kyc: 'Complete KYC and Earn $3 Instantly!',
    },
    hero_ompetition: {
      title: 'Hot competition ahead!',
      description:
        'users are already hustling to climb the leaderboard and grab their share of up to 30 USD! Join the action, climb the ranks, and claim your victory prize!',
      check_it_now: 'Check it now',
      people_ahead_you: 'People ahead you',
      behind_you: 'Behind you',
    },
    join: {
      join_instantwire: 'Be a Part of Instantwire',
      join_instantwire_description:
        'Join our global community to stay informed, receive support, and become part of a worldwide financial network',
    },
    footer: {
      different: 'Embrace the Difference',
      join_and_investigate:
        'Join us and explore a whole new world of international money transfers!',
      zero_fee: 'Claim Your Free First Transaction - No Fees Applied!',
      register_now: 'Register Now',
    },
    question_answer: {
      title: 'Frequently Asked Questions',
      tabs: {
        general: 'General',
        before_you_transfer: 'Before you transfer',
        during_the_transfer: 'During the transfer',
        after_the_transfer: 'After the transfer',
      },
      details: {
        general: [
          {
            question: 'What is Instantwire?',
            answer:
              'Instantwire is a P2P and blockchain-based platform that allows you to send money internationally with <strong>zero fees</strong>, <strong>faster speeds</strong>, and <strong>greater flexibility</strong> compared to traditional money transfer services.',
          },
          {
            question: 'How does Instantwire work?',
            answer:
              'InstantWire connects you directly with someone sending the opposite way, all in a secure online marketplace' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li><strong>Matching making:</strong> Once you verify the details of the person receiving the funds and decide on the amount, Instantwire pairs you with someone looking to transfer funds the other way.</li>' +
              ' <li><strong>Encryption:</strong> After you make the payment for your money transfer request, Instantwire will encrypt the amount and securely store it in your account using blockchain technology.</li>' +
              ' <li><strong>Seamless conversion:</strong>The encrypted amount will be converted into the recipient’s national currency at the market exchange rate.</li>' +
              ' <li><strong>Domestic transaction:</strong>To send money, initiate a payment to another user within your country thanks to the matchmaking. The recipient will receive money from a user in the same country who needs to make a transfer in the opposite direction.</li>' +
              '</ul>' +
              "<br/>To gain a better understanding, let's examine the simulated example below:" +
              '<br/><br/><img src="/images/instantwire-work.jpg" alt="instantwire-work" />' +
              "<br/><br/>When Bob, who lives in the U.S., wants to send $50 to Hung in Vietnam, InstantWire doesn't process this cross-border transfer directly. Instead, the system finds a corresponding transaction within its internal network." +
              "<br/><br/>In this example, Alice, also living in the U.S., receives an equivalent amount of $50 converted from 1,200,000 VND at a similar USD-VND exchange rate, sent by Nam from Vietnam. This way, Nam's transfer to Hung is matched with Bob's transfer going the opposite direction." +
              '<br/><br/>To complete the payment for his transaction, Bob pays $50 to Alice, who lives in the U.S. just like Bob; and Nam pays 1,200,000 VND by bank transfer to Hung to fulfill his part of the international transaction, both residing in Vietnam.' +
              '<br/><br/>Instantwire has a holding mechanism for any transfer amount waiting to be transacted. In the example given, Alice must confirm that she has received the money from Bob before Nam proceeds to make the payment to Hung. This escrow service adds another layer of protection that comes with using Instantwire. Using escrow minimizes the possibility of thefts, scams, and other actors stealing your assets without completing their part of the transaction.',
          },
          {
            question: 'How do I get started with Instantwire?',
            answer:
              '<br/>1. Visit the website.' +
              '<br/>2. Login with your Gmail account.' +
              '<br/>3. Complete the KYC (Know Your Customer) process if required.' +
              '<br/>4. Start sending and receiving money!',
          },
          {
            question: 'What currencies are supported?',
            answer:
              'Instantwire is now accessible in Vietnam and the United States, supporting transactions in VND and USD. We plan to include additional currencies going forward.',
          },
        ],
        before_you_transfer: [
          {
            question:
              'Is Instantwire faster and cheaper than traditional money transfer services for sending money from the United States to Vietnam?',
            answer:
              'Absolutely! Instantwire offers faster transfers (usually minutes) with <strong>zero fees</strong>, while traditional services can take days and charge hefty fees.',
          },
          {
            question: 'What are the minimum and maximum amounts I can send with Instantwire?',
            answer:
              'The minimum amount you can deposit is 500,000 VND or $20.' +
              '<br/>The maximum amount you can send in one transfer depends on the status of your account verification as follows:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li>Without account verification:' +
              '   <br/><span class="ml-10">- VND: 5,000,000</span>' +
              '   <br/><span class="ml-10">- USD: $250</span>' +
              ' </li>' +
              ' <li>With phone number verified:' +
              '   <br/><span class="ml-10">- VND: 10,000,000</span>' +
              '   <br/><span class="ml-10">- USD: $500</span>' +
              ' </li>' +
              ' <li>With face and identity verified:' +
              '   <br/><span class="ml-10">- VND: 100,000,000</span>' +
              '   <br/><span class="ml-10">- USD: $5000</span>' +
              ' </li>' +
              '</ul>' +
              '<br/>However, in a single day, you cannot transfer more than 400,000,000 VND or $15,000.',
          },
          {
            question: 'Is Instantwire safe and secure?',
            answer:
              'Yes! They use blockchain technology and secure encryption to protect your transactions and personal information.' +
              '<br/>Additionally, Instantwire has set forth the following requirements to ensure safe transactions:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li><strong>Strict identity verification</strong>' +
              '   <br/><span class="ml-10">- Facial recognition</span>' +
              '   <br/><span class="ml-10">- Background information</span>' +
              '   <br/><span class="ml-10">- Basic information</span>' +
              ' </li>' +
              ' <li><strong>Instantwire enforces tight risk management</strong> by limiting transaction amounts based on identifying information, requiring several facial verification points, and conducting anti-money laundering checks to keep your money secure.</li>' +
              ' <li><strong>24/7 customer support. </strong> Instantwire provides round-the-clock online customer service to ensure your concerns are addressed quickly and easily.</li>' +
              '</ul>',
          },
          {
            question: 'Are there any hidden fees or charges associated with using Instantwire?',
            answer:
              "We believe in <strong>zero surprises</strong>. You won't encounter any hidden fees or additional charges beyond what's clearly displayed during your transfer initiation.",
          },
          {
            question: 'How is the exchange rate determined, and who sets it?',
            answer:
              'The exchange rate is based on live market data, reflecting real-time fluctuations in currency values. We update the rate every minute, ensuring you get the current market price without manipulation.',
          },
          {
            question: 'Are there any additional costs for receiving money in Vietnam?',
            answer:
              "Your recipient receives the full amount exchanged directly into their bank account or any account that provide by you. They won't incur any additional fees at the receiving end.",
          },
          {
            question: 'Do I need to pay any taxes on money received through Instantwire?',
            answer:
              'Taxes on received money depend on local regulations of recipient’s country. We <strong>recommend consulting with a tax advisor</strong> for specific guidance on potential tax liabilities. Instantwire is not responsible for any taxes applicable to received funds.',
          },
        ],
        during_the_transfer: [
          {
            question: "Can I choose the person I'm matched with?",
            answer:
              'No, Instantwire automatically finds the best match based on your transfer details and available users. This ensures a fair and secure system for everyone.',
          },
          {
            question: 'How do I pay for the transfer?',
            answer:
              'You can complete your payment using bank transfer in your local currency. You may use any payment method supported in your country for this transaction.' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li>If you are in Vietnam, you can make a bank transfer using a banking app, or e-wallets like MoMo and ZaloPay.</li>' +
              ' <li>If you are in the USA, you can transfer funds through banking apps, Zelle, Venmo, or e-wallets such as PayPal and Google Pay.</li>' +
              '</ul>' +
              '<br/>Please note, to avoid the risk of losing money, you should take a picture of the payment confirmation as proof when you hit the "I have made the transfer" button on Instantwire.',
          },
          {
            question:
              'What happens to my transferred funds if there is a technical issue with the platform?',
            answer:
              "In the rare case of a technical issue, the money you've transferred will be safely secured in an escrow account. We have contingency plans to swiftly address technical problems, ensuring the safety and integrity of your funds. Our 24/7 customer support team is also ready to assist you throughout the money transfer process." +
              '<br/><br/>Instantwire has a holding mechanism for any transfer amount waiting to be transacted. This escrow service adds another layer of protection that comes with using Instantwire. Using escrow minimizes the possibility of thefts, scams, and other actors stealing your assets without completing their part of the transaction.',
          },
          {
            question: "What if I need to cancel a transfer after I've already initiated it?",
            answer:
              "We understand that things don't always go as planned. Here are answers to some common scenarios you might encounter:<br/>" +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              " <li><strong>Before Matching:</strong> You can cancel a transfer anytime before it's matched with another user. No fees are applied.</li>" +
              " <li><strong>After matching, and you haven't paid for any invoice:</strong> You can cancel the transaction at any time before it gets matched with another user. There are no cancellation fees.</li>" +
              ' <li><strong>After matching, and you have made a payment for at least one invoice:</strong> You will not be able to cancel the transaction once a payment has been made. Therefore, make sure to carefully review the transaction before processing the payment.</li>' +
              '</ul>',
          },
          {
            question: "What happens if my recipient's bank account information is incorrect?",
            answer:
              "If you entered incorrect bank details, we'll try to reach you for confirmation. If the information is confirmed incorrect, the transfer might be reversed, and cancellation fees may apply. Double-checking recipient information is crucial to avoid delays or potential issues.",
          },
          {
            question:
              'Why do I have to make multiple payments for multiple bills when transferring money?',
            answer:
              "Instantwire operates on a matching mechanism to connect users who want to transfer money between the US and Vietnam. In an ideal situation with a perfect match, you would only have to make a payment for a single bill when there's another user who matches the exact amount you want to transfer to your recipient." +
              '<br/><br/>However, there are situations where you might have to make multiple payments because Instantwire needs to combine several users moving money in the opposite direction to you in order to match the total amount precisely with what you intend to transfer to your recipient. Some common scenarios include:' +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              " <li>The amount you're transferring is relatively large</li>" +
              " <li>At the moment you're transferring, Instantwire can't find any other user transferring the exact amount you are" +
              '</ul>' +
              '<br/>Thus, the process of matching your transfer request can happen more quickly. In this case, you will find yourself making different payments to different people within the same country as you.' +
              '<br/><br/>Please note, Instantwire ensures that you will not have to pay more than 3 bills for a single money transfer transaction.',
          },
          {
            question: 'Why do I have to pay someone else instead of the actual recipient?',
            answer:
              "Instantwire operates through a pairing mechanism that connects users looking to transfer money between the two countries, the USA and Vietnam, and works on a peer-to-peer (P2P) transaction basis. To understand why let's look at the simulated example below:" +
              '<br/><br/><img src="/images/instantwire-work.jpg" alt="instantwire-work" />' +
              "<br/><br/>When Bob, who lives in the U.S., wants to send $50 to Hung in Vietnam, InstantWire doesn't process this cross-border transfer directly. Instead, the system finds a corresponding transaction within its internal network." +
              "<br/><br/>In this example, Alice, also living in the U.S., receives an equivalent amount of $50 converted from 1,200,000 VND at a similar USD-VND exchange rate, sent by Nam from Vietnam. This way, Nam's transfer to Hung is matched with Bob's transfer going the opposite direction." +
              '<br/><br/>To complete the payment for his transaction, Bob pays $50 to Alice, who lives in the U.S. just like Bob; and Nam pays 1,200,000 VND by bank transfer to Hung to fulfill his part of the international transaction, both residing in Vietnam.' +
              '<br/><br/>Instantwire has a holding mechanism for any transfer amount waiting to be transacted. In the example given, Alice must confirm that she has received the money from Bob before Nam proceeds to make the payment to Hung. This escrow service adds another layer of protection that comes with using Instantwire. Using escrow minimizes the possibility of thefts, scams, and other actors stealing your assets without completing their part of the transaction.',
          },
          {
            question: 'How to Ensure Your Transaction is Error-Free and Risk-Free',
            answer:
              'After finding a suitable match, remember to pay attention to a few important things. Firstly, ensure you have thoroughly read the terms and conditions of the transaction before proceeding.' +
              '<br/><br/>Be aware that the payment requests shown on Instantwire at the "You Pay" step have a payment window (usually lasting from 15 minutes to 3 hours depending on the seller\'s settings). Do not start a transaction if you are in the middle of something important, such as a meeting or a dinner date.' +
              '<br/><br/><strong>Watch the countdown timer</strong>' +
              '<br/>When Instantwire finds a suitable match offer for you, a countdown timer will start, letting you know how much time you have left to make the payment and mark the transaction as paid. Always double-check the amount before sending the money.' +
              '<br/><br/><strong>Completing the order</strong>' +
              '<br/>There is one more step after sending the money. Mark the order as paid by clicking the "I have sent the money" button. After this step, Instantwire will begin the currency exchange process and ask the sender on the other end to proceed with the payment to your recipient.',
          },
          {
            question:
              'What to note when making a payment after the system has found a matching transaction:',
            answer:
              '' +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              ' <li>The bank account details of the recipient shown on Instantwire must match those displayed in your banking app.</li>' +
              ' <li>For transfers to other banks, you must use the fast transfer service available 24/7.</li>' +
              ' <li>The transfer description must adhere strictly to the requirements and precisely match the information shown on Instantwire.</li>' +
              ' <li>Only press the "I have transferred the money" button after you have completed the transfer.</li>' +
              '</ul>',
          },
          {
            question:
              'I noticed a "Dispute Resolution" message appearing after I confirmed the payment and uploaded the evidence.',
            answer:
              'If you\'ve completed the payment and clicked "I have transferred the money" but then Instantwire shows a "Dispute Resolution" message, your first step should be to contact the Customer Care Center through the chatbox feature. After ensuring that you have followed the Platform\'s regulations and correctly performed the required procedures (this includes, but is not limited to, providing related legal documents that meet the platform’s guidelines), you can submit a complaint about the transaction.' +
              '<br/><br/>The Instantwire team will assist in resolving your dispute based on the rules for handling complaints in case a user submits one. At this point, Instantwire, acting as an arbitrator, will have the authority to interpret and make the final decision regarding the sanctioning of complaint cases. The reopening of a claim will be at the sole discretion of Instantwire, based on each specific case.',
          },
          {
            question:
              'Do I need to click "I have transferred the money" after making a payment, and is it necessary to upload a proof of payment photo?',
            answer:
              'Instantwire operates on a Peer-to-Peer (P2P) model. This transaction method allows you and the person sending you money, after successfully pairing up, to exchange assets directly with each other. Instantwire acts as an intermediary, holding on to all funds awaiting transactions within its network. This escrow service adds an additional layer of protection for Instantwire users. By using the escrow service, it helps reduce the risk of theft, fraud, and the chance of losing assets to malicious parties before fulfilling their respective obligations in the transaction.' +
              '<br/><br/>Let\'s revisit the example, "Why do I need to make a payment to someone else instead of the intended recipient?" Alice must confirm that she has received the money from Bob before Nam can proceed to pay Hùng. Therefore, clicking "I have transferred the money" and providing proof of payment are mandatory prerequisites for your recipient in another country to receive the money you wish to transfer, facilitated by someone paired up with you from the opposite end.',
          },
          {
            question:
              'Why do I need to confirm that "Recipient has received the money" after Instantwire announces the money transfer to the recipient? ',
            answer:
              'Instantwire operates on a Peer-to-Peer (P2P) model. This transaction method allows you and the person sending you money, after successfully pairing up, to exchange assets directly with each other. Instantwire acts as an intermediary, holding on to all funds awaiting transactions within its network. This escrow service adds an additional layer of protection for Instantwire users. By using the escrow service, it helps reduce the risk of theft, fraud, and the chance of losing assets to malicious parties before fulfilling their respective obligations in the transaction.' +
              '<br/><br/>Reviewing the example in the question, "Why do I have to pay for someone else instead of my own recipient?", Alice must confirm she has received money from Bob before Nam can make a payment to Hung. Therefore, clicking "Recipient has received the money" is mandatory and a prerequisite for Instantwire to execute the payment order. This means you are required to pay the recipient of the sender who has been matched with you, and this recipient is also in the same country as you.' +
              '<br/><br/>To confirm whether the recipient has successfully received the money, you can choose from the following methods:' +
              '<ul class="list-decimal list-inside space-y-2 mt-4">' +
              ' <li>As the checker, you indirectly confirm on behalf of the recipient.' +
              '   <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '     <li>a. You directly contact the recipient through chat apps (Messenger, Whatsapp, Zalo, etc.) or by phone to ask them to check their account to see if the money has arrived.</li>' +
              '     <li>b. The recipient will then proactively inform you of the result.</li>' +
              '     <li>c. Once informed, you return to Instantwire to hit the "Recipient has received the money" button.</li>' +
              '   </ul>' +
              ' </li>' +
              ' <li>The recipient directly checks and confirms:' +
              '   <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '     <li>a. The recipient receives the transaction information sent to them through' +
              '       <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '         <li>i. A link to the transaction details page, which you share with them via chat apps like Messenger, WhatsApp, Zalo, etc. </li>' +
              '         <li>ii. The Instantwire system sends the recipient a link to the money transfer information page via email, but only if you provide the recipient\'s email address under the section "Enter recipient information."</li>' +
              '       </ul>' +
              '     </li>' +
              '     <li>b. They check their account balance and actively confirm by pressing "I have received the money" on the instruction screen. </li>' +
              '   </ul>' +
              ' </li>' +
              '</ul>' +
              '<br/><br/>Please note, never click on "I have received the money"  if your recipient directly confirming the receipt, or "Recipient has received the money" if you are confirming on behalf of your recipient, under these circumstances:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li>You have not yet made contact with the recipient</li>' +
              " <li>The money has not been fully credited to the recipient's account</li>" +
              ' <li>The amount received does not match the transaction amount shown by Instantwire</li>' +
              '</ul>' +
              '<br/>Keep these instructions in mind to avoid disputes that could delay the money transfer process and might even result in a loss of funds.' +
              '<br/>In case of any disputes, please immediately contact Instantwire’s 24/7 customer support team for assistance.',
          },
        ],
        after_the_transfer: [
          {
            question: 'How long will it take for my recipient to receive the money?',
            answer:
              'In most cases, your recipient will receive the money within minutes, significantly faster than traditional methods. However, it can be influenced by bank processing times in Vietnam.',
          },
          {
            question:
              "What if my recipient doesn't receive the money after the expected timeframe?",
            answer:
              "Don't panic! Just contact Instantwire's friendly support team. They are available 24/7 to help you troubleshoot any issues.",
          },
          {
            question: 'Does my recipient need an Instantwire account to receive the money?',
            answer:
              "No, your recipient only needs a bank account in Vietnam. They won't need to install any software or deal with cryptocurrency.",
          },
        ],
      },
    },
    how_it_works: {
      title: 'How it works?',
      description:
        'InstantWire connects you directly with someone sending the opposite way, all in a secure online marketplace',
      items: [
        {
          title: 'Matching making',
          description:
            'Once you verify the details of the person receiving the funds and decide on the amount, Instantwire pairs you with someone looking to transfer funds the other way.',
        },
        {
          title: 'Encryption',
          description:
            'After you make the payment for your money transfer request, Instantwire will encrypt the amount and securely store it in your account using blockchain technology.',
        },
        {
          title: 'Seamless conversion',
          description:
            'The encrypted amount will be converted into the recipient’s national currency at the market exchange rate.',
        },
        {
          title: 'Domestic transaction',
          description:
            'To send money, initiate a payment to another user within your country thanks to the matchmaking. The recipient will receive money from a user in the same country who needs to make a transfer in the opposite direction.',
        },
      ],
    },
    steps_to_follow: {
      title: 'How to transfer money',
      description:
        'Immediately after creating an account and personal identification, follow these steps to transfer money:',
      steps: [
        {
          title: '01. Account Verification',
          description:
            'Log in with your Gmail account and complete identity verification in just a few minutes to ensure security and safety for each of your transactions.',
          image: 'steps-to-follow-1',
        },
        {
          title: '02. Create a Transfer Request',
          description:
            "Enter details of the transfer amount and the Recipient's bank account in Vietnam to create your transfer request.",
          image: 'steps-to-follow-2',
        },
        {
          title: '03. Payment',
          description:
            'Choose your preferred payment method and make the transfer according to the bank details displayed on Instantwire to complete your request.',
          image: 'steps-to-follow-3',
        },
        {
          title: '04. Track and Complete the Transaction',
          description:
            'Instantwire will transfer the funds to the recipient’s account in Vietnam as quickly as possible. You can track the transaction status and confirm completion once the Recipient has received the full amount in their account.',
          image: 'steps-to-follow-4',
        },
      ],
    },
    partners: {
      title: 'Strategic Partners',
      description:
        'Closely partnered with reputable platforms, we are committed to delivering fast transactions with optimal fees',
    },
    as_easy_as_it_get: {
      title: 'As easy as it get',
      description: 'Once you’ve registered and verified your ID, all you need to do is:',
      tabs: [
        { title: 'Choose how much to send', description: 'Send money between US and Viet Nam' },
        {
          title: 'Add the receiver’s details',
          description: 'Send money directly to receiver’s local bank account',
        },
        { title: 'Pay for the transfer', description: 'Send money from your bank account' },
        {
          title: 'Track the transfer',
          description: 'Check with your receiver and confirm the completed transfer',
        },
      ],
      details: [
        {
          title: 'Choose how much to send',
          description: 'Send money between US and Viet Nam',
          items: [
            'Enter your transfer amount in your local currency',
            'Check the exchange rate',
            'Confirm to start',
          ],
          note: '<strong class="text-iw-saffron-yellow">Note:</strong> The exchange rate will change every minutes as market fluctuation',
        },
        {
          title: 'Add the receiver’s details',
          description: 'Send money directly to receiver’s local bank account',
          items: [
            'Provide your receiver information:<br/>' +
              '<ul class="list-disc list-inside space-y-2 mt-2 pl-0">' +
              ' <li>Bank account</li>' +
              ' <li>Bank name</li>' +
              ' <li>Bank account holder</li>' +
              '</ul>',
            'Confirm to start',
          ],
          note: '<strong class="text-iw-saffron-yellow">Note:</strong> You could save your receiver for next times transfer without enter new information again',
        },
        {
          title: 'Pay for the transfer',
          description: 'Send money from your bank account',
          items: [
            'Complete your payment via bank transfer',
            'Take a screenshot to upload your payment confirmation',
          ],
          note: '<strong class="text-iw-saffron-yellow">Note:</strong> Always make sure to capture a screenshot of your payment confirmation. <br/>If your requested amount is large, you may need to make multiple transfers to different matched individuals.',
        },
        {
          title: 'Track the transfer',
          description: 'Check with your receiver and confirm the completed transfer',
          items: [
            'Contact your recipient to confirm the status of the transaction.',
            'Press the confirm button to finish',
          ],
          note: '<strong class="text-iw-saffron-yellow">Note:</strong> Your receiver will get money from other person in the target country',
        },
      ],
    },
    about_us: {
      title: 'Excited to know more about us?',
      description:
        'Ready for a Completely New Cross-Border Money Transfer Experience: Simple, Fast, and Limitless!',
    },
  },
  wire_race: {
    hero: {
      title: '<span class="text-iw-secondary">Wire Race</span> Champion <br/> League',
      description:
        'Join the Instantwire waitlist and climb the ranks for a chance to win big! The more friends you invite, the larger the prize becomes.',
      sub_description:
        'Ready to wire your way to the top? Conquer the Task board, rack up Wire Points, and ascend the ranks to unlock rewards and bragging rights!',
      go_to_task_board: 'Go to Task board',
      round_finished: 'Round 2 has ended!',
      next_round: (date: string) =>
        `Let's eagerly await the occurrence of Round 3 on <span class="text-iw-secondary">${date}</span>`,
    },
    reward: {
      title: 'The higher you rise, the richer<br/>the reward',
      items: [
        { title: 'Top 3 winners', amount: '24', unit: 'USD', get_more: 'Get extra 10 USD' },
        { title: 'Top 20 winners', amount: '12', unit: 'USD', get_more: 'Get extra 5 USD' },
        { title: '20 lucky users', amount: '6', unit: 'USD', get_more: 'Get extra 2.5 USD' },
        { title: 'More rewards', amount: '14+', unit: 'wires', get_more: 'Get wirepoint' },
      ],
    },
    question_answer: {
      title: 'Frequently Asked Questions',
      details: [
        {
          question: 'How can I win the Wire Race Champion League?',
          answer:
            "The key to winning lies in <strong>referring friends and referees who complete draft transactions!</strong> Every referral registration earns you points, but even more points come when they make their first draft transaction. Remember, there's a new leaderboard and fresh chance to win every two weeks!",
        },
        {
          question: 'What exactly are "Wire points" and how do they work?',
          answer:
            'Think of Wire points as your key to climbing the leaderboard. You earn them by completing various tasks like creating an account, verifying KYC, joining the community, and of course, referring friends. The more points you collect, the higher your rank on the leaderboard and the closer you are to victory!',
        },
        {
          question: 'How much can I win?',
          answer:
            "It's not just bragging rights on the line – you can win real USD cash! The prize pool grows based on how many referees join each round. More friends making draft transactions means more money in the pot, reaching up to a maximum of $1,837.5! Plus, there might even be an Epic round with even bigger prizes!",
        },
        {
          question: 'Does it cost anything to join?',
          answer:
            'Nope! Joining the Wire Race Champion League is completely free. Just sign up, complete tasks, and start referring your friends. The only investment you need is your enthusiasm and competitive spirit!',
        },
        {
          question: 'I already have some Wire Points. Do I lose them when the leaderboard resets?',
          answer:
            "No, you won't lose any Wire Points you've earned! Here's how it works:" +
            '<ul class="list-disc list-inside space-y-2 mt-4">' +
            " <li><strong>Points from one-time tasks:</strong> Points earned from tasks you can only complete once (like registering an account or completing KYC) are <strong>permanent</strong> and will <strong>contribute to your ranking in every round.</strong> These points won't be affected by the leaderboard reset.</li>" +
            " <li><strong>Points from repeatable tasks:</strong> Points earned from tasks you can do multiple times (like inviting friends) are <strong>temporary</strong> and <strong>will reset every two weeks</strong> before the next round starts. These points don't affect your leaderboard rank, but they <strong>accumulate in your overall Wire Point balance.</strong></li>" +
            '</ul>',
        },
        {
          question: 'So, I can keep earning points even after the reset?',
          answer:
            'Absolutely! Points from repeatable tasks may reset on the leaderboard, but they still add to your total Wire Point balance. You can track these points in the offer page and use them for future redemptions when the product launches.',
        },
        {
          question: 'How does this affect my ranking in different rounds?',
          answer:
            'Your one-time task points (registering, KYC, etc.) will give you a head start in each round without needing to complete those tasks again. However, your rank will depend mainly on your points earned from repeatable tasks during that specific round.' +
            "<br/><strong>Here's a quick example:</strong><br/>" +
            '<ul class="list-disc list-inside space-y-2 mt-4">' +
            ' <li><strong>Round 1:</strong> You have 5 points from registering and KYC. You earn another 10 points from inviting friends. Your total rank-contributing points are 15 (5 + 10).</li>' +
            ' <li><strong>Round 2:</strong> The leaderboard resets. You still have your 5 points from one-time tasks. You earn 15 points from inviting friends in Round 2. Your total rank-contributing points are now 20 (5 + 15).</li>' +
            '</ul><br/>' +
            '<strong>Remember:</strong> While the leaderboard resets ensure everyone has a fair chance each round, your one-time task points give you a lasting advantage!',
        },
      ],
    },
    gap_cta: {
      excited_to: 'Excited to',
      bigger_prizes: 'bigger prizes?',
      description:
        "To foster the success of our campaign and to sincerely thank you, we're offering an opportunity to experience a 150% boost in prize value for both you and the community!",
      learn_more: 'Learn more',
    },
    leader_board: {
      title: 'Leaderboard',
      description:
        'See your name rise above the clouds on our leaderboard. The higher you climb, the brighter your victory shines!',
      note: 'Note: Kindly take note that the Wire race will be refreshed every two weeks in anticipation of the upcoming thrilling competition. Please be assured that all Wire Points you have accumulated will be fully retained!',
      position: 'Position',
      user: 'User',
      email: 'Email',
      hero_points: 'Wire points',
    },
    task_board: {
      title: 'Tasks board',
      description:
        "Task by task, point by point, you'll build your way to the top of the leaderboard.",
      tasks_completed: 'Tasks completed',
      tasks_progress: (completed: number, total: number) =>
        `<span class="font-extrabold text-iw-secondary text-[2rem] sm:text-5xl">${completed}</span><span class="font-bold text-iw-charcoal-black text-lg sm:text-[1.75rem]">/${total}</span>`,
      items: [
        {
          name: 'first_login',
          title: 'Register an account',
          conditions: (isDoublePoint: boolean) => [
            'Get yourself added to the waitlist and take the first step to create your own account with Instantwire',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } Wire points`,
          ],
        },
        {
          name: 'first_transaction',
          title: 'Create 1st Transaction',
          conditions: (isDoublePoint: boolean) => [
            'With 1st transaction (minimum $20), you have a chance to reach higher rank in Wire Race Leaderboard',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+5</b> <b class="text-iw-secondary">+10</b>'
                : '<b class="text-iw-secondary">+5</b>'
            } Wire points`,
          ],
        },
        {
          name: 'invite_friends',
          title: 'Invite friends to join',
          conditions: (isDoublePoint: boolean) => [
            'Send this link to your friends and make sure they sign up to get wire point for you.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } Wire points (referee sign-up)`,
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+2</b> <b class="text-iw-secondary">+4</b>'
                : '<b class="text-iw-secondary">+2</b>'
            } Wire points (referee create transaction)`,
          ],
        },
        {
          name: 'telegram_joined',
          title: 'Join Instantwire Telegram',
          conditions: (isDoublePoint: boolean) => [
            'Join our Telegram community for regular updates, exclusive deals, and valuable discussions with fellow users.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } Wire points`,
          ],
        },
        {
          name: 'twitter_followed',
          title: 'Follow Instantwire Twitter',
          conditions: (isDoublePoint: boolean) => [
            'Follow our official Twitter account to stay updated with the latest news and enjoy exciting offers.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } Wire points`,
          ],
        },
        // {
        //   name: 'kyc_completed',
        //   title: 'Complete KYC',
        //   conditions: (isDoublePoint: boolean) => [
        //     'Verify your account',
        //     `${isDoublePoint ? '<b class="text-iw-rose-red line-through">+2</b> <b class="text-iw-secondary">+4</b>' : '<b class="text-iw-secondary">+2</b>'} Wire points`,
        //   ],
        // },
        {
          name: 'facebook_followed',
          title: 'Follow Instantwire Facebook',
          conditions: (isDoublePoint: boolean) => [
            'Follow our official Facebook to stay updated with the latest news and enjoy exciting offers.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+3</b> <b class="text-iw-secondary">+6</b>'
                : '<b class="text-iw-secondary">+3</b>'
            } Wire points`,
          ],
        },
      ],
      completed: 'Completed',
      do_now: 'Do now',
    },
    referee: {
      title: "The referee party's on you, the reward's on us!",
      description:
        "Every friend you bring strengthens your claim to a prize that's fit for the champion. By introducing others to our platform, you'll not only contribute to its growth, but also qualify for a significantly larger prize.",
      reward_value: 'Reward value:',
      elite_league: {
        title: 'Elite League',
        current_prize_pool: '',
        amount: '$1.255',
        description:
          '100% of winners will gain this prize. Refer more friends to increase the prize value and get higher rewards.',
        rewards: [
          '<span class="text-iw-primary font-semibold">20 USD</span> for each in Top 3',
          '<span class="text-iw-primary font-semibold">10 USD</span>  for each in Top 20',
          '<span class="text-iw-primary font-semibold">5 USD</span> for each 20 lucky users',
        ],
      },
      premier_league: {
        title: 'Premier League',
        current_prize_pool: 'Current prize pool',
        amount: '$1.506',
        description: 'Refer more friends to increase the prize value and get higher rewards.',
        rewards: [
          '<span class="text-iw-primary font-semibold">24 USD</span> for each in Top 3',
          '<span class="text-iw-primary font-semibold">12 USD</span>  for each in Top 20',
          '<span class="text-iw-primary font-semibold">6 USD</span> for each 20 lucky users',
        ],
      },
      rising_stars: {
        title: 'Rising Stars Challenger League',
        current_prize_pool: '',
        amount: '$1.882',
        description: (users: number) =>
          `Need <span class="text-[#F3BA2F] font-semibold">${users.toLocaleString()}</span> more referees to get a <span class="text-iw-secondary font-semibold">150%</span> increase in prize value.`,
        rewards: [
          '<span class="text-iw-primary font-semibold">30 USD</span> for each in Top 3',
          '<span class="text-iw-primary font-semibold">15 USD</span>  for each in Top 20',
          '<span class="text-iw-primary font-semibold">7.5 USD</span> for each 20 lucky users',
        ],
      },
    },
    champion_roster: {
      title: 'Champion Roster',
      description: 'Congratulation top winners',
      top_winners: 'Top 20 winners',
      top_lucky_users: '20 lucky users',
      unfinished_event: 'Unfinished event ...',
      round: 'Round',
      wire_point: (total: number) =>
        `<span class="text-iw-secondary font-semibold">${total.toLocaleString()}</span> Wire point`,
      referees: (total: number) =>
        `<span class="text-iw-secondary font-semibold">${total.toLocaleString()}</span> referees`,
    },
    wire_point_benefits: {
      title: 'Wire point benefits',
      description:
        "Wire Points are more than just a loyalty point; they're a symbol of our commitment to rewarding your participation and fostering a mutually beneficial platform. Start earning today and unlock a world of value and possibilities.",
      items: [
        {
          image: '/images/benefits/wire-race-benefits-en-1.png',
          title: 'Turn Wire Points into cash rewards',
          description:
            'Turn your Wire Points into cash rewards! Every 25 points you accumulate becomes a cool 1 USD, ready to be claimed after the product launch',
        },
        {
          image: '/images/benefits/wire-race-benefits-en-2.png',
          title: 'Unlock the fast lane for money transfer',
          description:
            'Unlock the fast lane for your finances! Redeem Wire Points to access premium transfer services and watch your transactions zip through in minutes.',
        },
        {
          image: '/images/benefits/wire-race-benefits-en-3.png',
          title: 'Wire Points today, Wire tokens tomorrow',
          description:
            'Accumulating Wire Points now grants you early access to the potential benefits of Wire tokens and engagement with the Renec blockchain ecosystem.',
        },
        {
          image: '/images/benefits/wire-race-benefits-en-4.png',
          title: 'Wire Points just got VIP status',
          description:
            'Redeem your Wire Points for exclusive deals and offers across the Renec ecosystem, turning points into privileges.',
        },
      ],
    },
  },
  instantwire_transaction: {
    referral_benefit: {
      refer_friend: 'Refer Your Friends, Get Extra Benefits',
      description:
        'Refer friends to create a money transfer order, and both of you will receive benefits. Unlimited sharing, unlimited benefits.',
      you_get: 'You get',
      free_wire: ' Randomly earn <b>1-5 Wire</b> points.',
      friend_get: 'Your friend get',
      kyc_success: '<b>3</b> Wire Points after successfully completing the KYC process',
      create_transaction: '<b>5</b> Wire Points for initiating their first draft transaction',
      share_link: 'Shared link',
      code: 'Referral code',
      you_received: 'Your rewards',
      wire_point: 'Wire points',
      total_accumulated: 'Expected cashback amount',
      referral_information: 'Referral Information',
      successful_referral: 'Successful Referrals',
      transfer_created: 'Money Transfer Orders Created',
      success_referree_amount: (amount: number) => `${amount} friends`,
      success_transaction_amount: (amount: number) => `${amount} transaction(s)`,
      login_to_see_link: 'Login to see link',
      login_to_see_code: 'Login to see your code',
      check_reward: 'Check your rewards.',
      wire_point_accumulate:
        'Wire points are earned from money transfer transactions or by referring friends to participate on Instantwire.',
      wire_point_convert:
        'Wire Points can be converted into your local currency and withdrawn directly to your bank account or used for future money transfer transactions.',
      wire_point_valid:
        'Kindly be aware that the total Wire points you accumulate will only be valid after you complete the identity verification (KYC) process upon the official launch of Instantwire. Further details about Wire points will also be disclosed at this time.',
      understood: 'Understood',
      refund_info:
        'You will receive a cashback after your downline makes a successful transaction.',
      share_referral_code: 'Share referral code',
      or_share_via: 'Or share via',
      referrer_benefit_info: () =>
        "Earn <b><span style='color:#00BD90'>$2</span></b> for each successful friend KYC verification, with unlimited referrals.",
    },
    referral_progress: {
      refer_friend: 'How to Successfully Refer Friends',
      description: 'Just 3 simple steps for you and your friends to enjoy rewards!',
      share_link: '1. Share Your Referral Link',
      sign_up: 'Log in or sign up with your email, then share your referral link with your friends',
      invite_friend: '2. Invite Friends to Create a Money Transfer',
      use_the_link:
        'Ask your friends to use the link you shared, create a money transfer order and register their email.',
      earn: '3. Earn Rewards',
      receive:
        "Randomly earn <b><span style='color:#00BD90'>1-5</span> Wire</b> points and earn up to <span style='color:#00BD90'><b>0,1% - 0,3%</b></span> cashback from your friends' money transfer orders and withdraw once you accumulate $10.",
    },
    draft_transaction: {
      empty_input_amount: 'Please input your desired amount.',
      unavailable_pair: (currency_in: string, currency_out: string) =>
        `The transfer from ${currency_in} to ${currency_out} is not supported yet.`,
      created_successfully:
        'Congratulations! Your transaction has been successfully initiated with the "<b>0 fee</b>"',
      lowest_input_amount: (limit: number, currency: string) =>
        `Minimum amount to be sent is ${limit.toLocaleString()} ${currency}.`,
      exceed_input_amount: (limit: number, currency: string) =>
        `Maximum amount to be sent is ${limit.toLocaleString()} ${currency}.`,
      explore_deal: 'Explore exclusive deals for early access users',
      detail: 'Send Order Details',
      creation_date: 'Order creation date',
      send_from: 'Send from',
      send_amount: 'You send',
      fee: 'Fee',
      bond_fee: 'Guarantee fee',
      trade_fee: 'Transaction fee',
      receive_amount: 'Recipient will receive',
      rate: 'Rate',
      status: 'Status',
      keep_the_deal: 'Preserve the deal',
      notice1: 'Our system retained your transaction information and deals.',
      notice2:
        'You can adjust information and proceed with the transaction when Instantwire officially launches.',
      view_detail: 'View detail',
      sign_in_to_secure: 'Sign in now to secure your offers!',
    },
    statuses: {
      unsaved: 'Not yet executed',
      draft: 'Preserved the deal',
    },
    survey: {
      intro:
        'Help Instantwire better understand your international money transfer needs and support us in improving the official version of Instantwire by participating in this short survey. <br>It only takes 3 minutes to complete.',
      join_survey: 'Join survey',
      completed: 'Completed the survey',
      no_of_joined_users: (amount: string) => `There are <b>${amount}</b> users joined`,
    },
    referral_benefit_double: {
      refer_friend: 'Share the Loot, Double the Points',
      description:
        'Spread the Instantwire love and get rewarded! Invite your friends to join and watch your Wire Points double! More friends, more points, more ways to win!',
      referee_create_acc:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>1</span> <span style='color:#00BD90'><b>2</b></span>  Wire Points when your referee creates an account",
      draft_trx_bonus:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> Wire Points when your referee initiates a draft money transfer transaction",
      refund:
        "&#x2022;&nbsp;&nbsp;<span style='color:#00BD90'><b>0,1%</b></span> cashback on the value of each transfer transaction made by your referee",
      create_acc:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>1</span> <span style='color:#00BD90'><b>2</b></span> Wire Points after successfully creating an account",
      create_transaction:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> Wire Points for initiating their first draft transaction",
    },
  },
  login_now: 'Login now!',
  count_down: {
    end_at: 'End at',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
  terms_and_conditions: 'Terms of use',
  recipients: {
    list_title: 'Your recipients',
    add_form_title: "Add recipient's bank information",
    update_form_title: "Update recipient's bank information",
    select_form_title: "Choose recipient's bank information",
    select_country: 'Select country',
    select_bank: 'Select bank',
    country: 'Country',
    bank_name: 'Bank name',
    bank_account_number: 'Bank account number',
    bank_account_name: 'Bank account name',
    bank_verification_number: 'Bank verification number',
    email: 'Email',
    phone_number: 'Phone number',
    new_recipients: 'New recipients',
    delete_warning: 'Delete recipient',
    delete_warning_msg: 'Do you really want to delete this record? This process cannot be undone',
    save: 'Save',
    added_recipient_success: 'Added recipient information successfully',
    update_recipient_success: 'Updated recipient information successfully',
    list: {
      account_name: 'Account name',
      account_number: 'Account number',
      action: 'Action',
    },
    bonus_first_trans: (amount: number) =>
      `Receive <b>${amount} wirepoints</b> for the first transaction`,
    bonus_first_trans_received: (amount: number) => `You have received <b>${amount} wirepoints</b>`,
    no_recipients_yet: 'There is no recipient yet!',
  },
  recipient: 'Recipient',
  form_error: {
    required: 'This field is required',
    invalid_email: 'Invalid email address',
    invalid_phone_number: 'Invalid phone number',
    submit_error: 'Something went wrong. Try again',
    bank_account_name_fetch_error: 'Failed to fetch bank account name',
  },
  action: {
    delete: 'Delete',
    edit: 'Edit',
    detail: 'Detail',
    cancel: 'Cancel',
  },
  account: {
    kyc: 'Identity Verification',
    balances: 'Balances',
    recipients: 'Recipients',
    transaction_history: 'Transaction History',
    exclusive_deals: 'Exclusive deals',
    wire_race: 'Wire Race',
    support: 'Support Center',
    transfer: 'Transfer',
  },
  balances: {
    title: 'Balances',
    your_balances: 'Your balances',
    history: 'History',
    time: 'Time',
    asset: 'Asset',
    amount: 'Amount',
    description: 'Description',
    status: 'Status',
    total_amount: 'Total amount',
    usd_value: 'USD value',
    action: 'Action',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
  },
  kyc: {
    information: 'KYC Information',
    status: 'KYC status',
    level1: 'KYC level 1',
    level2: 'KYC level 2',
    level: 'Level',
    complete_level: (level: string) => `Complete KYC Level ${level} to get the benefits:`,
    bonus_kyc: (amount: number) => `Receive <b>${amount} wirepoints</b> upon successful KYC`,
    fiat_deposit: 'Fiat deposit:',
    trading: 'Trading:',
    withdrawal: 'Withdrawal:',
    more_event_bonuses: 'More event bonuses',
    verify_now: 'Verify now',
    daily: 'Daily',
    requirements: 'Requirements',
    country: 'Country',
    phone_number: 'Phone number',
    selfie: 'Selfie',
    review_duration: 'Review Duration (Est. 10m)',
    statuses: {
      verified: 'Verified',
      not_verified: 'Not verified',
      approved: 'Approved',
      rejected: 'Rejected',
      pending: 'Pending',
    },
    benefit_1_lv1: 'Secure your account by verifying your <b>phone number.</b>',
    benefit_2_lv1:
      'Enjoy faster transactions and added security that helps us create a safer platform for all your transactions.',
    benefit_1_lv2: 'Secure your account by verifying your <b>identity.</b>',
    benefit_2_lv2:
      'Unlock premium features, enjoy higher transaction limits, and added security that helps us create a safer platform for all of your transactions.',
    kyc_approved_title: 'Congratulations! You have successfully completed KYC',
    kyc_approved_description: (amount: number) =>
      `You have received a <span class="text-iw-secondary font-semibold">$${amount}</span> bonus. This bonus will be used when <span class="font-semibold text-iw-text-primary">Instantwire officially launches in late November 2024</span>. Stay tuned and get ready to experience our services!`,
  },
  transaction_input: {
    continue: 'Continue',
    cancel: 'Cancel',
    fee: 'Fee',
    total_to_pay: 'Total to pay',
    start: 'Start',
  },
  transfer_review: {
    review_request: 'Review request',
    make_sure_correct:
      "Please ensure the transfer amount and recipient's information are correct before proceeding.",
    money_info: 'Transfer request information',
    total_payout: 'Amount you need to pay',
    rate: 'Exchange rate',
    fee: 'Fee',
    converted_amount: 'Amount recipient will receive',
    from: 'Transfer direction',
    estimate: 'Estimated time of receipt',
    recipient_info: 'Recipient information',
    recipient: 'Recipient',
    bank_account: 'Bank account number',
    bank_name: 'Bank',
    email: 'Email',
    phone: 'Phone number',
    edit_recipient: 'Edit recipient information',
    next: 'Next',
  },
  transfer_connecting: {
    connecting: 'Matching...',
    finding:
      'We seek other users wishing to transfer money in the opposite direction to pair with your request.',
    cancel: 'Cancel',
  },
  new_recipients: {
    your_recipient: 'Who are you sending money to?',
    add_new: 'Add new recipient',
    bank_transfer_recipient: 'Recipient list',
    infor: 'Recipient information',
    update: 'Update recipient’s information',
    continue: 'Continue',
    no_bank_transfer_recipient: 'No bank transfer recipient',
  },
  transaction_progress: {
    steps: {
      creating_order: 'YOU CREATE',
      paying: 'YOU SEND',
      transfering: 'WE PAY OUT',
      completed: 'COMPLETED',
    },
    step_x_of_y: (x: number, y: number) => `Step ${x} of ${y}`,
    payment: 'Payment',
  },
  transaction_complaint_progress: {
    steps: {
      appeal_submitted: 'Appeal submitted',
      handling: 'Handling',
      result: 'Result',
    },
    being_process: 'Your appeal is currently being processed by the arbitration team',
    reason:
      "Reason: <span class='text-rose-500 font-bold font-inter'>The recipient has not received full payment.<span>",
    request_id: (transactionId: string) => `Request ID: <b>#${transactionId}</b>`,
    cancel_appeal: 'Cancel appeal',
    home: 'Home',
    completed: 'Completed!',
    are_you_certain: 'Are you certain?',
    certain_description:
      "Canceling a dispute doesn't mean cancelling the money transfer itself. You're still in the middle of the transaction. If you're still facing any issues, feel free to raise another dispute",
    received: (amount: string) => `I have received the amount of <b>${amount}</b>.`,
    not_now: 'Not now',
  },
  kyc_double: {
    verify_account: 'Double Your Points with KYC Today',
    bonus_kyc:
      "Receive <span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> Wire Points after successfully completing the KYC process",
    verify_now: 'Get Verified',
  },
  banner_double: {
    flash_sale: 'Flash Deal 24H:',
    double_when_success:
      'Double your Wire Points in a flash! Get double Wire points for every 1st draft transaction AND every referral',
    learn_more: 'Learn More',
  },
  referral_process_double: {
    receive:
      "Earn <span style='text-decoration:line-through;color:#EB5757'>1-3</span> <b><span style='color:#00BD90'>2-6</span></b> Wire Points and earn up to <span style='color:#00BD90'><b>0,1%</b></span> cashback from your friends' money transfer orders.",
  },
  hero_double: {
    end_at: 'Finish after',
    double_point:
      'Get <b>Double Wire Points</b> for every 1st draft transaction AND every referral',
  },
  transfer_payment: {
    request_id: 'Ref',
    wait_for_payment: 'Awaiting your payment',
    instruction:
      'Please make the payment to the designated beneficiary account below. You need to <strong>take a screenshot</strong> of the transfer result as proof after completing the transaction.',
    payment_x_of_y: (x: number, y: number) =>
      `Payment <span class="text-iw-secondary sm:text-[1.75rem]">${x}</span>/${y}`,
    payment_x_of_y_normal: (x: number, y: number) => `Payment ${x}/${y}`,
    choose_a_payment_channel: 'Choose a payment channel',
    payment_via_banks: 'Payment via Banks',
    payment_via_others: 'Payment via Zelle',
    or_click_others: 'Click',
    copy_transfer_info: 'to copy',
    bank_account_name: 'Beneficiary:',
    bank_name: 'Bank:',
    bank_acc_number: 'Bank Account:',
    bank_routing_number: 'Routing Number:',
    bank_address: 'Bank Address:',
    transfer_note: 'Referral:',
    amount: 'Amount:',
    copied: 'Copied',
    cancel: 'Cancel',
    complete: 'I have completed the payment',
    transfering: 'Sending money',
    transfering_subtitle:
      'The funds are currently being transferred to the recipient, completely free of charge!',
    transferred: 'Money has been sent',
    transferred_subtitle:
      'Before we wrap this up, please reach out to the recipient to ensure everything is set on their end. Just hit the button below to confirm all their details for us.',
    received: 'Verify the payment',
    confirm: 'Would you like to confirm?',
    upload_evidence: 'Upload proof of payment',
    upload_evidence_instruction:
      'Almost done! Just one more step, the money will reach the recipient.',
    payment_method: 'Payment method',
    phone_number: 'Phone number:',
    email: 'Email:',
    sent_at: 'Sent at',
    expire_in: 'Expire in',
    download: 'Download',
    need_help: 'Contact customer support',
    banks: 'Banks',
    order_status: {
      payment_not_yet_made: 'Payment not yet made',
      being_verified: 'Being verified',
      being_verified_description: 'We are currently checking your payment. Please wait a moment!',
      under_dispute: 'Under dispute',
      under_dispute_description: 'Our referee is currently contacting you to confirm your payment.',
      payment_is_received: 'Payment is received',
      canceled: 'Payment is canceled',
    },
    changed_your_mind: "You've changed your mind!",
    cancel_the_transaction: 'Cancel the transaction',
  },
  payment_evidence: 'Payment proof',
  contact_support: 'Contact support',
  payment_confirm: {
    title: 'Verifying...',
    subtitle: 'We are currently checking your payment. Please wait a moment!',
    verified: 'Verified',
  },
  upload: {
    file_max_size: (max_size: string) => `* Maximum file upload size: ${max_size}`,
    upload_btn: 'Select file to upload',
    uploading_title: 'Uploading...',
    uploading_msg: 'Your bank transfer proof is being uploaded. Please wait a moment.',
    uploaded_title: 'Upload completed',
    uploaded_msg:
      'The transfer proof has been fully uploaded and is currently being verified by the system.',
    cancel: 'Cancel',
    size_exceed_limit: 'Exceeded allowed capacity',
    zoom_in: 'Zoom in',
    continue_tracking: 'View my transfer',
    failed: 'Upload failed',
  },
  currency_exchange: {
    title: 'Money exchange',
    exchanging:
      "The system is now converting your local currency to the recipient's currency using the market exchange rate",
    estimate: 'Estimated time remaining',
    hour: 'hour(s)',
    info: 'Transfer details',
  },
  trx_info: {
    more: 'More',
    less: 'Less',
    receive_amount: 'Receive amount',
    received_amount: 'Received amount',
    you_send: 'You send',
    fee: 'Fee',
    rate: 'Exchange rate',
    you_pay: 'You pay',
    perform_time: 'Created at',
    direction: 'Transfer From - To',
    bank_acc_owner: 'Full name',
    amount: 'Amount',
    bank_acc_number: 'Bank account number/IBAN',
    bank_name: 'Bank name',
    transfer_note: 'Transfer notes/Reference',
  },
  transaction_complete: {
    title: 'Completed!',
    subtitle: 'The recipient has successfully received the money.',
    go_homepage: 'Home',
  },
  trx_cancel: {
    confirm_title: 'Are you going to quit?',
    confirm_msg_1:
      'If you have made the payment, please remember to click the "<b style=\'color: #00BD90\'>I have completed the payment</b>" button.',
    confirm_msg_2:
      'If you have made the payment to the designated recipient, please do not cancel the transaction.',
    confirm_checkbox: 'I confirm that I have not made the payment',
    btn: 'Cancel transfer',
    back: 'Not now',
    confirm_description: [
      'If you have already made the payment, remember to press the button "<span style="color: #00BD90">I have transferred the money.</span>"',
      'If you have already made payment to the designated recipient, please do not cancel the transaction.',
    ],
    reason_title: 'Reason for cancellation',
    reason_list: [
      { value: 'no_longer_want_to_trade', label: "I don't want to trade anymore" },
      {
        value: 'payment_platform_issue',
        label: 'The payment platform has a technical or network error',
      },
      {
        value: 'payment_method_issue',
        label:
          "There was a problem with the nominated person's payment method that resulted in payment failure",
      },
      { value: 'other', label: 'Other reasons' },
    ],
    reason_placeholder: 'Please enter a reason',
    cancel_transaction_successful: 'Transaction has been successfully canceled',
  },
  continue: 'Continue',
  cancel: 'Cancel',
  order_dispute: {
    title: 'Require extra time for processing',
    subtitle:
      'We require extra time to confirm your payment details. A representative will reach out to you soon to assist with the process and make sure that your payment verification is conducted thoroughly and efficiently.',
  },
  transaction_history: {
    title: 'Transaction history',
    status: {
      in_progress: 'In Progress',
      all: 'All',
      completed: 'Completed',
      canceled: 'Canceled',
    },
    received: 'Recipient received',
    in_progress: 'In progress',
    success: 'Success',
    cancel: 'Canceled',
  },
  transaction_onramp_failed: {
    failed: 'Failed',
    try_again:
      "We're sorry, but we haven't found the transfer request from the other user in the opposite direction to complete the match. Please try again with a smaller transfer amount later!",
    how_it_works: 'See how it works',
    new_transfer: 'Create new transfer',
  },
  safe_transactions: {
    title: 'Safe transactions',
    items: [
      {
        title: 'Identity verification',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> ensures that each customer undergoes the below verification during transactions:',
        items: ['Face verification', 'Background information check'],
        sub_description: 'to provide transparency and trustworthiness on the platform.',
      },
      {
        title: 'Strict risk management',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> limits the amount of money you can transact based on identification information. Various points require facial verification and anti-money laundering checks to ensure your money is secure.',
      },
      {
        title: '24/7 Customer Support',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> offers 24/7 online customer service to ensure your issues are resolved quickly and easily.',
      },
    ],
  },
  ongoing_transaction: {
    title: 'There is 1 ongoing transaction.',
    msg: (id: string | number) =>
      `You have transaction #${id} pending. Please check and complete before creating a new transaction.`,
    check_transaction: 'Check transaction',
    close: 'Close',
  },
  discount: {
    msg: (points: number) => `Discount using ${points} Wire points`,
    tooltip: [
      {
        VND: 'Use up to 100 wire points <span class="text-iw-saffron-yellow">(~5 USD)</span> for transactions valued from 500 thousands VND to 10 millions VND',
        USD: 'Use up to 100 wire points <span class="text-iw-saffron-yellow">(~5 USD)</span> for transactions valued from $20 to $500',
      },
      {
        VND: 'Use up to 200 wire points <span class="text-iw-saffron-yellow">(~10 USD)</span> for transactions valued from 10 millions VND to 20 millions VND',
        USD: 'Use up to 200 wire points <span class="text-iw-saffron-yellow">(~10 USD)</span> for transactions valued from $500 to $1000',
      },
      {
        VND: 'No limit on the number of wire points used to discount transactions over 20 millions VND',
        USD: 'No limit on the number of wire points used to discount transactions over $1000',
      },
    ],
    field: 'Discount using Wire points',
    discount_amount: 'Discount',
  },
  transfer_received_verify: {
    title: 'Seems like you’re stepping in to verify payment for the recipient',
    subtitle: (recipient: string) =>
      `Contact <b>${recipient}</b> to verify accurate information before confirmation to prevent the risk of financial loss.`,
    not_received: 'The recipient has not received the money.',
    not_received_sufficient: 'The recipient has not received sufficient funds.',
    received: (accountName: string, amount: string, currency: string) =>
      `<b>${accountName}</b> has successfully received the exact amount of <b>${amount}</b> ${currency}.`,
    confirm: 'Confirm',
    verify_again: 'No, I must verify this again',
    dispute_title: "We're sorry to hear you're having trouble!",
    dispute_subtitle: 'The receipt showing funds has been added to your account is',
    copy_and_dispute: 'Copy transaction ID & appeal',
    check_again: "I'll check and let you know later",
    received_lt_50: 'Received <b>less than 50%</b> of the amount',
    received_gt_50: 'Received <b>more than 50%</b> of the amount',
    received_insignificant_amount: 'Receive an <b>insignificant</b> amount (below 3%)',
  },
  transfer_contact_reason: {
    title: 'Reason for contact',
    reason: 'Reason',
    not_received: 'The recipient has not received the money',
    not_received_sufficient: 'The recipient receives a shortage of money',
    upload_evidence_instruction:
      'Upload a photo of the statement proving that the recipient has no money receiving transactions that match your transfer request',
    upload_btn: 'Upload proof',
    confirm: 'Confirm',
    cancel: 'Come back later',
  },
  here: 'Here',
  search: 'Search',
  referee_list: {
    list: 'Referral list',
    user: 'User',
    email: 'Email',
    status: 'Status',
    transferred: 'Transferred +$7',
    approved: 'KYC Approved +$2',
    invited: 'Invited',
    people: 'user(s)',
    close: 'Close',
  },
  coming_soon: {
    title: 'Money transfer feature on Instantwire is coming soon!',
    description:
      "We are working hard to ensure Instantwire's fast and secure money transfer feature will be officially launched soon. Follow us for launch date updates and get ready to experience exceptional service with exciting offers!",
    follow: 'Follow our official channels',
  },
}

export default locale
