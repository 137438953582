import React, { createContext, useState } from 'react'

type MenuProfileContextType = {
  isOpenProfileMenu: boolean
  setIsOpenProfileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

export const MenuProfileContext = createContext({} as MenuProfileContextType)

export const MenuProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)

  return (
    <MenuProfileContext.Provider value={{ isOpenProfileMenu, setIsOpenProfileMenu }}>
      {children}
    </MenuProfileContext.Provider>
  )
}
