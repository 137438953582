import { useEffect } from 'react'
import { getSession } from 'next-auth/react'

const useSessionRefresh = (refreshInterval: number = 15 * 60 * 1000): void => {
  useEffect(() => {
    let refreshTimer: NodeJS.Timeout | null = null

    const refreshSession = async () => {
      try {
        const session = await getSession()
        if (session) {
          console.log('Session refreshed successfully.')
        }
      } catch (error) {
        console.error('Error refreshing session:', error)
      }
    }

    const resetTimer = () => {
      if (refreshTimer) clearTimeout(refreshTimer)
      refreshTimer = setTimeout(() => {
        refreshSession()
      }, refreshInterval)
    }

    const activityEvents: string[] = ['mousemove', 'keydown', 'scroll', 'touchstart']
    activityEvents.forEach((event) => window.addEventListener(event, resetTimer))

    return () => {
      if (refreshTimer) clearTimeout(refreshTimer)
      activityEvents.forEach((event) => window.removeEventListener(event, resetTimer))
    }
  }, [refreshInterval])
}

export default useSessionRefresh
