import { User } from '../utils/apis/types'
import { isSuccessHttpCode } from '../utils/helpers'
import { useAuthorizeRequest } from './use-authorize-request'

export const useUser = () => {
  return useAuthorizeRequest({
    path: '/users/me',
    method: 'GET',
    transformResponse: (raw_data: any, _header: any, status?: number) => {
      const data = JSON.parse(raw_data)

      if (status && isSuccessHttpCode(status)) {
        return {
          id: data.id,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          queueOrder: data.queue_order,
          inWhitelist: data.in_whitelist,
          referralCode: data.referral_code,
          refereesCount: data.referees_count,
          successTransactionCount: data.success_transaction_count,
          wirePoints: data.total_wire_points,
          totalRefundRefereeTransaction: data.total_refund_referee_transaction,
          draftTransactionCount: data.draft_transaction_count,
          publicKey: data.public_key,
          kycStatus: data.kyc_status,
          kycLink: data.kyc_link,
          tosStatus: data.tos_status,
          tosLink: data.tos_link,
          kycBlocked: data.kyc_blocked,
          kycBlockedReason: data.kyc_blocked_reason,
          nextAllowedKycTimestamp: data.next_allowed_kyc_timestamp,
          kycRejectedCount: data.kyc_rejected_count,
          refuseReceiveEmail: data.refuse_receive_email,
          kycRejectionReason: data.kyc_rejection_reason,
          testingFeatures: data.testing_features,
          kycAvailable: data.kyc_available,
          totalRewards: data.total_rewards,
        } as User
      }
      return data
    },
  })
}
