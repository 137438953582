import DefaultBankIcon from '@/assets/bank-card-icon.png'
import { LocalStorage } from './storage'

import BankList from '../assets/bank_list.json'
import { v4 as uuidv4 } from 'uuid'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any
  }
}

export const isRunningInCypress = typeof window !== 'undefined' && window.Cypress !== undefined

export const removeIntercomActiveClass = () => {
  document.querySelectorAll('.intercom-mobile-messenger-active').forEach((element) => {
    element.classList.remove('intercom-mobile-messenger-active')
  })
}

export const onIntercomWidgetShowed = () => {
  document.body.classList.add('intercom-h-0')
}

export const onIntercomWidgetHidden = () => {
  document.body.classList.remove('intercom-h-0')
}

export const detectElementMutated = (element: any, callback: () => void) => {
  const observer = new MutationObserver(callback)

  observer.observe(element, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false,
  })
}

export const nextIsRunningCypress = process.env.NEXT_PUBLIC_IS_RUNNING_CYPRESS === 'true'

export const minifyAddress = (address: string, length = 4) => {
  const firstPart = address.slice(0, length)
  const lastPart = address.slice(-length)
  return `${firstPart}...${lastPart}`
}

export const isSuccessHttpCode = (code: number) => {
  return /^(2\d{2})$/.test(code.toString())
}

export const validateEmail = (email: string) => {
  if (!email) return true
  return /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const shortenEmail = (email: string) => {
  const arrEmail = email.split('@')
  if (arrEmail.length < 2) return email

  return `${arrEmail[0].slice(0, Math.max(0, arrEmail[0].length - 3))}***@${arrEmail[1]}`
}

export const getBankLogoUrl = (bankName: string | undefined) => {
  const url = Object.entries(BankList).find(([key, val]) => {
    return (
      val.shortName.toLowerCase() === bankName?.toLowerCase() ||
      key.toLowerCase() === bankName?.toLowerCase()
    )
  })?.[1]?.bankLogoUrl

  if (url) return url

  return DefaultBankIcon
}

export const showIntercomChatBox = () => {
  if (window?.Intercom) {
    window.Intercom('show')
  }
}

export const joinAndUpperCase = (arr: string[], separator = ', ', toUpperCase = true): string => {
  const joined = arr.join(separator)
  return toUpperCase ? joined.toUpperCase() : joined
}

export const retrieveOrGenerateUUID = () => {
  let uuid = LocalStorage.get('login-uuid')
  if (!uuid) {
    uuid = uuidv4()
    LocalStorage.set('login-uuid', uuid)
  }
  return uuid
}
