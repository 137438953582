import { useAuthorizeWebsocket } from './use-authorize-websocket'
import useIwAuth from './use-iw-auth'

export const useUserSocket = (callback: (data: any) => void) => {
  const { isLoggedIn } = useIwAuth()

  return useAuthorizeWebsocket<any>({
    channel: 'UserChannel',
    params: {},
    enabled: isLoggedIn,
    callback,
  })
}
