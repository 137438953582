// import { isMainnet, nextIsRunningCypress } from '@/utils/helpers'
// import { REVND_MINT_ADDRESS, REUSD_MINT_ADDRESS } from '@/hooks/use-tokens'

// import { api } from './'

// import sampleSettings from '../../../cypress/fixtures/settings.json'

export const fetchSettings = async () => {
  // if (nextIsRunningCypress && typeof window === 'undefined') {
  //   return sampleSettings
  // }

  // const { data } = await api.get('/settings/nemo_swap')
  // data.one_pool_swapping_mode = true
  // if (isMainnet) {
  //   data.off_ramp_program_id = 'EQoA6efKaNEqjkyQmjDG8EWZ8NcrZEnUPyZufjXFxFBx'
  //   data.off_ramp_config_id = 'escrow_vnd'
  //   data.off_ramp_fiat_tokens = data.off_ramp_fiat_tokens || [REVND_MINT_ADDRESS]
  // } else {
  //   data.off_ramp_fiat_tokens = data.off_ramp_fiat_tokens || [REVND_MINT_ADDRESS, REUSD_MINT_ADDRESS]
  // }
  // return data
  return {} as any
}
