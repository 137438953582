import React, { memo, useCallback, useEffect, useState } from 'react'
import CustomDialog from '@/components/base/CustomDialog'
import { Button, Stack, Typography } from '@mui/material'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import { useRouter } from 'next/router'
import SuccessKycWithIconImage from '@/assets/success-kyc-with-icon.png'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import { useLocalStorage } from 'usehooks-ts'
import { KYC_STATUS_APPROVED } from '@/constants/kyc'
import { useUser } from '@/hooks/use-user'

const KycSuccessModal = () => {
  const trans = useTrans()
  const router = useRouter()
  const { data: featureFlags } = useFeatureFlags()
  const { data: user } = useUser()
  const [showKycSuccessModal, setShowKycSuccessModal] = useState(false)
  const [hasShownKycSuccessModal, setHasShownKycSuccessModal] = useLocalStorage(
    'hasShownKycSuccessModal',
    false,
  )

  useEffect(() => {
    setShowKycSuccessModal(
      featureFlags?.join_wait_list_enabled &&
        user &&
        KYC_STATUS_APPROVED === user?.kycStatus &&
        !hasShownKycSuccessModal,
    )
  }, [user, hasShownKycSuccessModal, featureFlags])

  const onCloseKycSuccessModal = () => {
    setShowKycSuccessModal(false)
    setHasShownKycSuccessModal(true)
  }

  const handleClick = useCallback(() => {
    onCloseKycSuccessModal()
    router.push('/') // TODO: change to "kiem tra yeu cau" page when it's ready
  }, [router, onCloseKycSuccessModal])

  return (
    <CustomDialog
      handleClose={onCloseKycSuccessModal}
      isOpened={showKycSuccessModal}
      body={
        <Stack alignItems="center" className="text-center py-10 px-5">
          <Image
            height={95}
            className="image-fluid"
            src={SuccessKycWithIconImage}
            alt="kyc-success"
          />
          <Typography className="font-ma text-2xl pt-10">{trans.kyc.kyc_approved_title}</Typography>
          <Typography
            variant="body2"
            className="mt-6 text-sm md:text-base"
            dangerouslySetInnerHTML={{ __html: trans.kyc.kyc_approved_description(3) }}
          />
          <Stack gap={1} className="w-full pt-10">
            <Button variant="contained" onClick={handleClick}>
              {trans.continue}
            </Button>
          </Stack>
        </Stack>
      }
    />
  )
}

export default memo(KycSuccessModal)
