import {
  useEffect,
  useState,
} from 'react'

import axios from 'axios'

import { useUser } from '@/root/src/hooks/use-user'
import {
  detectElementMutated,
  onIntercomWidgetHidden,
  onIntercomWidgetShowed,
  removeIntercomActiveClass,
} from '@/utils/helpers'
import { useIntercom } from '@thanhvo102/react-use-intercom'

declare global {
  interface Window {
    Intercom: any;
  }
}

function BootIntercom(): null {
  const [hasIntercom, setHasIntercom] = useState(false)
  const { boot, update } = useIntercom()
  const { data: user } = useUser()

  useEffect(() => {
    boot()
  }, [boot])

  const updateIntercomUser = async () => {
    if (user) {
      try {
        const { data: userHash } = await axios.get('/api/intercom_user_hash')
        update({
          email: user.email,
          unsubscribedFromEmails: user.refuseReceiveEmail,
          userHash,
          customAttributes: {
            instantwire_user: true,
            locale: user.locale,
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating Intercom user', error)
      }
    }
  }

  useEffect(() => {
    updateIntercomUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (window.Intercom && !hasIntercom) {
      setHasIntercom(true)

      window.Intercom('onHide', onIntercomWidgetHidden)
      window.Intercom('onShow', onIntercomWidgetShowed)
    }

    detectElementMutated(document.querySelector('html'), removeIntercomActiveClass)
  }, [hasIntercom])

  return null
}

export default BootIntercom
