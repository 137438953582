import { palette } from '../options'

export const MuiSwitchOptions = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: () => ({
      width: 48,
      height: 28,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        color: palette.grey[300],
        '&.Mui-checked': {
          transform: 'translateX(20px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundImage: palette.primary.gradient,
            opacity: 1,
            border: 0,
          },
        },
        '&.Mui-disabled': {
          color: palette.grey[300],
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 24,
        height: 24,
      },
      '& .MuiSwitch-track': {
        borderRadius: 28 / 2,
        backgroundColor: palette.grey[200],
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.8,
      },
    }),
  },
}
