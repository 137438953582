// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  header: {
    vietnamese: 'Tiếng Việt',
    english: 'English',
    home: 'Trang chủ',
    referral: 'Ưu đãi độc quyền',
    wire_race: 'Wire Race',
    transaction: 'Giao dịch',
    transaction_init: 'Chuyển tiền',
    transaction_history: 'Lịch sử',
  },
  transaction: {
    title: 'Transactions',
    in_progress: 'In progress',
    completed: 'Completed',
    sending: 'Sending',
    cancelled: 'Cancelled',
    failed: 'Failed',
    title_detail: 'Transactions details',
    transfer_money: 'Transfer money',
    tracking: 'Tracking',
    details: 'Details',
    you_set_up_transfer: 'You set up your transfer',
    waiting_for_your_money: 'We are waiting for your money',
    waiting_for_your_money_description:
      "If you’ve sent the money from your bank to Rewire, we will let you know when it's arrived",
    receive_usd: 'We receive your USD',
    payout_vnd: 'We pay out your VND',
    recipient_receives_vnd: 'Your recipient receives the VND',
    cancel_transfer: 'Cancel transfer',
    back_to_home: 'Back to home',
    information: 'Thông tin giao dịch',
  },
  transfer: {
    bank_account_number: 'Bank account number',
    bank_name: 'Bank name',
    bank_account_name: 'Bank account name',
    email: 'Email',
    phone_number: 'Phone number',
    next: 'Next',
    add_recipient_bank: "Add recipient's bank",
    information: 'information',
    review: 'Review',
    review_description: 'Please double check information before continue to payment',
    recipient: 'Recipient',
    amount: 'Amount',
    you_send: 'You send',
    recipient_receives: 'Recipient receives',
    change: 'Change',
    continue_to_payment: 'Continue to payment',
    more: 'More',
    less: 'Less',
    fee: 'Fee',
    amount_to_be_converted: 'Amount to be converted',
    Rate_24h: 'Rate (24hs)',
    tolerance: 'Slippage tolerance',
  },
  home: {
    swap: 'Hoán đổi',
    transfer_to_friends: 'Transfer to friends and family',
    low_fee_easy: 'Low Fee. Easy',
    swap_for: 'thành',
    slippage_tolerance: 'Tỷ lệ trượt giá cho phép',
    fee: 'Phí',
    minimum_received: 'Tối thiểu nhận được',
    minimum_received_help_text:
      'Dù giá của các token thay đổi do các giao dịch khác, bạn vẫn sẽ nhận được ít nhất số lượng này hoặc giao dịch sẽ bị hủy. Số lượng này được xác định bởi tỷ lệ trượt giá cho phép của bạn.',
    swap_completed: 'Hoán đổi hoàn tất',
    swap_failed: 'Hoán đổi thất bại',
    you_swapped: 'Bạn đã hoán đổi',
    for_approximately: 'thành xấp xỉ',
    view_on_renec_explorer: 'Xem trên RENEC Explorer',
    enter_amount: 'Nhập số lượng',
    insufficient_balance: 'Số dư không đủ',
    maximum: 'Tối đa',
    minimum: 'Tối thiểu',
    rate: 'Tỷ giá',
    price_impact: 'Tác động giá',
    get_started: 'Bắt đầu',
    you_send: 'Bạn gửi',
    recipient_receive: 'Người nhận sẽ nhận',
    price_impact_warning: 'Cảnh báo tác động giá',
    reduce_the_swapping_amount: 'Swap với số lượng nhỏ hơn để giảm thiểu tổn thất từ tác động giá',
    price_impact_help_text: 'Sự chênh lệch giữa giá thị trường và giá ước tính do quy mô giao dịch',
    transaction_fee_in_renec_notice: (fee: string) =>
      `Renec Foundation sẽ chi trả phí gas ${fee} RENEC để bạn có được trải nghiệm giao dịch một cách dễ dàng.`,
    swap_error_troubleshooting: {
      title: 'Xử lý sự cố',
      descriptions: {
        hint_1: 'Hãy thử lại với ít nhất 0,01 RENEC trong ví của bạn',
        hint_2: 'Hoặc thử lại với số tiền hoán đổi nhỏ hơn để tránh trượt giá lớn',
      },
    },
    sign_in: 'Đăng nhập',
    sign_up: 'Đăng ký',
    sign_up_description:
      'Hãy tham gia cùng chúng tôi và khám phá một trải nghiệm hoàn toàn mới khi chuyển tiền xuyên biên giới.',
    sign_in_with_google: 'Đăng nhập với Google',
    facebook_continue: 'Facebook (sắp ra mắt)',
    apple_continue: 'Apple (sắp ra mắt)',
    send_money: 'Chuyển tiền đi nước ngoài',
    zero_fee: 'Miễn phí. Siêu nhanh',
    coming_soon: 'Sắp ra mắt',
    draft_transaction: 'Tạo lệnh chuyển tiền',
  },
  sell: {
    sell_btn: 'Bán',
    sell_reVND_to_VND: 'Bán reVND thành VND',
    input_info_remind: 'Nhập thông tin của bạn',
    my_info: 'Thông tin của tôi',
    my_contact_info: 'Thông tin liên hệ',
    withdraw_to: 'Thông tin chuyển khoản',
    buyer_awaiting: 'Đang tìm người mua',
    payment_awaiting: 'Chờ thanh toán',
    in_dispute: 'Đang trong tranh chấp',
    in_release: 'Giao dịch hoàn tất',
    btn_release: 'Hoàn tất',
    btn_cancel: 'Hủy',
    btn_dispute: 'Tranh chấp',
    phone_number: 'Số điện thoại',
    bank_name: 'Tên ngân hàng',
    bank_account_number: 'Số tài khoản',
    bank_account_name: 'Tên chủ tài khoản',
    form_validation: {
      required: (fieldName: string) => `${fieldName} là bắt buộc.`,
      invalid: (fieldName: string) => `${fieldName} không hợp lệ.`,
      should_not_contains_special_characters: (fieldName: string) =>
        `${fieldName} không được chứa ký tự đặc biệt.`,
    },
    save: 'Lưu',
    btn_release_help_text: (bond: string) =>
      `Nếu bạn đã nhận thanh toán từ người Mua, nhấn vào Hoàn tất để nhận lại ${bond} tiền đảm bảo.`,
    btn_dispute_help_text: 'nếu bạn chưa được thanh toán.',
    more_details: 'Thông tin thêm',
    trade_ref: 'Mã giao dịch',
    buyer_address: 'Địa chỉ người mua',
    copied_buyer_address_snackbar: 'Đã copy địa chỉ người mua.',
    buyer_has_kyc: 'Người mua đã xác minh KYC với Remitano',
    for_approximately: 'thành xấp xỉ',
    sell_fail: 'Lệnh bán không thành công',
  },
  deposit: {
    buy_btn: 'Mua',
    buy_reVND_btn: 'Mua reVND',
    no_trades_order_found: 'Không tìm thấy lệnh giao dịch nào.',
    bank_name: 'Tên ngân hàng',
    bank_account_number: 'Số tài khoản',
    bank_account_name: 'Tên tài khoản',
    transfer_note: 'Nội dung chuyển khoản',
    reference: 'Reference',
    transfer_title: (fiatAmount: string, fiatCurrency: string) =>
      `Hãy thanh toán <span style='font-weight:600;color:white'>${fiatAmount}</span> ${fiatCurrency} đến tài khoản ngân hàng dưới đây.`,
    hide_bank_title: 'Ẩn tài khoản',
    show_bank_title: 'Hiện tài khoản',
    warning_connect_wallet: 'Kết nối ví để hiện thị thông tin giao dịch',
    not_found_order: 'Không tồn tại giao dịch',
    want_to_buy: 'Tôi muốn mua',
    scan_qr_code_to_complete_transaction: 'Quét mã QR dưới đây để hoàn thành giao dịch.',
    made_payment_btn: 'Tôi đã thanh toán',
    made_payment_popup_title: 'Chờ xác nhận',
    made_payment_popup_msg: (waitingTime: number) =>
      `Xin vui lòng chờ trong ${waitingTime} phút để người bán có thể xác nhận thanh toán.`,
    wait_confirmation_btn: 'Chờ người bán xác nhận',
    dispute_popup_title: 'Giao dịch tranh chấp',
    dispute_popup_msg:
      'Trọng tài sẽ liên hệ với bạn và người bán qua Chat hoặc Thông tin liên lạc đã cung cấp. Bạn sẽ nhận được reVND khi trọng tài xác minh bằng chứng thanh toán. Chi phí cho trọng tài sẽ nhận từ Tiền đảm bảo của người bán.',
    request_arbitration_btn: 'Yêu cầu trọng tài',
    keep_waiting_btn: 'Tôi sẽ chờ thêm',
  },
  common: {
    balance: 'Max',
    estimated: 'Ước tính',
    details: 'Chi tiết',
    connect_wallet: 'Kết nối ví',
    how_to_use_nemo: 'Hướng dẫn sử dụng NemoSwap?',
    disconnect: 'Ngắt kết nối',
    copied: 'Đã copy',
    copy_address: 'Copy địa chỉ',
    connect: 'Kết nối',
    connecting: 'Đang kết nối',
    copy: 'Copy',
    buy: 'Mua',
    connected: 'Đã kết nối',
    change_wallet: 'Thay đổi ví',
    token_picker_filter_by: 'Tìm kiếm',
    token_picker_no_tokens_found: 'Không tìm thấy token nào',
    bond: 'Đảm bảo',
    bond_tooltip: 'Nhận lại khi giao dịch thành công',
    token_picker_please_select: 'Hãy chọn một token...',
    please_wait: 'Vui lòng chờ...',
    copied_address_snackbar: 'Đã copy địa chỉ token.',
    failed: 'Lỗi!',
    something_wrong: 'Có lỗi xảy ra, vui lòng thử lại sau!',
    policy: {
      terms_of_service: 'Điều khoản dịch vụ',
      terms_of_use: 'Điều khoản sử dụng',
      privacy_policy: 'Chính sách bảo mật',
      first: 'Bằng việc đăng ký, bạn chấp thuận',
      and: 'và',
      last: 'của chúng tôi',
    },
    powered_by: 'Được cung cấp bởi RENEC Blockchain',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    expand_less: 'Rút gọn',
    expand_more: 'Xem thêm',
    no_data: 'Không có dữ liệu',
    loading: 'Đang xử lý...',
    just_a_moment_loading_data: 'Hệ thống đang tải dữ liệu...',
    bonus: 'Tiền thưởng',
  },
  wallet_ui: {
    dialog_heading: 'Kết nối ví để tiếp tục',
    first_time_connect_notice: 'Ở lần kết nối đầu tiên, bạn có thể sẽ cần Tạo Ví trước.',
    install_demon_notice_heading: 'Cài đặt ví Demon',
    install_demon_notice_body:
      'Bạn sẽ được đưa tới trang mới để cài đặt ví Demon. Sau khi hoàn tất cài đặt, vui lòng quay lại NemoSwap.',
    detected: 'Đã cài đặt',
    not_detected: 'Không tìm thấy',
  },
  meta: {
    home: {
      title: 'Instantwire | Website Chính Thức',
      description: 'Chuyển khoản nhanh chóng và an toàn tại địa phương miễn phí',
      thumb: '/thumb-share-ins-vi.png',
    },
    account: {
      title: 'Instantwire | Quản Lý Tài Khoản',
      description: 'Quản lý tài khoản của bạn trên Instantwire để chuyển khoản an toàn và suôn sẻ',
    },
    landing: {
      title: 'Instantwire | Chuyển Tiền Miễn Phí',
      description:
        'Dịch vụ chuyển tiền toàn cầu miễn phí, sử dụng mô hình chuyển tiền ngang hàng kết hợp với bảo mật blockchain',
    },
    login: {
      title: 'Instantwire | Đăng Nhập',
      description:
        'Đăng ký hoặc Đăng nhập để bắt đầu hành trình của bạn với dịch vụ chuyển tiền miễn phí, an toàn và suôn sẻ của chúng tôi',
    },
    referral: {
      title: 'Instantwire | Chương Trình Giới Thiệu',
      description:
        'Giới thiệu bạn bè tạo lệnh chuyển tiền để cả hai đều nhận ưu đãi. Không giới hạn số lượt chia sẻ và ưu đãi nhận được.',
      social_share_description:
        'Join the Instantwire waitlist now to unlock unlimited exciting rewards – the more you refer, the bigger the rewards!',
    },
    term_and_conditions: {
      title: 'Instantwire | Điều Khoản',
      description:
        'Các Điều khoản Dịch vụ chi phối việc bạn truy cập và sử dụng Trang web và Dịch vụ của Instantwire',
    },
    privacy_policy: {
      title: 'Instantwire | Chính sách về quyền riêng tư',
      description:
        'Chính sách về quyền riêng tư chi phối việc bạn truy cập và sử dụng Trang web và Dịch vụ của Instantwire',
    },
    transaction_history: {
      title: 'Instantwire | Lịch Sử Giao Dịch',
      description:
        'Theo dõi lịch sử giao dịch chuyển tiền toàn cầu của bạn với thông tin luôn đảm bảo đầy đủ và chính xác tuyệt đối',
    },
    transfer_detail: {
      title: 'Instantwire | Chi Tiết Giao Dịch',
      description: 'Xem chi tiết giao dịch chuyển khoản với thông tin chính xác tuyệt đối',
    },
    transfer_init: {
      title: 'Instantwire | Tạo Giao Dịch',
      description:
        'Bắt đầu giao dịch chuyển khoản ngân hàng miễn phí của bạn với giao diện đơn giản',
    },
    wire_race: {
      title: 'Instantwire | Sự Kiện Wire Race',
      description:
        'Tranh hạng cùng InstantWire! Cùng rủ rê chiến hữu leo hạng để rinh phần thưởng siêu đỉnh! Càng nhiều bạn bè tham gia, giải thưởng càng hấp dẫn!',
    },
    bank_detail: {
      title: 'Instantwire | Thông Tin Người Nhận',
      description: 'Điền thông tin người nhận để thực hiện việc chuyển tiền trên Instantwire',
    },
    kyc: {
      title: 'KYC trên Bridge',
      start_title: 'Bắt đầu KYC trên Bridge',
      start_button: 'Bắt đầu xác minh để nhận ngay $3',
      first_part_description: 'Bạn sắp bắt đầu quy trình KYC với ',
      second_part_description:
        ', đối tác KYC đáng tin cậy của Instantwire. Bridge sẽ xác minh danh tính của bạn một cách an toàn để đảm bảo trải nghiệm an toàn và tuân thủ trên nền tảng của chúng tôi. Hãy yên tâm rằng thông tin của bạn được xử lý theo tiêu chuẩn cao nhất về bảo mật và quyền riêng tư.',
      verify_status: 'Trạng thái xác minh',
      status_pending: 'ĐANG XỬ LÝ',
      status_pending_description: 'Tài liệu của bạn đang được xử lý.',
      back_to_home: 'Quay lại trang chủ',
      status_rejected: 'THẤT BẠI',
      status_rejected_description: 'Tài liệu của bạn cần được cập nhật.',
      verify_again: 'Xác minh lại',
      status_approved: 'THÀNH CÔNG',
      continue: 'Tiếp tục',
      check_latest_status: 'Kiểm tra trạng thái mới nhất',
      account_temporary_blocked: 'Tài khoản bị khoá tạm thời',
      kyc_blocked_third_failed:
        'Nỗ lực xác minh KYC của bạn đã thất bại lần thứ ba. Vui lòng chờ <b>1 giờ</b> trước khi thử lại.',
      kyc_blocked_fourth_failed:
        'Nỗ lực xác minh KYC lần thứ tư của bạn đã thất bại. Vui lòng chờ <b>24 giờ</b> trước khi thử lại.',
      kyc_blocked_fifth_failed:
        'Tài khoản của bạn đã tạm thời bị khóa do vượt quá số lần thử xác minh KYC tối đa (5 lần). Vì lý do bảo mật và để ngăn chặn các hoạt động gian lận, bạn sẽ cần liên hệ với bộ phận Hỗ trợ Khách hàng của chúng tôi để xem xét và có thể mở khóa tài khoản của bạn.',
      unusual_activity_detected: 'Phát hiện hoạt động bất thường',
      unusual_activity_detected_desc_1:
        'Chúng tôi đã phát hiện nhiều lần thử xác minh KYC không thành công từ thiết bị này. Vì lý do bảo mật, xác minh KYC đã tạm thời bị vô hiệu hóa cho thiết bị này.',
      unusual_activity_detected_desc_2:
        'Nếu bạn cho rằng đây là một lỗi, vui lòng liên hệ với đội ngũ hỗ trợ của chúng tôi.',
      unusual_activity_detected_desc_3:
        'Lưu ý: Hạn chế này áp dụng cho tất cả các tài khoản truy cập từ thiết bị này.',
      unavailable_title: 'Dịch Vụ KYC chưa Khả Dụng tại Khu Vực của Bạn',
      unavailable_desc:
        'Hiện tại, Instantwire chưa hỗ trợ quy trình xác minh danh tính (KYC) tại quốc gia hoặc vùng lãnh thổ của bạn. Chúng tôi đang nỗ lực để mở rộng dịch vụ và sẽ thông báo ngay khi có thể hỗ trợ khu vực của bạn.',
    },
  },
  main_menu: {
    home: 'Trang chủ',
    transactions: 'Giao dịch',
    recipients: 'Người nhận',
    settings: 'Cài đặt',
  },
  error: {
    back_to_home: 'Về trang chủ',
    err_404: {
      title: 'Oops! Trang này không tồn tại.',
    },
    err_500: {
      title: 'Lỗi hệ thống!',
      description: 'Xin lỗi vì sự bất tiện, chúng tôi hiện đang xử lý vấn đề này!',
    },
    maintenance: {
      title: 'Bảo Trì Hệ Thống',
      description: 'Xin lỗi vì sự bất tiện, chúng tôi đang trong quá trình bảo trì.',
    },
  },
  footer: {
    title: 'Khám Phá Sự Khác Biệt',
    description:
      'Instantwire giúp bạn chuyển tiền từ Mỹ về Việt Nam nhanh chóng, an toàn và tiết kiệm để kết nối tài chính dễ dàng với người thân yêu.',
    main_menu: 'Trang Chính',
    main_menu_home: 'Trang chủ',
    main_menu_exclusive_deals: 'Ưu đãi độc quyền',
    main_menu_wire_race: 'Wire Race',
    company_others: 'Khác',
    company_policy: 'Chính sách bảo mật',
    company_terms: 'Điều khoản sử dụng',
    privacy_policy: 'Chính sách về quyền riêng tư',
    about_us: 'Về chúng tôi',
  },
  pre_footer: {
    title: 'Gác lại chờ đợi, gửi tiền nhanh như chớp!',
    description: 'Thoát khỏi lo âu xếp hàng, tận hưởng chuyển khoản tức thời với Instantwire.',
    send_money_now: 'Chuyển tiền ngay',
  },
  liquidity: {
    loading_positions: 'Đang tải các vị thế...',
    no_positions_found: 'Không tìm thấy vị thế nào.',
    position: {
      status: 'Trạng thái:',
      status_above_range: 'Above range',
      status_in_range: 'In range',
      status_below_range: 'Below range',
      current_amount: 'Số lượng hiện tại:',
      uncollected_fees: 'Phí chưa nhận:',
      apr: 'APR:',
      stop: 'Đóng',
      stopping: 'Đang đóng...',
    },
    create_pool_form: {
      header: 'Khởi tạo hồ thanh khoản',
      button_submit: 'Tạo mới',
      button_submitting: 'Đang khởi tạo...',
      initial_price: 'Giá ban đầu',
      fee_tier: 'Mức phí chuyển lần đầu',
      success_message: 'Hồ thanh khoản đã được khởi tạo thành công và đang chờ phê duyệt.',
      error_message: 'Không thể khởi tạo hoặc yêu cầu phê duyệt hồ thanh khoản. Vui lòng thử lại.',
      message_dialog_button_close: 'Đóng',
      button_pool_duplicate: 'Pool đã tồn tại',
      swap_prices: 'Hoán đổi giá',
    },
    bar: {
      pools: 'Hồ',
      portfolio: 'Vị thế',
      create_pool_button: 'Tạo hồ mới',
    },
    pool: {
      total_liquidity: 'Tổng thanh khoản',
      volume_24h: '24h volume',
      APR: 'APR',
      deposit: 'Tham gia',
    },
    pool_search_placeholder: 'Nhập địa chỉ token, tên token hoặc địa chỉ hồ thanh khoản',
    no_results_found: 'Không tìm thấy kết quả nào.',
    no_pools_found: 'Chưa có hồ thanh khoản nào.',
    open_position: {
      add_new_position: 'Tạo vị thế mới',
      deposit_ratio: 'Tỉ lệ',
      current_price: 'Giá hiện tại',
      min_price: 'Giá tối thiểu',
      max_price: 'Giá tối đa',
      max_balance: 'Max',
      total: 'Tổng',
      deposit_button: 'Khởi tạo',
      revert_prices_icon: 'Hoán đổi giá',
      errors: {
        must_be_gt_min_price: 'Phải lớn hơn giá tối thiểu',
        must_be_lt_max_price: 'Phải nhỏ hơn giá tối đa',
        must_be_a_number: 'Phải là số hợp lệ',
      },
    },
  },
  quota: {
    copied_address_snackbar: 'Đã copy địa chỉ.',
    profile: 'Hồ sơ',
    kyc: 'Xác minh',
    now: 'ngay',
    unverified: 'Chưa xác minh',
  },
  core_tokens_list: {
    title: 'Các token hàng đầu trên NemoSwap',
    table_headers: {
      token_name: 'Tên token',
      price: 'Giá',
      about: 'Mô tả',
      action: 'Thao tác',
    },
    token_detail: {
      title: 'Thông tin token',
      stats: 'Số liệu',
      volume_24h: 'Khối lượng 24h',
      link: 'Đường dẫn',
      website: 'Trang web',
      close: 'Đóng',
    },
    search_placeholder: 'Nhập tên, kí hiệu, hoặc địa chỉ token',
    more: 'Thêm',
    trade: 'Giao dịch',
    detail: 'Chi tiết',
  },
  terms_of_use: 'Điều khoản sử dụng',
  survey: {
    back: 'Quay lại',
    description:
      'Với <span class="font-bold">Instantwire</span>, bạn có thể dễ dàng chuyển tiền đi nước ngoài cho những người thân yêu của mình mọi lúc mọi nơi một cách an toàn. Với tỷ giá tốt và miễn phí đổi ngoại tệ, <span class="font-bold">Instantwire</span> đảm bảo gia đình và bạn bè của bạn nhận được số tiền nhiều nhất có thể.',
    answer_question:
      'Để tham gia trải nghiệm sớm, bạn hãy trả lời 4 câu hỏi khảo sát để giúp chúng tôi phát triển sản phẩm tốt hơn',
    thank_you: 'Xin cảm ơn',
    added_email: 'Địa chỉ email của bạn đã được thêm vào danh sách.',
    priority_access: 'Bạn muốn được ưu tiên truy cập sớm?',
    invite:
      'Mời bạn bè và gia đình của bạn tham gia <span class="font-bold">Instantwire</span>. Bạn giới thiệu càng nhiều, bạn càng có cơ hội truy cập sớm hơn.',
    share_now: 'Chia sẻ với bạn bè và gia đình',
    share_via: 'Chia sẻ qua:',
    copy_link: 'Hoặc sao chép đường dẫn',
    copy: 'Sao chép',
    copied: 'Đã sao chép!',
    done: 'Xong',
    submit_error: 'Có lỗi đã xảy ra. Xin vui lòng thử lại.',
    answer_required_question: 'Xin vui lòng trả lời tất cả câu hỏi',
    people_ahead_of_you: (counter: number) =>
      `Đã có <span class="font-extrabold">${counter.toLocaleString('vi')}</span> đăng ký trước bạn`,
    reservation_held: (email: string) =>
      `Suất đặt chỗ này được đăng ký với <span class="font-bold">${email}</span>`,
  },
  landing: {
    hero: {
      slogan: 'Thanh Toán Tại Nhà<br>Nhận Tiền Đa Quốc Gia',
      connect:
        'Chúng tôi kết nối thế giới với bạn thông qua giao dịch P2P, đảm bảo tiền của bạn được giữ an toàn tại quốc gia mình và nhanh chóng đến tay người nhận trên khắp thế giới.',
      work: 'Xem cách chúng tôi hoạt động',
      reward: 'Nhận ưu đãi <b>"Giao dịch 0 Phí"</b> cho giao dịch đầu tiên.',
      no_of_users_created_transaction: 'người đã tạo lệnh chuyển tiền',
      no_fee: 'Miễn phí',
      arrive: 'Tiền sẽ tới tài khoản người nhận trong vòng',
      arrive_time: '15 phút',
      arrive_description:
        'Các giao dịch cần xác minh bổ sung có thể mất nhiều thời gian xử lý hơn, lên đến 24 giờ.',
    },
    hero_waitlist: {
      title_wait_list: (isMobile: boolean) =>
        isMobile
          ? 'Kết nối phương xa, chuyển tiền gần kề với <br/> <span class="text-iw-secondary">phí 0đ</span>'
          : 'Kết nối phương xa, chuyển <br/> tiền gần kề - <span class="text-iw-secondary"> phí 0đ </span>',
      title: (isMobile: boolean) =>
        isMobile
          ? 'Chuyển tiền <br/> từ Mỹ về Việt Nam <br/> <span class="text-iw-secondary">trong 15 phút</span>'
          : 'Chuyển tiền từ Mỹ về Việt Nam <span class="text-iw-secondary">trong 15 phút</span>',
      with_no_fee: ' <span class="text-iw-secondary">phí 0đ</span>',
      description:
        'Dễ dàng gửi và chuyển đổi USD thành VND về tài khoản ngân hàng tại Việt Nam - nhanh chóng, an toàn và tiết kiệm chi phí.',
      supported_country: 'Có mặt tại',
      join_wait_list_gmail: 'Tham gia danh sách chờ và nhận thưởng',
      users_on_the_wait_list: 'người dùng đã tham gia danh sách chờ. Hoàn toàn miễn phí!',
      view_intro: 'Xem giới thiệu',
    },
    stats: {
      title: 'Con số về chúng tôi',
      items: [
        { title: '200+', description: 'Giao dịch thành công' },
        { title: '97%', description: 'Khách hàng hài lòng' },
        { title: '< 8hrs', description: 'Thời gian chuyển tiền' },
        { title: '100+', description: 'Khách hàng tin dùng' },
      ],
    },
    early_benefits: {
      title: 'Đặc quyền cho khách hàng ưu tiên',
      items: [
        {
          title: 'Ưu tiên trải nghiệm',
          description:
            'Bỏ qua hàng chờ, bạn là người đầu tiên được khám phá chuyển tiền miễn phí cùng Instantwire',
        },
        {
          title: 'Nhận quà cực chất',
          description: 'Phần thưởng đến 30 USD tiền mặt cho khách hàng trúng giải',
        },
        {
          title: 'Điểm thưởng "xịn sò"',
          description: 'Đổi ngay tiền mặt hoặc các phần quà cực chất',
        },
        {
          title: 'Tiếp cận nội dung độc quyền',
          description: 'Nhận các bản tin, ưu đãi, và thông tin cập nhật mới nhất trước tiên!',
        },
      ],
    },
    why_to_choice: {
      title: 'Vì sao nên chọn <span class="text-iw-primary">Instantwire</span>?',
      description:
        'Instantwire sử dụng công nghệ blockchain để mang đến giải pháp chuyển tiền nhanh chóng, bảo mật và chi phí thấp, giúp bạn yên tâm kết nối tài chính với người thân yêu.',
      items: [
        {
          title: 'Nhanh trong tích tắc',
          description:
            'Tiền gửi từ Mỹ về Việt Nam sẽ đến tài khoản người nhận ngay lập tức, với thành gian tối đa chỉ 15 phút.',
        },
        {
          title: 'Bảo mật an toàn',
          description:
            'Với công nghệ bảo mật cao và luôn sẵn sàng hỗ trợ khách hàng 24/7, Instantwire đảm bảo thông tin và tiền của bạn được bảo vệ tối đa, giúp bạn yên tâm khi chuyển tiền.',
        },
        {
          title: 'Tối ưu chi phí',
          description:
            'Instantwire mang đến mức phí chuyển tiền hấp dẫn, giúp bạn tiết kiệm chi phí mà vẫn đảm bảo tốc độ và an toàn cho mỗi giao dịch.',
        },
      ],
      send_money_now: 'Chuyển tiền ngay',
      title_gap_cta:
        'Tạo giao dịch chuyển tiền chỉ trong tích tắc cùng <span class="text-iw-primary">Instantwire</span>',
    },
    hero_all_set: {
      title: 'Chúc mừng, bạn đã là khách hàng ưu tiên!',
      description:
        'Email của bạn đã được thêm thành công vào danh sách đăng ký của chúng tôi. Hãy đón chờ những tin tức thú vị sắp đến!',
      follow_us: 'Theo dõi chúng tôi qua kênh chính thức',
      kyc_now: 'KYC ngay',
      later: 'Để sau',
      complete_kyc: 'Hoàn thành KYC và nhận ngay 3 USD!',
    },
    hero_ompetition: {
      title: 'Cơn bão đua hạng đã tới!',
      description:
        'khách hàng đang chạy nước rút để giành 1,809 USD! Bạn có muốn về đích cùng họ không? Tham gia cuộc đua ngay, phá đảo bảng xếp hạng và mang về giải thưởng xứng đáng!',
      check_it_now: 'Tranh hạng ngay',
      people_ahead_you: 'Người xếp trước bạn',
      behind_you: 'Người xếp sau bạn',
    },
    join: {
      join_instantwire: 'Tham gia cộng đồng Instantwire',
      join_instantwire_description:
        'Hãy gia nhập cộng đồng toàn cầu của chúng tôi để cập nhật thông tin, nhận sự hỗ trợ và trở thành một phần của mạng lưới chuyển tiền đa quốc gia',
    },
    footer: {
      different: 'Khám Phá Sự Khác Biệt',
      join_and_investigate:
        'Hãy tham gia cùng chúng tôi và khám phá một trải nghiệm hoàn toàn mới khi chuyển tiền xuyên biên giới.',
      zero_fee: 'Nhận ưu đãi 0đ PHÍ GIAO DỊCH cho giao dịch đầu tiên.',
      register_now: 'Đăng ký ngay',
    },
    question_answer: {
      title: 'Câu hỏi thường gặp',
      tabs: {
        general: 'Giới thiệu chung',
        before_you_transfer: 'Trước chuyển tiền',
        during_the_transfer: 'Trong khi chuyển tiền',
        after_the_transfer: 'Sau khi chuyển tiền',
      },
      details: {
        general: [
          {
            question: 'Instantwire là gì?',
            answer:
              'Instantwire là một nền tảng chuyển tiền quốc tế ngang hàng (P2P) dựa trên công nghệ blockchain, cho phép bạn gửi tiền <strong>nhanh chóng</strong>, <strong>linh hoạt</strong> và <strong>không mất phí</strong> so với các dịch vụ chuyển tiền truyền thống.',
          },
          {
            question: 'Instantwire hoạt động như thế nào?',
            answer:
              'InstantWire thiết lập kết nối trực tiếp giữa bạn và người gửi tiền theo chiều ngược lại, mang đến giải pháp chuyển tiền an toàn và thuận lợi trên nền tảng trực tuyến bảo mật.' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li><strong>Ghép đôi:</strong> Sau khi bạn xác nhận thông tin về người nhận và số tiền muốn gửi, Instantwire sẽ kết nối bạn với người dùng khác có nhu cầu chuyển tiền theo chiều ngược lại.</li>' +
              ' <li><strong>Mã hoá:</strong> Sau khi bạn thanh toán cho yêu cầu chuyển tiền của mình. Instantwire sẽ mã hoá số tiền này và lưu trữ an toàn trong tài khoản của bạn thông qua công nghệ blockchain.</li>' +
              ' <li><strong>Quy đổi tiền tệ:</strong> Số tiền sau khi mã hoá sẽ được được chuyển đổi sang đơn vị tiền tệ của quốc gia người nhận, áp dụng tỷ giá trên thị trường.</li>' +
              ' <li><strong>Giao dịch nội địa:</strong> Để chuyển tiền đi, bạn sẽ thanh toán cho một người dùng khác ở cùng quốc gia với bạn nhờ vào cơ chế Ghép đôi. Người nhận sẽ nhận được tiền từ một người dùng ở cùng quốc gia với họ có nhu cầu chuyển tiền theo chiều ngược lại.</li>' +
              '</ul>' +
              '<br/>Để hiểu thêm, bạn xem ví dụ mô phỏng bên dưới:' +
              '<br/><br/><img src="/images/instantwire-work.jpg" alt="instantwire-work" />' +
              '<br/><br/>Khi Bob, người đang sinh sống ở Mỹ, muốn gửi 50 USD cho Hùng ở Việt Nam, InstantWire không thực hiện chuyển khoản xuyên biên giới này. Thay vào đó, hệ thống sẽ tìm một giao dịch tương ứng trong mạng lưới kết nối nội bộ.' +
              '<br/><br/>Trong ví dụ này, Alice - người đang sinh sông ở Mỹ, sẽ nhận được một khoản tiền tương đương 50 USD sau khi quy đổi từ 1.200.000 VND theo tỷ giá giữa USD - VND tương tự từ Nam gửi từ Việt Nam sang. Khi đó, giao dịch chuyển tiền của Nam được ghép nối với giao dịch chuyển tiền của Bod ở chiều ngược lại.' +
              '<br/><br/>Vì vậy, để thực hiện việc thanh toán cho giao dịch chuyển tiền của mình, Bob sẽ thanh toán 50 USD cho Alice, người cùng sinh sống ở Mỹ với Bod; và Nam sẽ thanh toán 1.200.000 VND bằng cách chuyển khoản ngân hàng cho Hùng để tiến hành giao dịch chuyển tiền quốc tế của mình, Nam và Hùng cùng sinh sống ở Việt Nam.' +
              '<br/><br/>Instantwire là trung gian đứng giữa với cơ chế khoanh giữ đối với mọi số tiền đang chờ được giao dịch trong mạng lưới của mình. Trong ví dụ trên, Alice phải xác nhận đã nhận được tiền từ Bob thì Nam mới thực hiện thanh toán cho Hùng. Dịch vụ lưu ký này bổ sung thêm một lớp bảo vệ khác cho người dùng khi sử dụng Instantwire. Sử dụng dịch vụ lưu ký giúp giảm nguy cơ trộm cắp, lừa đảo và nguy cơ bị kẻ xấu lấy cắp tài sản khi chúng chưa hoàn thành trách nhiệm tương ứng trong giao dịch.',
          },
          {
            question: 'Làm sao để bắt đầu sử dụng Instantwire?',
            answer:
              '<br/>1. Truy cập trang web của Instantwire.' +
              '<br/>2. Đăng nhập bằng tài khoản Gmail của bạn.' +
              '<br/>3. Hoàn thành quy trình KYC (Xác minh nhận dạng khách hàng) nếu cần.' +
              '<br/>4. Vậy là bạn đã sẵn sàng gửi và nhận tiền rồi!',
          },
          {
            question: 'Instantwire hỗ trợ những loại tiền nào?',
            answer:
              'Hiện tại, Instantwire hỗ trợ đồng VND và USD, và chúng tôi đang có kế hoạch bổ sung thêm nhiều loại tiền khác trong tương lai.',
          },
        ],
        before_you_transfer: [
          {
            question:
              'Gửi tiền từ Mỹ sang Việt Nam qua Instantwire có nhanh và rẻ hơn dịch vụ truyền thống không?',
            answer:
              'Instantwire cung cấp dịch vụ chuyển tiền nhanh chóng (thường chỉ trong vài phút) mà không thu phí, trong khi các dịch vụ truyền thống có thể mất vài ngày và tính phí cao.',
          },
          {
            question: 'Số tiền tối thiểu và tối đa tôi có thể gửi qua Instantwire là bao nhiêu?',
            answer:
              'Số tiền tối thiểu bạn có thể gửi là 500,000 VND hoặc $20.' +
              '<br/>Số tiền tối đa bạn có thể gửi cho 1 lần chuyển tiền phụ thuộc vào trạng thái xác thực tài khoản của bạn như sau:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li>Chưa xác thực tài khoản:' +
              '   <br/><span class="ml-10">- VND: 5,000,000đ</span>' +
              '   <br/><span class="ml-10">- USD: $250</span>' +
              ' </li>' +
              ' <li>Đã xác thực số điện thoại:' +
              '   <br/><span class="ml-10">- VND: 10,000,000đ</span>' +
              '   <br/><span class="ml-10">- USD: $500</span>' +
              ' </li>' +
              ' <li>Đã xác thực khuôn mặt và danh tính:' +
              '   <br/><span class="ml-10">- VND: 100,000,000đ</span>' +
              '   <br/><span class="ml-10">- USD: $5000</span>' +
              ' </li>' +
              '</ul>' +
              '<br/>Tuy nhiên, trong 1 ngày bạn sẽ không được chuyển quá 400.000.000đ hoặc 15,000$',
          },
          {
            question: 'Instantwire có an toàn và bảo mật không?',
            answer:
              'Chắc chắn! Chúng tôi sử dụng công nghệ blockchain và mã hóa an toàn để bảo vệ các giao dịch và thông tin cá nhân của bạn.' +
              '<br/>Bên cạnh đó, Instantwire đưa ra các yêu cầu bên dưới để đảm bảo giao dịch an toàn:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li><strong>Xác minh danh tính nghiêm ngặt</strong>' +
              '   <br/><span class="ml-10">- Xác minh khuôn mặt</span>' +
              '   <br/><span class="ml-10">- Thông tin lý lịch</span>' +
              '   <br/><span class="ml-10">- Thông tin cơ bản</span>' +
              ' </li>' +
              ' <li><strong>Quản lý rủi ro nghiêm ngặt.</strong> Instantwire giới hạn số tiền giao dịch theo thông tin định danh, nhiều điểm yêu cầu xác minh khuôn mặt và kiểm tra chống rửa tiền để đảm bảo tiền của bạn được an toàn.</li>' +
              ' <li><strong>Hỗ trợ khách hàng 24/7.</strong> Instantwire cung cấp dịch vụ khách hàng trực tuyến 24/7 để đảm bảo các vấn đề của bạn được giải quyết 1 cách nhanh chóng và dễ dàng.</li>' +
              '</ul>',
          },
          {
            question: 'Có bất kỳ khoản phí ẩn nào khi sử dụng Instantwire không?',
            answer:
              'Chúng tôi đặt tín nhiệm vào sự minh bạch. Bạn sẽ không gặp bất kỳ khoản phí ẩn hoặc phụ phí nào ngoài những khoản hiển thị rõ ràng trong quá trình khởi tạo giao dịch chuyển tiền',
          },
          {
            question: 'Tỷ giá hối đo được xác định như thế nào, ai là người quy định?',
            answer:
              'Tỷ giá hối đo dựa trên dữ liệu thị trường trực tiếp, phản ánh sự biến động của giá trị tiền tệ theo thời gian thực. Chúng tôi cập nhật tỷ giá mỗi phút, đảm bảo bạn nhận được giá thị trường hiện tại mà không bị thao túng.',
          },
          {
            question: 'Có bất kỳ khoản phí bổ sung nào khi nhận tiền ở Việt Nam không?',
            answer:
              'Người nhận sẽ nhận được toàn bộ số tiền trực tiếp vào tài khoản ngân hàng của họ hoặc bất kỳ tài khoản nào do bạn cung cấp. Người nhận sẽ không phải chịu bất kỳ khoản phí bổ sung nào ở đầu nhận.',
          },
          {
            question:
              'Tôi có phải trả bất kỳ khoản thuế nào đối với số tiền nhận được qua Instantwire không?',
            answer:
              'Thuế đối với số tiền nhận được phụ thuộc vào các quy định địa phương ở quốc gia người nhận. Chúng tôi khuyên bạn nên tham khảo ý kiến của chuyên gia thuế để được hướng dẫn cụ thể về các khoản thuế tiềm ẩn. Instantwire không chịu trách nhiệm về bất kỳ khoản thuế nào áp dụng cho số tiền nhận được.',
          },
        ],
        during_the_transfer: [
          {
            question: 'Tôi có thể chọn người mình được ghép đôi không?',
            answer:
              'Không, Instantwire tự động tìm đối tượng khớp tốt nhất dựa trên chi tiết chuyển khoản và người dùng sẵn có. Điều này đảm bảo một hệ thống công bằng và an toàn cho mọi người.',
          },
          {
            question: 'Làm sao để tôi thanh toán cho giao dịch?',
            answer:
              'Bạn có thể hoàn tất thanh toán bằng chuyển khoản ngân hàng sử dụng đơn vị tiền tệ địa phương của bạn. Để thanh toán, bạn có thể dùng bất kỳ hình thức thanh toán nào được hỗ trợ tại quốc gia của bạn để phục việc chuyển tiền qua lại với nhau.' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li>Nếu ở Việt Nam, bạn có thể thanh toán chuyển khoản thông qua dụng ứng dụng ngân hàng, Ví điện tử (MoMo, ZaloPay,...)</li>' +
              ' <li>Nếu ở Mỹ, bạn có thể thanh toán chuyển khoản thông qua ứng dụng ngân hàng, Zelle, Venmo, Ví điện tử (PayPal, Google Pay,...)</li>' +
              '</ul>' +
              '<br/>Lưu ý, để tránh rủi ro mất tiền, bạn cần chụp ảnh kết quả thanh toán để làm bằng chứng khi bấm xác nhận “Tôi đã chuyển tiền" trên Instantwire',
          },
          {
            question:
              'Điều gì xảy ra với số tiền đã chuyển của tôi nếu nền tảng gặp vấn đề kỹ thuật?',
            answer:
              'Trong trường hợp hiếm hoi xảy ra sự cố kỹ thuật, số tiền bạn đã chuyển sẽ được giữ an toàn trong tài khoản ủy thác. Chúng tôi có kế hoạch dự phòng để giải quyết các vấn đề kỹ thuật kịp thời, đảm bảo sự an toàn và toàn vẹn của tiền của bạn. Đội ngũ chăm sóc khách hàng 24/7 của chúng tôi cũng sẵn sàng hỗ trợ trong suốt quá trình thực hiện giao dịch chuyển tiền của bạn' +
              '<br/><br/>Instantwire sử dụng cơ chế khoanh giữ đối với mọi số tiền đang chờ được giao dịch trong mạng lưới của mình. Dịch vụ lưu ký này bổ sung thêm một lớp bảo vệ khác cho người dùng khi sử dụng Instantwire. Sử dụng dịch vụ lưu ký giúp giảm nguy cơ trộm cắp, lừa đảo và nguy cơ bị kẻ xấu lấy cắp tài sản khi chúng chưa hoàn thành trách nhiệm tương ứng trong giao dịch.',
          },
          {
            question: 'Tôi có thể hủy bỏ giao dịch sau khi đã bắt đầu không?',
            answer:
              'Chúng tôi hiểu rằng mọi thứ không phải lúc nào cũng diễn ra theo kế hoạch. Dưới đây là câu trả lời cho một số tình huống phổ biến bạn có thể gặp phải:<br/>' +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              ' <li><strong>Trước khi khớp với người dùng khác:</strong> Bạn có thể hủy bỏ giao dịch bất kỳ lúc nào trước khi nó được ghép với người dùng khác. Không thu phí hủy bỏ.</li>' +
              ' <li><strong>Sau khi khớp, và bạn chưa thanh toán cho bất kỳ lệnh thanh toán nào:</strong> Bạn có thể hủy bỏ giao dịch bất kỳ lúc nào trước khi nó được ghép với người dùng khác. Không thu phí hủy bỏ.</li>' +
              ' <li><strong>Sau khi khớp, và bạn đã thanh toán cho ít nhất 1 lệnh thanh toán:</strong> Bạn sẽ không thể huỷ bỏ giao dịch một khi đã thực hiện việc thanh toán. Do đó, hãy kiểm tra thật kỹ giao dịch trước khi thực hiện thanh toán.</li>' +
              '</ul>',
          },
          {
            question:
              'Điều gì xảy ra nếu thông tin tài khoản ngân hàng của người nhận không chính xác?',
            answer:
              'Nếu bạn nhập chi tiết ngân hàng không chính xác, chúng tôi sẽ cố gắng liên hệ với bạn để xác nhận. Nếu thông tin được xác nhận không chính xác, giao dịch có thể bị hủy bỏ và phí hủy có thể áp dụng. Kiểm tra kỹ thông tin người nhận là rất quan trọng để tránh chậm trễ hoặc các vấn đề tiềm ẩn.',
          },
          {
            question:
              'Vì sao khi chuyển tiền, tôi phải thực hiện thanh toán nhiều lần cho nhiều hoá đơn thanh toán?',
            answer:
              'Instantwire hoạt động dựa trên cơ chế ghép đôi để kết nối những người dùng có nhu cầu chuyển tiền giữa hai quốc gia Mỹ và Việt Nam và cơ chế giao dịch ngang hàng (P2P). Trong điều kiện lý tưởng và ghép đôi hoàn hảo, bạn sẽ chỉ cần thanh toán cho 1 hoá đơn thanh toán duy nhất khi chỉ cần có 1 người dùng khác để đủ khớp với số tiền mà bạn muốn chuyển đến người nhận của mình' +
              '<br/><br/>Tuy nhiên, sẽ có những tình huống mà bạn sẽ phải thanh toán nhiều lần do Instantwire phải kết hợp nhiều người có nhu cầu chuyển tiền ở chiều ngược với bạn để tổng số tiền của họ mới khớp đủ và chính xác với số tiền mà bạn muốn chuyển cho người nhận của mình. Một số tình huống phổ biến như:' +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              ' <li>Số tiền bạn chuyển tương đối lớn</li>' +
              ' <li>Ở thời điểm bạn chuyển tiền, Instantwire không tìm được bất kỳ duy nhất 1 người dùng nào khác cũng chuyển tiền đúng với số tiền mà bạn chuyển' +
              '</ul>' +
              '<br/>Như vậy, việc ghép nối yêu cầu chuyển tiền của bạn sẽ được diễn ra nhanh chóng. Khi đó, bạn sẽ thấy mình phải thanh toán nhiều lần khác nhau cho những người khác nhau ở trong cùng quốc gia với bạn.' +
              '<br/><br/>Lưu ý, Instantwire đảm bảo bạn sẽ không thanh toán nhiều hơn 3 hoá đơn cho một giao dịch chuyển tiền của mình.',
          },
          {
            question:
              'Vì sao tôi phải thanh toán cho một người khác chứ không phải là người nhận của mình?',
            answer:
              'Instantwire hoạt động dựa trên cơ chế ghép đôi để kết nối những người dùng có nhu cầu chuyển tiền giữa hai quốc gia Mỹ và Việt Nam và cơ chế giao dịch ngang hàng (P2P).' +
              '<br/>Để hiểu về lý do, bạn có thể xem ví dụ mô phỏng bên dưới:' +
              '<br/><br/><img src="/images/instantwire-work.jpg" alt="instantwire-work" />' +
              '<br/><br/>Khi Bob, người đang sinh sống ở Mỹ, muốn gửi 50 USD cho Hùng ở Việt Nam, InstantWire không thực hiện chuyển khoản xuyên biên giới này. Thay vào đó, hệ thống sẽ tìm một giao dịch tương ứng trong mạng lưới kết nối nội bộ.' +
              '<br/><br/>Trong ví dụ này, Alice - người đang sinh sông ở Mỹ, sẽ nhận được một khoản tiền tương đương 50 USD sau khi quy đổi từ 1.200.000 VND theo tỷ giá giữa USD - VND tương tự từ Nam gửi từ Việt Nam sang. Khi đó, giao dịch chuyển tiền của Nam được ghép nối với giao dịch chuyển tiền của Bod ở chiều ngược lại.' +
              '<br/><br/>Vì vậy, để thực hiện việc thanh toán cho giao dịch chuyển tiền của mình, Bob sẽ thanh toán 50 USD cho Alice, người cùng sinh sống ở Mỹ với Bod; và Nam sẽ thanh toán 1.200.000 VND bằng cách chuyển khoản ngân hàng cho Hùng để tiến hành giao dịch chuyển tiền quốc tế của mình, Nam và Hùng cùng sinh sống ở Việt Nam.' +
              '<br/><br/>Instantwire là trung gian đứng giữa với cơ chế khoanh giữ đối với mọi số tiền đang chờ được giao dịch trong mạng lưới của mình. Trong ví dụ trên, Alice phải xác nhận đã nhận được tiền từ Bob thì Nam mới thực hiện thanh toán cho Hùng. Dịch vụ lưu ký này bổ sung thêm một lớp bảo vệ khác cho người dùng khi sử dụng Instantwire. Sử dụng dịch vụ lưu ký giúp giảm nguy cơ trộm cắp, lừa đảo và nguy cơ bị kẻ xấu lấy cắp tài sản khi chúng chưa hoàn thành trách nhiệm tương ứng trong giao dịch.',
          },
          {
            question: 'Cách đảm bảo giao dịch không có sai sót và rủi ro',
            answer:
              'Sau khi bạn tìm thấy một đề nghị giao dịch phù hợp, hãy ghi nhớ một số điều quan trọng. Trước tiên, hãy đảm bảo bạn đã đọc kỹ các điều khoản và điều kiện của giao dịch trước khi tiếp tục.' +
              '<br/><br/>Lưu ý, yêu cầu thanh toán hiển thị trên Instantwire ở bước “Bạn thanh toán" đều có một khung thời gian thanh toán (thường kéo dài từ 15 phút đến 3 giờ tùy vào thời gian mà người bán đã đặt). Bạn không nên bắt đầu giao dịch nếu đang dở việc quan trọng, chẳng hạn như một cuộc họp hoặc một buổi hẹn hò ăn tối.' +
              '<br/><br/><strong>Chú ý đến đồng hồ đếm giờ</strong>' +
              '<br/>Tại thời điểm Instantwire đã tìm thấy một đề nghị giao dịch phù hợp, đồng hồ đếm ngược sẽ bắt đầu, thông báo cho bạn biết bạn còn bao nhiêu thời gian để thanh toán và đánh dấu giao dịch là đã thanh toán. Khi gửi tiền, hãy luôn kiểm tra kỹ số tiền trước khi tiếp tục.' +
              '<br/><br/><strong>Hoàn tất lệnh</strong>' +
              '<br/>Vẫn còn một bước nữa sau khi bạn gửi tiền. Đánh dấu lệnh là đã thanh toán bằng cách nhấn vào nút “Tôi đã chuyển tiền". Sau bước này, Instantwire sẽ bắt đầu chuyển đổi tiền tệ và yêu cầu người gửi ở chiều ngược lại tiến hành thanh toán cho người nhận của bạn.',
          },
          {
            question: 'Lưu ý khi thanh toán sau khi hệ thống đã tìm thấy giao dịch phù hợp',
            answer:
              '' +
              '<ul class="list-disc list-inside space-y-2 mt-4">' +
              ' <li>Tài khoản ngân hàng chính chủ. Thông tin tài khoản ngân hàng của người cần được thanh toán hiển thị trên Instantwire và thông tin hiển thị trong ứng dụng ngân hàng phải giống nhau.</li>' +
              ' <li>Chuyển khoản khác ngân hàng thì bắt buộc dùng chuyển tiền nhanh 24/7</li>' +
              ' <li>Nội dung chuyển khoản ghi đúng yêu cầu và chính xác với thông tin hiển thị trên Instantwire</li>' +
              ' <li>Chuyển khoản xong mới ấn nút “Tôi đã chuyển tiền”</li>' +
              '</ul>',
          },
          {
            question:
              'Tôi nhìn thấy thông báo “Trọng tài xử lý" sau khi bấm Đã chuyển tiền và tải lên bằng chứng',
            answer:
              'Nếu bạn đã hoàn tất thanh toán và nhấn vào "Tôi đã chuyển tiền” nhưng sau đó Instantwire hiển thị thông báo “Trọng tài xử lý", trước tiên bạn nên liên hệ với Trung tâm chăm sóc khách hàng thông qua công cụ hộp chat. Sau khi bạn đảm bảo rằng bạn đã tuân thủ các quy định của Instantwire và thực hiện đúng các thủ tục quy định (bao gồm nhưng không giới hạn ở việc cung cấp các chứng từ pháp lý có liên quan đáp ứng các yêu cầu của Instantwire), bạn có thể gửi khiếu nại về giao dịch.' +
              '<br/><br/>Đội ngũ Instantwire sẽ giúp bạn giải quyết khiếu nại dựa trên các quy tắc xử lý khiếu nại trong trường hợp Người dùng gửi khiếu nại. Khi đó, Instantwire với vai trò trọng tài sẽ có quyền đưa ra diễn giải và phán quyết cuối cùng đối với việc xử phạt các trường hợp khiếu nại. Việc mở lại khiếu nại sẽ do Instantwire toàn quyền quyết định theo từng trường hợp.',
          },
          {
            question:
              'Tôi có cần thiết phải bấm “Tôi đã chuyển tiền" sau khi thanh toán, và việc tải lên hình ảnh chứng mình tôi đã chuyển tiền có cần thiết không?',
            answer:
              'Instantwire hoạt động theo mô hình P2P. Đây là hình thức giao dịch trong đó bạn và người chuyển tiền ở chiều ngược lại với bạn sau khi ghép đôi thành công sẽ trực tiếp trao đổi tài sản với nhau. Instantwire là trung gian đứng giữa với cơ chế khoanh giữ đối với mọi số tiền đang chờ được giao dịch trong mạng lưới của mình. Dịch vụ lưu ký này bổ sung thêm một lớp bảo vệ khác cho người dùng khi sử dụng Instantwire. Sử dụng dịch vụ lưu ký giúp giảm nguy cơ trộm cắp, lừa đảo và nguy cơ bị kẻ xấu lấy cắp tài sản khi chúng chưa hoàn thành trách nhiệm tương ứng trong giao dịch.' +
              '<br/><br/>Xem lại ví dụ trong câu “Vì sao tôi phải thanh toán cho một người khác chứ không phải là người nhận của mình?”, Alice phải xác nhận đã nhận được tiền từ Bob thì Nam mới thực hiện thanh toán cho Hùng. Do vậy, việc bấm “Tôi đã chuyển tiền" và cung cấp bằng chứng thanh toán là bắt buộc và là điều kiện tiên quyết để người nhận của bạn ở quốc gia bên kia được thanh toán số tiền mà bạn muốn chuyển, thực hiện bởi người chuyển tiền ở chiều ngược lại được ghép đôi với bạn.',
          },
          {
            question:
              'Tại sao sau khi Instantwire thông báo đã chuyển tiền đến cho người nhận, tôi phải xác nhận “Người nhận đã nhận tiền"?',
            answer:
              'Instantwire hoạt động theo mô hình P2P. Đây là hình thức giao dịch trong đó bạn và người chuyển tiền ở chiều ngược lại với bạn sau khi ghép đôi thành công sẽ trực tiếp trao đổi tài sản với nhau. Instantwire là trung gian đứng giữa với cơ chế khoanh giữ đối với mọi số tiền đang chờ được giao dịch trong mạng lưới của mình. Dịch vụ lưu ký này bổ sung thêm một lớp bảo vệ khác cho người dùng khi sử dụng Instantwire. Sử dụng dịch vụ lưu ký giúp giảm nguy cơ trộm cắp, lừa đảo và nguy cơ bị kẻ xấu lấy cắp tài sản khi chúng chưa hoàn thành trách nhiệm tương ứng trong giao dịch.' +
              '<br/><br/>Xem lại ví dụ trong câu “Vì sao tôi phải thanh toán cho một người khác chứ không phải là người nhận của mình?”, Alice phải xác nhận đã nhận được tiền từ Bob thì Nam mới thực hiện thanh toán cho Hùng. <strong>Do vậy, việc bấm “Người nhận đã nhận tiền" là bắt buộc và là điều kiện tiên quyết để Instantwire thực hiện lệnh thanh toán</strong>, yêu cầu bạn thanh toán cho người nhận của người chuyển tiền ở chiều ngược lại đã được ghép đôi với bạn, đang ở cùng quốc gia với bạn.' +
              '<br/><br/>Để xác nhận người nhận đã nhận tiền thành công hay chưa, có thể lựa chọn các cách sau đây:' +
              '<ul class="list-decimal list-inside space-y-2 mt-4">' +
              ' <li>Bạn là người kiểm tra và xác nhận gián tiếp thay cho người nhận.' +
              '   <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '     <li>a. Bạn liên lạc trực tiếp với người nhận thông qua các chat app (Messenger, Whatsapp, Zalo, ...), điện thoại để yêu cầu người nhận kiểm tra tài khoản của họ đã có tiền chưa.</li>' +
              '     <li>b. Sau đó người nhận chủ động thông báo kết qủa với bạn.</li>' +
              '     <li>c. Bạn nhận thông tin và quay trở lại Instantwire để bấm nút “Người nhận đã nhận tiền".</li>' +
              '   </ul>' +
              ' </li>' +
              ' <li>Người nhận là người kiểm tra và xác nhận trực tiếp:' +
              '   <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '     <li>a. Người nhận nhận được thông tin về giao dịch chuyển tiền được gửi đến họ thông qua' +
              '       <ul class="list-none list-inside space-y-2 mt-4 ml-4">' +
              '         <li>i. Đường dẫn liên kết đến trang thông tin giao dịch chuyển tiền do bạn chia sẻ với người nhận thông qua các chat app (Messenger, Whatsapp, Zalo, ...)</li>' +
              '         <li>ii. Hệ thống Instantwire gửi đến hộp thư điện tử của người nhận đường dẫn liên kết đến trang thông tin giao dịch chuyển tiền (chỉ khi bạn cung cấp thông tin hộp thư điện tử của người nhận ở mục "Nhập thông tin người nhận"</li>' +
              '       </ul>' +
              '     </li>' +
              '     <li>b. Người nhận kiểm tra số dư tài khoản, và chủ động xác nhận</li>' +
              '     <li>c. Người nhận bấm “Tôi đã nhận tiền” trên màn hình hướng dẫn để xác nhận</li>' +
              '   </ul>' +
              ' </li>' +
              '</ul>' +
              '<br/><br/><strong>Xin lưu ý, tuyệt đối không bấm “Tôi đã nhận tiền” nếu là người nhận là người trực tiếp xác nhận hoặc “Người nhận đã nhận tiền" nếu bạn là người gián tiếp xác nhận hộ, khi</strong>:' +
              '<ul class="list-disc list-inside space-y-2 mt-4 ml-4">' +
              ' <li><strong>Bạn chưa liên hệ được người nhận</strong></li>' +
              ' <li><strong>Tiền chưa được ghi có đầy đủ vào tài khoản người nhận</strong></li>' +
              ' <li><strong>Số tiền nhận được không chính xác với số tiền Instantwire hiển thị trên giao dịch</strong></li>' +
              '</ul>' +
              '<br/>Hãy ghi nhớ các lưu ý trên để tránh khiếu nại dẫn đến kéo dài thời gian chuyển - nhận tiền, thậm chí rủi ro mất tiền.' +
              '<br/>Trong trường hợp có khiếu nại, bạn hãy liên hệ ngay đội ngũ chăm sóc khách hàng 24/7 của Instantwire để được hỗ trợ.',
          },
        ],
        after_the_transfer: [
          {
            question: 'Người nhận của tôi sẽ nhận được tiền trong bao lâu?',
            answer:
              'Trong hầu hết trường hợp, người nhận sẽ nhận được tiền trong vài phút, nhanh hơn đáng kể so với các phương thức truyền thống. Tuy nhiên, thời gian có thể bị ảnh hưởng bởi thời gian xử lý của ngân hàng tại địa phương của người nhận.',
          },
          {
            question:
              'Nếu người nhận của tôi không nhận được tiền sau khung thời gian dự kiến thì sao?',
            answer:
              'Đừng lo lắng! Hãy liên hệ ngay với đội ngũ hỗ trợ thân thiện của Instantwire. Chúng tôi sẵn sàng 24/7 để giúp bạn giải quyết bất kỳ vấn đề nào.',
          },
          {
            question: 'Người nhận của tôi có cần tài khoản Instantwire để nhận tiền không?',
            answer:
              'Không, người nhận chỉ cần có tài khoản ngân hàng ở tại quốc gia sở tại của họ. Người nhận không cần cài đặt bất kỳ phần mềm nào',
          },
        ],
      },
    },
    how_it_works: {
      title: 'Cách chúng tôi hoạt động',
      description:
        'InstantWire thiết lập kết nối trực tiếp giữa bạn và người gửi tiền theo chiều ngược lại, mang đến giải pháp chuyển tiền an toàn và thuận lợi trên nền tảng trực tuyến bảo mật.',
      items: [
        {
          title: 'Ghép đôi',
          description:
            'Sau khi bạn xác nhận thông tin về người nhận và số tiền muốn gửi, Instantwire sẽ kết nối bạn với người dùng khác có nhu cầu chuyển tiền theo chiều ngược lại.',
        },
        {
          title: 'Mã hoá',
          description:
            'Sau khi bạn thanh toán cho yêu cầu chuyển tiền của mình. Instantwire sẽ mã hoá số tiền này và lưu trữ an toàn trong tài khoản của bạn thông qua công nghệ blockchain.',
        },
        {
          title: 'Quy đổi tiền tệ',
          description:
            'Số tiền sau khi mã hoá sẽ được được chuyển đổi sang đơn vị tiền tệ của quốc gia người nhận, áp dụng tỷ giá trên thị trường.',
        },
        {
          title: 'Giao dịch nội địa',
          description:
            'Để chuyển tiền đi, bạn sẽ thanh toán cho một người dùng khác ở cùng quốc gia với bạn nhờ vào cơ chế Ghép đôi. Người nhận sẽ nhận được tiền từ một người dùng ở cùng quốc gia với họ có nhu cầu chuyển tiền theo chiều ngược lại.',
        },
      ],
    },
    steps_to_follow: {
      title: 'Các bước thực hiện',
      description:
        'Ngay sau khi tạo tài khoản và định danh cá nhân, bạn làm theo các bước sau đây để chuyển tiền:',
      steps: [
        {
          title: '01. Định danh tài khoản',
          description:
            'Đăng nhập với tài khoản Gmail và thực hiện xác minh danh tính trong vài phút để đảm bảo an toàn và bảo mật cho mỗi giao dịch của bạn.',
          image: 'steps-to-follow-1',
        },
        {
          title: '02. Tạo yêu cầu chuyển tiền',
          description:
            'Nhập thông tin số tiền và tài khoản nhận tại Việt Nam để tạo yêu cầu chuyển tiền.',
          image: 'steps-to-follow-2',
        },
        {
          title: '03. Thanh toán',
          description:
            'Lựa chọn phương thức thanh toán phù hợp và thực hiện chuyển khoản theo thông tin ngân hàng được hiển thị trên Instantwire để hoàn tất yêu cầu.',
          image: 'steps-to-follow-3',
        },
        {
          title: '04. Theo dõi và hoàn tất giao dịch',
          description:
            'Instantwire sẽ chuyển tiền đến tài khoản nhận tại Việt Nam trong thời gian ngắn nhất. Bạn có thể theo dõi trạng thái giao dịch và xác minh hoàn tất khi người nhận đã nhận đủ tiền vào tài khoản.',
          image: 'steps-to-follow-4',
        },
      ],
    },
    partners: {
      title: 'Đối Tác Chiến Lược',
      description:
        'Liên kết chặt chẽ với các nền tảng uy tín, cam kết mang đến trải nghiệm giao dịch nhanh chóng với mức phí tối ưu',
    },
    as_easy_as_it_get: {
      title: 'Thuận tiện tối ưu',
      description:
        'Ngay sau khi tạo tài khoản và định danh cá nhân, bạn làm theo các bước sau đây để chuyển tiền:',
      tabs: [
        { title: 'Nhập số tiền', description: 'Chuyển tiền quốc tế giữa Mỹ và Việt Nam' },
        {
          title: 'Nhập thông tin người nhận',
          description: 'Tiền chuyển trực tiếp vào tài khoản ngân hàng của người nhận',
        },
        { title: 'Thanh toán', description: 'Chuyển khoản ngân hàng để thanh toán' },
        {
          title: 'Theo dõi tiến độ',
          description: 'Check with your receiver and confirm the completed transfer',
        },
      ],
      details: [
        {
          title: 'Nhập số tiền',
          description: 'Chuyển tiền quốc tế giữa Mỹ và Việt Nam',
          items: [
            'Nhập số tiền cần chuyển theo đồng nội tệ',
            'Kiểm tra tỷ giá hối đoái',
            'Xác nhận để bắt đầu',
          ],
          note: '<strong class="text-iw-saffron-yellow">Chú ý:</strong> Tỷ giá hối đoái sẽ được cập nhập liên tục theo biến động của thị trường.',
        },
        {
          title: 'Nhập thông tin người nhận',
          description: 'Tiền chuyển trực tiếp vào tài khoản ngân hàng của người nhận',
          items: [
            'Nhập thông tin người nhận:<br/>' +
              '<ul class="list-disc list-inside space-y-2 mt-2 pl-0">' +
              ' <li>Số tài khoản ngân hàng</li>' +
              ' <li>Tên ngân hàng</li>' +
              ' <li>Chủ tài khoản</li>' +
              '</ul>',
            'Xác nhận',
          ],
          note: '<strong class="text-iw-saffron-yellow">Chú ý:</strong> Bạn có thể lưu thông tin người nhận để sử dụng cho những lần sau.',
        },
        {
          title: 'Thanh toán',
          description: 'Chuyển khoản ngân hàng để thanh toán',
          items: [
            'Thanh toán bằng cách chuyển khoản đến số tài khoản ngân hàng được chỉ định.',
            'Chụp ảnh màn hình kết quả giao dịch và tải lên bằng chứng.',
          ],
          note: '<strong class="text-iw-saffron-yellow">Chú ý:</strong> Luôn ghi nhớ lưu lại bằng chứng đã thanh toán sau khi chuyển khoản. <br/>Bạn có thể phải thanh toán nhiều lần nếu giá trị giao dịch lớn',
        },
        {
          title: 'Theo dõi tiến độ',
          description:
            'Đảm bảo người nhận đã nhận tiền và xác nhận với Instantwire về kết quả cuối cùng',
          items: [
            'Liên hệ với người nhận để đảm bảo họ đã nhận tiền.',
            'Bấm nút "Người nhận đã nhận tiền" để hoàn tất giao dịch.',
          ],
          note: '<strong class="text-iw-saffron-yellow">Chú ý:</strong> Người nhận của bạn sẽ nhận tiền từ 1 người khác cùng chung quốc gia với họ.',
        },
      ],
    },
    about_us: {
      title: 'Bạn có hứng thú muốn biết thêm về chúng tôi?',
      description:
        'Sẵn sàng cho một trải nghiệm Chuyển Tiền Xuyên Biên Giới Hoàn Toàn Mới: Đơn giản, Nhanh chóng và Không giới hạn!',
    },
  },
  wire_race: {
    hero: {
      title: 'Vòng đua ngoại hạng <br/><span class="text-iw-secondary">Wire Race</span>',
      description:
        'Tranh hạng cùng InstantWire! Cùng rủ rê chiến hữu leo hạng để rinh phần thưởng siêu đỉnh, và góp mặt trong danh sách ưu tiên trải nghiệm dịch vụ! Càng nhiều bạn bè tham gia, giải thưởng càng hấp dẫn!',
      sub_description:
        'Bạn đã sẵn sàng "càn quét" bảng xếp hạng chưa? Chinh phục mọi nhiệm vụ, tích lũy điểm Wire, phá đảo bảng xếp hạng để mở khóa quà khủng và khoe trọn chiến tích!',
      go_to_task_board: 'Tranh hạng ngay!',
      round_finished: 'Vòng 2 đã kết thúc!',
      next_round: (date: string) =>
        `Cùng chờ đón vòng 3 sẽ diễn ra vào ngày <span class="text-iw-secondary">${date}</span>`,
    },
    reward: {
      title: 'Càng cao càng đỉnh, càng <br/> thành càng sung',
      items: [
        { title: 'Top 3', amount: '24', unit: 'USD', get_more: 'Nhận thêm 10 USD' },
        { title: 'Top 20', amount: '12', unit: 'USD', get_more: 'Nhận thêm 5 USD' },
        { title: '20 giải may mắn', amount: '6', unit: 'USD', get_more: 'Nhận thêm 2.5 USD' },
        { title: 'Thêm điểm thưởng', amount: '14+', unit: 'wires', get_more: 'Nhận điểm Wire' },
      ],
    },
    question_answer: {
      title: 'Câu hỏi thường gặp',
      details: [
        {
          question: 'Làm sao để giành chiến thắng trong Giải Vô Địch Wire Race?',
          answer:
            'Bí quyết để chiến thắng nằm ở việc <strong>giới thiệu bạn bè và những người được giới thiệu hoàn thành giao dịch dự thảo!</strong> Mỗi lần đăng ký giới thiệu bạn sẽ kiếm được điểm, nhưng bạn sẽ nhận được nhiều điểm hơn khi họ thực hiện giao dịch dự thảo đầu tiên. Hãy nhớ rằng, bảng xếp hạng được thiết lập lại và cơ hội chiến thắng mới xuất hiện sau mỗi hai tuần!',
        },
        {
          question: 'Điểm Wire chính xác là gì và chúng hoạt động như thế nào?',
          answer:
            'Hãy nghĩ về Điểm Wire như chìa khóa để bạn leo lên bảng xếp hạng. Bạn kiếm được chúng bằng cách hoàn thành các nhiệm vụ khác nhau như tạo tài khoản, xác minh KYC, tham gia cộng đồng và tất nhiên, giới thiệu bạn bè. Bạn càng tích được nhiều điểm, thứ hạng của bạn trên bảng xếp hạng càng cao và bạn càng tiến gần đến chiến thắng!',
        },
        {
          question: 'Bạn có thể thắng bao nhiêu?',
          answer:
            'Không chỉ là danh hiệu trên bảng xếp hạng, bạn còn có thể thắng tiền mặt USD thực sự! Quỹ thưởng tăng lên dựa trên số lượng người giới thiệu tham gia mỗi vòng. Càng nhiều bạn bè thực hiện giao dịch bản nháp, tiền thưởng càng cao, lên đến tối đa $1,837.5! Thêm vào đó, thậm chí còn có thể có một vòng "Epic" với giải thưởng lớn hơn nữa!',
        },
        {
          question: 'Tham gia có mất phí gì không?',
          answer:
            'Chắc chắn là không! Tham gia Giải Vô Địch Wire Race hoàn toàn miễn phí. Chỉ cần đăng ký, hoàn thành nhiệm vụ và bắt đầu giới thiệu bạn bè. Điều duy nhất bạn cần đầu tư là sự nhiệt tình và tinh thần cạnh tranh!',
        },
        {
          question:
            'Tôi đã có một số Wire Points. Liệu tôi có bị mất chúng khi bảng xếp hạng được thiết lập lại không?',
          answer:
            'Không, bạn sẽ không bị mất bất kỳ Wire Points nào bạn đã kiếm được! Dưới đây là cách hoạt động:' +
            '<ul class="list-disc list-inside space-y-2 mt-4">' +
            ' <li><strong>Điểm từ các nhiệm vụ một lần:</strong> Điểm kiếm được từ các nhiệm vụ bạn chỉ có thể hoàn thành một lần (chẳng hạn như đăng ký tài khoản hoặc hoàn thành KYC) sẽ <strong>vĩnh viễn</strong> và sẽ <strong>góp phần vào xếp hạng của bạn trong mỗi vòng.</strong> Những điểm này sẽ không bị ảnh hưởng bởi việc thiết lập lại bảng xếp hạng.</li>' +
            ' <li><strong>Điểm từ các nhiệm vụ có thể lặp lại:</strong> Điểm kiếm được từ các nhiệm vụ bạn có thể thực hiện nhiều lần (chẳng hạn như mời bạn bè) chỉ là <strong>tạm thời</strong> và sẽ được <strong>thiết lập lại sau mỗi hai tuần</strong> trước khi bắt đầu vòng tiếp theo. Những điểm này không ảnh hưởng đến thứ hạng của bạn trên bảng xếp hạng, nhưng chúng sẽ <strong>tích lũy trong tổng số dư Wire Points của bạn.</strong></li>' +
            '</ul>',
        },
        {
          question: 'Ồ, vậy sau khi điểm được đặt lại, tôi vẫn có thể kiếm thêm điểm sao?',
          answer:
            'Hoàn toàn chính xác! Điểm từ các nhiệm vụ có thể lặp lại có thể được đặt lại trên bảng xếp hạng, nhưng chúng vẫn được cộng vào tổng số điểm Wire Point của bạn. Bạn có thể theo dõi những điểm này trong trang cung cấp và sử dụng chúng để đổi thưởng trong tương lai khi sản phẩm ra mắt.',
        },
        {
          question: 'Điểm xếp hạng của tôi ở các vòng khác nhau sẽ bị ảnh hưởng như thế nào?',
          answer:
            'Điểm từ các nhiệm vụ chỉ hoàn thành một lần (đăng ký, KYC, v.v.) sẽ giúp bạn có lợi thế ban đầu trong mỗi vòng mà không cần phải thực hiện lại nhiệm vụ đó. Tuy nhiên, thứ hạng của bạn chủ yếu phụ thuộc vào điểm kiếm được từ các nhiệm vụ có thể lặp lại trong vòng đó.' +
            '<br/><strong>Đây là một ví dụ đơn giản:</strong><br/>' +
            '<ul class="list-disc list-inside space-y-2 mt-4">' +
            ' <li><strong>Vòng 1:</strong> Bạn có 5 điểm từ việc đăng ký và KYC. Bạn kiếm được thêm 10 điểm từ việc mời bạn bè. Tổng điểm đóng góp cho xếp hạng của bạn là 15 (5 + 10).</li>' +
            ' <li><strong>Vòng 2:</strong> Bảng xếp hạng được đặt lại. Bạn vẫn giữ 5 điểm từ các nhiệm vụ một lần của vòng 1. Bạn kiếm được 15 điểm từ việc mời bạn bè ở Vòng 2. Tổng điểm đóng góp cho xếp hạng của bạn bây giờ là 20 (5 + 15).</li>' +
            '</ul><br/>' +
            '<strong>Chú ý:</strong> Mặc dù việc đặt lại bảng xếp hạng đảm bảo mọi người có cơ hội công bằng trong mỗi vòng, điểm từ các nhiệm vụ một lần sẽ mang lại cho bạn lợi thế lâu dài!',
        },
      ],
    },
    gap_cta: {
      excited_to: 'Cùng bùng nổ',
      bigger_prizes: 'giá trị giải thưởng?',
      description:
        'Cảm ơn bạn đã luôn đồng hành cùng chúng tôi! Để tri ân sự nhiệt tình của bạn, chúng tôi mang đến cơ hội cùng chia giải thưởng khủng gấp 1.5 lần',
      learn_more: 'Tìm hiểu thêm',
    },
    leader_board: {
      title: 'Bảng xếp hạng',
      description:
        'Bạn đã sẵn sàng "phá đảo" bảng xếp hạng? Vượt qua mọi đối thủ để tranh vị trí top 10 của vòng đấu, và rinh về phần thưởng khủng! Nào, chiến thôi!',
      note: 'Lưu ý: Vòng đua Wire race sẽ được "làm mới" sau mỗi 2 tuần để sẵn sàng cho cuộc đua hấp dẫn tiếp theo. Yên tâm nhé, mọi Wire Point bạn nỗ lực giành được trong mỗi vòng vẫn sẽ được bảo toàn nguyên vẹn!',
      position: 'Xếp hạng',
      user: 'Khách hàng',
      email: 'Email',
      hero_points: 'Tổng điểm',
    },
    task_board: {
      title: 'Nhiệm vụ',
      description:
        'Lên hạng một cách nhanh chóng khi thực hiện các nhiệm vụ bên dưới và nhận về thật nhiều điểm Wire',
      tasks_completed: 'Nhiệm vụ đã hoàn tất',
      tasks_progress: (completed: number, total: number) =>
        `<span class="font-extrabold text-iw-secondary text-[2rem] sm:text-5xl">${completed}</span><span class="font-bold text-iw-charcoal-black text-lg sm:text-[1.75rem]">/${total}</span>`,
      items: [
        {
          name: 'first_login',
          title: 'Đăng ký tài khoản',
          conditions: (isDoublePoint: boolean) => [
            'Tham gia danh sách chờ Instantwire, khởi động bằng cách tạo tài khoản của bạn chỉ với một click!',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } điểm Wire`,
          ],
        },
        {
          name: 'first_transaction',
          title: 'Tạo giao dịch đầu tiên',
          conditions: (isDoublePoint: boolean) => [
            'Với giao dịch đầu tiên đạt tối thiểu $20 sẽ giúp bạn thăng hạng trên Bảng Xếp Hạng Wire Race',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+5</b> <b class="text-iw-secondary">+10</b>'
                : '<b class="text-iw-secondary">+5</b>'
            } điểm Wire`,
          ],
        },
        {
          name: 'invite_friends',
          title: 'Mời bạn bè tham gia',
          conditions: (isDoublePoint: boolean) => [
            'Chia sẻ đường dẫn để mời bạn bè tham gia. Hãy đảm bảo việc đăng ký thành công để bạn được nhận thêm điểm Wire nhé',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } điểm Wire khi bạn bè đăng ký`,
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+2</b> <b class="text-iw-secondary">+4</b>'
                : '<b class="text-iw-secondary">+2</b>'
            } điểm Wire khi bạn bè chuyển tiền`,
          ],
        },
        {
          name: 'telegram_joined',
          title: 'Tham gia nhóm Telegram của Instantwire',
          conditions: (isDoublePoint: boolean) => [
            'Kết nối với chúng tôi trên Telegram! Bạn sẽ nhận được thông tin mới nhất, ưu đãi hấp dẫn và có cơ hội thảo luận cùng những người dùng khác.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } điểm Wire`,
          ],
        },
        {
          name: 'twitter_followed',
          title: 'Theo dõi kênh Twiter của Instantwire',
          conditions: (isDoublePoint: boolean) => [
            'Theo dõi ngay tài khoản Twitter chính thức của chúng tôi để cập nhật tin tức mới nhất và tận hưởng các ưu đãi hấp dẫn!',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+1</b> <b class="text-iw-secondary">+2</b>'
                : '<b class="text-iw-secondary">+1</b>'
            } điểm Wire`,
          ],
        },
        // {
        //   name: 'kyc_completed',
        //   title: 'Định danh tài khoản',
        //   conditions: (isDoublePoint: boolean) => [
        //     'Xác thực tài khoản bằng số điện thoại và CMND/CCCD',
        //     `${isDoublePoint ? '<b class="text-iw-rose-red line-through">+2</b> <b class="text-iw-secondary">+4</b>' : '<b class="text-iw-secondary">+2</b>'} điểm Wire`,
        //   ],
        // },
        {
          name: 'facebook_followed',
          title: 'Theo dõi kênh Facebook của Instantwire',
          conditions: (isDoublePoint: boolean) => [
            'Kết nối với chúng tôi trên Facebook! Bạn sẽ nhận được thông tin mới nhất, ưu đãi hấp dẫn và có cơ hội thảo luận cùng những người dùng khác.',
            `${
              isDoublePoint
                ? '<b class="text-iw-rose-red line-through">+3</b> <b class="text-iw-secondary">+6</b>'
                : '<b class="text-iw-secondary">+3</b>'
            } điểm Wire`,
          ],
        },
      ],
      completed: 'Đã hoàn tất',
      do_now: 'Thực hiện ngay',
    },
    referee: {
      title: 'Vòng đua "xôm tụ" do bạn, giải thưởng "khủng" do chúng tôi!',
      description:
        'Mỗi chiến hữu bạn "chiêu mộ" sẽ giúp bạn tiến gần hơn tới một mức giải thưởng cao hơn! Giới thiệu bạn bè cùng khám phá Instantwire, vừa góp phần xây dựng cộng đồng, vừa "bỏ túi" phần thưởng cực "khủng" dành riêng cho những nhà vô địch!',
      reward_value: 'Giá trị mỗi giải:',
      elite_league: {
        title: 'Elite League',
        current_prize_pool: '',
        amount: '$1.255',
        description:
          '100% người chơi chiến thắng sẽ nhận được mức giải thưởng cơ bản. Chiêu mộ thêm bạn bè cùng tham gia để tăng giá trị giải',
        rewards: [
          '<span class="text-iw-primary font-semibold">20 USD</span> cho mỗi người thuộc top 3',
          '<span class="text-iw-primary font-semibold">10 USD</span>  cho mỗi người thuộc top 20',
          '<span class="text-iw-primary font-semibold">5 USD</span> cho 20 người may mắn',
        ],
      },
      premier_league: {
        title: 'Premier League',
        current_prize_pool: 'Giá trị giải thưởng hiện tại',
        amount: '$1.506',
        description: 'Chiêu mộ thêm bạn bè cùng tham gia để tăng giá trị giải',
        rewards: [
          '<span class="text-iw-primary font-semibold">24 USD</span> cho mỗi người thuộc top 3',
          '<span class="text-iw-primary font-semibold">12 USD</span>  cho mỗi người thuộc top 20',
          '<span class="text-iw-primary font-semibold">6 USD</span> cho 20 người may mắn',
        ],
      },
      rising_stars: {
        title: 'Rising Stars Challenger League',
        current_prize_pool: '',
        amount: '$1.882',
        description: (users: number) =>
          `Cần thêm <span class="text-[#F3BA2F] font-semibold">${users.toLocaleString()}</span> người được giới thiệu tham gia để nhận <span class="text-iw-secondary font-semibold">150%</span> giá trị gia tăng giải thưởng.`,
        rewards: [
          '<span class="text-iw-primary font-semibold">30 USD</span> cho mỗi người thuộc top 3',
          '<span class="text-iw-primary font-semibold">15 USD</span>  cho mỗi người thuộc top 20',
          '<span class="text-iw-primary font-semibold">7.5 USD</span> cho 20 người may mắn',
        ],
      },
    },
    champion_roster: {
      title: 'Quán quân',
      description: 'Chúc mừng người chơi bên dưới đã nhận giải thưởng cao nhất từ chương trình',
      top_winners: 'Top 20 người chơi chiến thắng',
      top_lucky_users: '20 người chơi may mắn',
      unfinished_event: 'Sự kiện chưa kết thúc ...',
      round: 'Vòng',
      wire_point: (total: number) =>
        `<span class="text-iw-secondary font-semibold">${total.toLocaleString()}</span> điểm Wire`,
      referees: (total: number) =>
        `<span class="text-iw-secondary font-semibold">${total.toLocaleString()}</span> người giới thiệu`,
    },
    wire_point_benefits: {
      title: 'Lợi ích khi sở hữu điểm Wire',
      description:
        'Điểm Wire không chỉ đơn thuần là điểm thưởng, mà còn là lời tri ân sự tham gia của bạn để xây dựng một nền tảng cùng phát triển. Bắt đầu tích điểm ngay hôm nay để mở khóa nhiều ưu đãi có giá trị!',
      items: [
        {
          image: '/images/benefits/wire-race-benefits-vi-1.png',
          title: 'Đổi điểm Wire thành tiền mặt',
          description:
            'Đổi điểm Wire thành tiền mặt siêu dễ! Mỗi 25 điểm bạn tích lũy sẽ có giá trị thành 1 USD, sẵn sàng sử dụng sau khi sản phẩm ra mắt!',
        },
        {
          image: '/images/benefits/wire-race-benefits-vi-2.png',
          title: 'Chuyển tiền siêu tốc với tính năng ưu tiên!',
          description:
            'Dịch vụ chuyển tiền ưu tiên! Dùng điểm Wire để đổi lấy dịch vụ chuyển tiền siêu tốc dành cho khách hàng ưu tiên, giao dịch thực hiện chỉ trong ít phút!',
        },
        {
          image: '/images/benefits/wire-race-benefits-vi-3.png',
          title: 'Đầu tư điểm Wire hôm nay, thu hoạch Wire token ngày mai!',
          description:
            'Tích lũy Điểm Wire ngay từ bây giờ để tận hưởng lợi ích tiềm năng của Wire token trong tương lai và tham gia giao dịch trong hệ sinh thái Renec.',
        },
        {
          image: '/images/benefits/wire-race-benefits-vi-4.png',
          title: 'Ưu đãi VIP',
          description:
            'Đổi điểm Wire thành quyền lợi "xịn" với vô vàn ưu đãi độc quyền trên hệ sinh thái Renec. Từ điểm thưởng thành đặc quyền, chỉ trong tầm tay!',
        },
      ],
    },
  },
  instantwire_transaction: {
    referral_benefit: {
      refer_friend: 'Giới thiệu bạn bè, nhận thêm ưu đãi',
      description:
        'Giới thiệu bạn bè tạo lệnh chuyển tiền để cả hai đều nhận ưu đãi. Không giới hạn số lượt chia sẻ và ưu đãi nhận được.',
      you_get: 'Bạn nhận',
      free_wire: 'Nhận ngẫu nhiên <b>1 - 5</b> điểm Wire.',
      friend_get: 'Bạn bè nhận',
      kyc_success: '<b>3</b> điểm Wire khi định danh tài khoản thành công',
      create_transaction: '<b>5</b> điểm Wire khi tạo lệnh chuyển tiền',
      share_link: 'Link chia sẻ',
      code: 'Mã giới thiệu',
      you_received: 'Bạn đã nhận',
      wire_point: 'Điểm Wire',
      total_accumulated: 'Số tiền hoàn dự kiến',
      referral_information: 'Thông tin giới thiệu',
      successful_referral: 'Giới thiệu thành công',
      transfer_created: 'Tạo lệnh chuyển tiền thành công',
      success_referree_amount: (amount: number) => `${amount} bạn bè`,
      success_transaction_amount: (amount: number) => `${amount} lệnh`,
      login_to_see_link: 'Đăng nhập để xem link',
      login_to_see_code: 'Đăng nhập để xem mã',
      check_reward: 'Kiểm tra ưu đãi của bạn.',
      wire_point_accumulate:
        'Điểm Wire là điểm được tích lũy từ các giao dịch chuyển tiền hoặc giới thiệu bạn bè cùng tham gia giao dịch chuyển tiền tại Instantwire.',
      wire_point_convert:
        'Điểm Wire có thể được quy đổi giá trị ra đồng tiền cơ sở của bạn và rút trực tiếp về tài khoản ngân hàng hoặc dùng cho các giao dịch chuyển tiền sau này.',
      wire_point_valid:
        'Lưu ý rằng, tổng số điểm Wire bạn tích lũy chỉ có giá trị sử dụng sau khi bạn hoàn tất xác minh danh tính (KYC) khi ứng dụng Instantwire chính thức ra mắt. Thông tin chi tiết khác về điểm Wire cũng sẽ được công bố tại thời điểm này.',
      understood: 'Đã hiểu',
      refund_info:
        'Bạn sẽ được hoàn tiền sau khi tuyến dưới của bạn thực hiện giao dịch thành công.',
      share_referral_code: 'Chia sẻ mã giới thiệu',
      or_share_via: 'Hoặc chia sẻ qua',
      referrer_benefit_info: () =>
        "Nhận ngay <b><span style='color:#00BD90'>2 USD</span></b> thưởng cho mỗi lượt giới thiệu bạn bè xác minh danh tính thành công. Không giới hạn số lượt giới thiệu.",
    },
    referral_progress: {
      refer_friend: 'Quy trình giới thiệu bạn bè',
      description: ' Chỉ cần 3 bước đơn giản để bạn và bạn bè đều nhận ưu đãi',
      share_link: '1. Chia sẻ link giới thiệu',
      sign_up:
        'Đăng nhập hoặc đăng ký email, sau đó mời bạn bè bằng cách chia sẻ liên kết giới thiệu cá nhân của bạn',
      invite_friend: '2. Mời bạn bè tạo lệnh chuyển tiền',
      use_the_link:
        'Yêu cầu bạn bè sử dụng liên kết bạn đã chia sẻ, tiến hành đăng ký email và tạo lệnh chuyển tiền.',
      earn: '3. Nhận thưởng',
      receive:
        "Nhận ưu đãi ngẫu nhiên <b><span style='color:#00BD90'>1-5</span></b> điểm <b>Wire</b> cho bạn và từng người bạn giới thiệu. Đồng thời, nhận <span style='color:#00BD90'><b>0,1% - 0,3%</b></span> hoàn tiền từ giao dịch chuyển tiền của tuyến dưới, rút tiền khi đạt 230,000 VND.",
    },
    draft_transaction: {
      empty_input_amount: 'Vui lòng nhập số lượng bạn muốn gửi.',
      unavailable_pair: (currency_in: string, currency_out: string) =>
        `Chuyển tiền từ ${currency_in} sang ${currency_out} chưa được hỗ trợ`,
      created_successfully:
        'Chúc mừng! Giao dịch của bạn đã được khởi tạo thành công với ưu đãi "<b>0 phí giao dịch</b>"',
      lowest_input_amount: (limit: number, currency: string) =>
        `Số tiền tối thiểu có thể gửi là ${limit.toLocaleString()} ${currency}.`,
      exceed_input_amount: (limit: number, currency: string) =>
        `Số tiền tối đa có thể gửi là ${limit.toLocaleString()} ${currency}.`,
      explore_deal: 'Khám phá ưu đãi dành riêng cho người dùng truy cập sớm',
      detail: 'Chi tiết lệnh gửi',
      creation_date: 'Ngày tạo lệnh',
      send_from: 'Gửi từ',
      send_amount: 'Bạn gửi',
      fee: 'Phí',
      bond_fee: 'Phí đảm bảo',
      trade_fee: 'Phí giao dịch',
      receive_amount: 'Người nhận sẽ nhận',
      rate: 'Tỷ giá',
      status: 'Trạng thái',
      keep_the_deal: 'Bảo toàn ưu đãi',
      notice1: 'Hệ thống chúng tôi đã lưu giữ thông tin giao dịch và ưu đãi của bạn.',
      notice2:
        'Bạn có thể điều chỉnh thông tin và tiếp tục giao dịch khi Instantwire chính thức ra mắt.',
      view_detail: 'Xem chi tiết',
      sign_in_to_secure: 'Đăng nhập ngay để bảo toàn ưu đãi!',
    },
    statuses: {
      unsaved: 'Chưa thực hiện',
      draft: 'Bảo toàn ưu đãi',
    },
    survey: {
      intro:
        'Hãy giúp Instantwire hiểu rõ hơn về nhu cầu chuyển tiền quốc tế của bạn và hỗ trợ chúng tôi cải thiện phiên bản chính thức của Instantwire bằng cách tham gia khảo sát ngắn này. <br>Chỉ mất 3 phút để hoàn tất.',
      join_survey: 'Tham gia khảo sát',
      completed: 'Đã hoàn thành khảo sát',
      no_of_joined_users: (amount: string) => `Đã có <b>${amount}</b> người dùng tham gia`,
    },
    referral_benefit_double: {
      refer_friend: 'Giới thiệu bạn bè, nhân đôi ưu đãi',
      description:
        'Giới thiệu bạn bè tạo lệnh chuyển tiền để cả hai đều nhận ưu đãi. Không giới hạn số lượt chia sẻ và ưu đãi nhận được.',
      referee_create_acc:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>1</span> <span style='color:#00BD90'><b>2</b></span> điểm Wire khi bạn bè tạo tài khoản",
      draft_trx_bonus:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> điểm Wire khi bạn bè tạo giao dịch chuyển tiền nháp",
      refund:
        "&#x2022;&nbsp;&nbsp;<span style='color:#00BD90'><b>0,1%</b></span> hoàn tiền trên giao dịch chuyển tiền của người nhận",
      create_acc:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>1</span> <span style='color:#00BD90'><b>2</b></span> điểm Wire khi tạo tài khoản thành công",
      create_transaction:
        "&#x2022;&nbsp;&nbsp;<span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> điểm Wire khi tạo lệnh chuyển tiền",
    },
  },
  login_now: 'Đăng nhập ngay!',
  count_down: {
    end_at: 'Kết thúc vào',
    days: 'Ngày',
    hours: 'Giờ',
    minutes: 'Phút',
    seconds: 'Giây',
  },
  terms_and_conditions: 'Điều khoản sử dụng',
  recipients: {
    list_title: 'Người nhận',
    add_form_title: 'Thêm thông tin người nhận',
    update_form_title: 'Cập nhật thông tin người nhận',
    select_form_title: 'Chọn thông tin người nhận',
    select_country: 'Chọn quốc gia',
    select_bank: 'Chọn ngân hàng',
    country: 'Quốc gia',
    bank_name: 'Ngân hàng',
    bank_account_number: 'Số tài khoản ngân hàng',
    bank_account_name: 'Tên chủ tài khoản',
    bank_verification_number: 'Số Xác Thực Ngân Hàng',
    email: 'Email',
    phone_number: 'Số điện thoại',
    new_recipients: 'Thêm người nhận',
    delete_warning: 'Xoá người nhận',
    delete_warning_msg: 'Bạn có thực sự muốn xoá dữ liệu này? Thao tác này không thể hoàn tác',
    save: 'Lưu thông tin',
    added_recipient_success: 'Thêm thông tin người nhận thành công',
    update_recipient_success: 'Cập nhật thông tin người nhận thành công',
    list: {
      account_name: 'Tên tài khoản',
      account_number: 'Số tài khoản',
      action: 'Hành động',
    },
    bonus_first_trans: (amount: number) =>
      `Nhận ngay <b>${amount} wirepoint</b> cho lần đầu giao dịch`,
    bonus_first_trans_received: (amount: number) => `Bạn đã nhận <b>${amount} wirepoint</b>`,
    no_recipients_yet: 'Không có thông tin!',
  },
  recipient: 'Người nhận',
  form_error: {
    required: 'Trường này là bắt buộc',
    invalid_email: 'Địa chỉ email không hợp lệ',
    invalid_phone_number: 'Số điện thoại không hợp lệ',
    submit_error: 'Có lỗi xảy ra, xin hãy thử lại',
    bank_account_name_fetch_error: 'Không thể lấy thông tin tài khoản ngân hàng',
  },
  action: {
    delete: 'Xoá',
    edit: 'Sửa',
    detail: 'Chi tiết',
    cancel: 'Huỷ',
  },
  account: {
    kyc: 'Xác minh danh tính',
    balances: 'Số dư',
    recipients: 'Danh sách người nhận',
    transaction_history: 'Lịch sử giao dịch',
    exclusive_deals: 'Ưu đãi độc quyền',
    wire_race: 'Wire Race',
    support: 'Trung tâm hỗ trợ',
    transfer: 'Chuyển tiền',
  },
  balances: {
    title: 'Số dư',
    your_balances: 'Số dư của bạn',
    history: 'Lịch sử',
    time: 'Thời gian',
    asset: 'Tài sản',
    amount: 'Số lượng',
    description: 'Chi tiết',
    status: 'Trạng thái',
    total_amount: 'Tổng số lượng',
    usd_value: 'Giá trị USD',
    action: 'Thao tác',
    deposit: 'Nạp',
    withdraw: 'Rút',
  },
  kyc: {
    information: 'Thông tin KYC',
    status: 'KYC status',
    level1: 'KYC cấp 1',
    level2: 'KYC cấp 2',
    level: 'Cấp',
    complete_level: (level: string) => `Hoàn thành KYC cấp ${level} để nhận được quyền lợi:`,
    bonus_kyc: (amount: number) => `Nhận <b>${amount} wirepoints</b> khi KYC thành công`,
    fiat_deposit: 'Nạp tiền:',
    trading: 'Giao dịch:',
    withdrawal: 'Rút tiền:',
    more_event_bonuses: 'Thưởng sự kiện nhiều hơn',
    verify_now: 'Xác thực ngay',
    daily: 'Mỗi ngày',
    requirements: 'Yêu cầu',
    country: 'Quốc gia',
    phone_number: 'Số điện thoại',
    selfie: 'Hình chính diện',
    review_duration: 'Thời gian xét duyệt (ước tính 10 phút)',
    statuses: {
      verified: 'Đã xác minh',
      not_verified: 'Chưa xác minh',
      approved: 'Đã xác minh',
      rejected: 'Bị từ chối',
      pending: 'Đang xác minh',
    },
    benefit_1_lv1: 'Bảo mật tài khoản của bạn bằng cách xác minh <b>số điện thoại.</b>',
    benefit_2_lv1:
      'Tận hưởng giao dịch nhanh chóng hơn và tăng cường lớp bảo mật giúp chúng tôi tạo ra một nền tảng an toàn và tin cậy cho mọi giao dịch của bạn',
    benefit_1_lv2: 'Bảo mật tài khoản của bạn bằng cách xác minh <b>danh tính.</b>',
    benefit_2_lv2:
      'Mở khóa các tính năng cao cấp, tận hưởng hạn mức giao dịch cao hơn và tăng cường lớp bảo mật giúp chúng tôi tạo ra một nền tảng an toàn và tin cậy cho mọi giao dịch của bạn.',
    kyc_approved_title: 'Xin chúc mừng! Bạn đã KYC thành công',
    kyc_approved_description: (amount: number) =>
      `Bạn đã nhận được <span class="text-iw-secondary font-semibold">$${amount}</span> tiền thưởng. Số tiền thưởng này sẽ được sử dụng khi <span class="font-semibold text-iw-text-primary">Instantwire chính thức ra mắt vào cuối tháng 11/2024</span>. Hãy tiếp tục theo dõi và sẵn sàng trải nghiệm các dịch vụ của chúng tôi!`,
  },
  transaction_input: {
    continue: 'Tiếp tục',
    cancel: 'Hủy',
    fee: 'Phí',
    total_to_pay: 'Bạn cần thanh toán',
    start: 'Bắt đầu',
  },
  transfer_review: {
    review_request: 'Kiểm tra yêu cầu',
    make_sure_correct:
      'Hãy đảm bảo thông tin số tiền và thông tin người nhận của bạn là chính xác trước khi tiếp tục',
    money_info: 'Thông tin chuyển tiền',
    total_payout: 'Bạn cần thanh toán',
    rate: 'Tỷ giá',
    fee: 'Phí',
    converted_amount: 'Số tiền người nhận sẽ nhận',
    from: 'Chiều chuyển tiền',
    estimate: 'Ước tính thời gian nhận',
    recipient_info: 'Thông tin người nhận',
    recipient: 'Người nhận',
    bank_account: 'Số tài khoản',
    bank_name: 'Ngân hàng',
    email: 'Email',
    phone: 'Số điện thoại',
    edit_recipient: 'Sửa thông tin người nhận',
    next: 'Tiếp tục',
  },
  transfer_connecting: {
    connecting: 'Đang kết nối',
    finding:
      'Chúng tôi đang tìm kiếm những người dùng khác đang có nhu cầu chuyển tiền ở chiều ngược lại để kết nối với yêu cầu chuyển tiền của bạn',
    cancel: 'Hủy',
  },
  new_recipients: {
    your_recipient: 'Bạn chuyển tiền đến ai?',
    add_new: 'Thêm người nhận mới',
    bank_transfer_recipient: 'Danh sách người nhận',
    infor: 'Thêm thông tin người nhận',
    update: 'Cập nhật thông tin người nhận',
    continue: 'Tiếp tục',
    no_bank_transfer_recipient: 'Bạn chưa có thông tin người nhận nào',
  },
  transaction_progress: {
    steps: {
      creating_order: 'TẠO YÊU CẦU',
      paying: 'BẠN THANH TOÁN',
      transfering: 'CHUYỂN TIỀN',
      completed: 'HOÀN TẤT',
    },
    step_x_of_y: (x: number, y: number) => `Bước ${x} trên ${y}`,
    payment: 'Lệnh',
  },
  transaction_complaint_progress: {
    steps: {
      appeal_submitted: 'Đã gửi khiếu nại',
      handling: 'Trọng tài xử lý',
      result: 'Kết quả',
    },
    being_process: 'Khiếu nại đang được trọng tài xử lý',
    reason:
      "Lý do: Người nhận báo cáo <span class='text-rose-500 font-bold font-inter'>Chưa nhận đủ tiền<span>",
    request_id: (transactionId: string) => `Mã giao dịch: <b>#${transactionId}</b>`,
    cancel_appeal: 'Huỷ khiếu nại',
    home: 'Trang chủ',
    completed: 'Hoàn tất!',
    are_you_certain: 'Bạn có chắc chắn?',
    certain_description:
      'Huỷ khiếu nại không phải là huỷ giao dịch chuyển tiền. Bạn vẫn tiếp tục trong giao dịch đó. Nếu vẫn gặp sự cố, bạn có thể gửi khiếu nại khác.',
    received: (amount: string) => `Tôi đã nhận số tiền <b>${amount}</b>.`,
    not_now: 'Không phải bây giờ',
  },
  kyc_double: {
    verify_account: 'Xác thực tài khoản',
    bonus_kyc:
      "Nhận ngay <span style='text-decoration:line-through;color:#EB5757'>2</span> <span style='color:#00BD90'><b>4</b></span> Wire Points khi xác thực tài khoản thành công",
    verify_now: 'Thực hiện ngay',
  },
  banner_double: {
    flash_sale: '24H vàng:',
    double_when_success:
      'Nhân Đôi Điểm Wire Khi Tạo Lệnh Chuyển Tiền hoặc Giới thiệu bạn bè thành công!',
    learn_more: 'Tìm hiểu thêm',
  },
  referral_process_double: {
    receive:
      "Nhận ưu đãi <span style='text-decoration:line-through;color:#EB5757'>1-3</span> <b><span style='color:#00BD90'>2-6</span></b> điểm Wire cho bạn khi bạn bè tạo giao dịch thành công. Đồng thời, nhận ngẫu nhiên 0,1% hoàn tiền trên giao dịch chuyển tiền của người nhận.",
  },
  hero_double: {
    end_at: 'Hết hạn sau',
    double_point: '<b>Nhân đôi điểm Wire</b> khi tạo lệnh chuyển tiền. Hoàn toàn miễn phí!',
  },
  transfer_payment: {
    request_id: 'Mã giao dịch',
    wait_for_payment: 'Chờ bạn thanh toán',
    instruction:
      'Vui lòng thanh toán cho tài khoản thụ hưởng được chỉ định bên dưới. Bạn cần <strong>chụp ảnh màn hình</strong> kết quả chuyển khoản để chứng minh sau khi hoàn tất.',
    payment_x_of_y: (x: number, y: number) =>
      `Lệnh <span class="text-iw-secondary text-lg sm:text-[1.75rem]">${x}</span>/${y}`,
    payment_x_of_y_normal: (x: number, y: number) => `Lệnh ${x}/${y}`,
    choose_a_payment_channel: 'Chọn kênh thanh toán',
    payment_via_banks: 'Thanh toán qua ngân hàng',
    payment_via_others: 'Thanh toán qua Venmo, Zelle',
    or_click_others: 'Bấm',
    copy_transfer_info: 'để sao chép thông tin chuyển khoản',
    bank_account_name: 'Người thụ hưởng:',
    bank_name: 'Tên ngân hàng:',
    bank_acc_number: 'Số tài khoản:',
    bank_routing_number: 'Số định tuyến ngân hàng:',
    bank_address: 'Địa chỉ ngân hàng:',
    transfer_note: 'Nội dung:',
    amount: 'Số tiền:',
    copied: 'Đã sao chép',
    cancel: 'Huỷ',
    complete: 'Tôi đã chuyển tiền',
    transfering: 'Hệ thống đang chuyển tiền',
    transfering_subtitle: 'Số tiền đang được chuyển đến người nhận. Hoàn toàn miễn phí!',
    transferred: 'Đã chuyển tiền',
    transferred_subtitle:
      'Vui lòng liên hệ người nhận để kiểm tra. Lưu ý, thời gian nhận tuỳ vào tốc độ xử lý của ngân hàng.',
    received: 'Người nhận đã nhận tiền',
    confirm: 'Bạn muốn xác nhận?',
    upload_evidence: 'Tải lên bằng chứng<br />chuyển khoản',
    upload_evidence_instruction: 'Gần hoàn tất! Chỉ 1 bước nữa, tiền sẽ đến tay người nhận.',
    payment_method: 'Phương thức thanh toán',
    phone_number: 'Số điện thoại:',
    email: 'Email:',
    sent_at: 'Thời gian',
    expire_in: 'Hết hạn sau',
    download: 'Tải xuống',
    need_help: 'Liên hệ chăm sóc khách hàng',
    banks: 'Ngân hàng',
    order_status: {
      payment_not_yet_made: 'Chưa chuyển tiền',
      being_verified: 'Đang kiểm tra',
      being_verified_description:
        'Chúng tôi đang kiểm tra khoản thanh toán của bạn. Vui lòng chờ trong giây lát!',
      under_dispute: 'Đang tranh chấp',
      under_dispute_description:
        'Trọng tài của chúng tôi đang liên hệ để xác minh khoản thanh toán của bạn.',
      payment_is_received: 'Đã chuyển tiền',
      canceled: 'Đã hủy',
    },
    changed_your_mind: 'Bạn thay đổi quyết định!',
    cancel_the_transaction: 'Hủy giao dịch',
  },
  payment_evidence: 'Bằng chứng chuyển khoản',
  contact_support: 'Liên hệ hỗ trợ',
  payment_confirm: {
    title: 'Đang kiểm tra...',
    subtitle: 'Chúng tôi đang kiểm tra khoản thanh toán của bạn. Vui lòng chờ trong giây lát!',
    verified: 'Đã xác thực',
  },
  upload: {
    file_max_size: (max_size: string) => `* Kích thước file tải lên tối đa: ${max_size}`,
    upload_btn: 'Tải lên tập tin',
    uploading_title: 'Đang tải lên ...',
    uploading_msg:
      'Bằng chứng chuyển khoản của bạn đang được tải lên. Vui lòng chờ trong giây lát.',
    uploaded_title: 'Tải lên hoàn tất',
    uploaded_msg:
      'Bằng chứng chuyển khoản đã được tải lên hoàn tất. Hệ thống đang tiến hành xác thực.',
    cancel: 'Huỷ',
    size_exceed_limit: 'Vượt quá dung lượng cho phép',
    zoom_in: 'Phóng to',
    continue_tracking: 'Theo dõi lệnh chuyển tiền',
  },
  currency_exchange: {
    title: 'Chuyển đổi ngoại tệ',
    exchanging:
      'Hệ thống đang chuyển đổi từ đồng nội tệ của bạn sang đồng ngoại tệ của quốc gia người nhận theo tỷ giá trên thị trường',
    estimate: 'Thời gian ước tính còn lại',
    hour: 'tiếng',
    info: 'Thông tin',
  },
  trx_info: {
    more: 'Thông tin thêm',
    less: 'Ẩn bớt thông tin',
    receive_amount: 'Số tiền sẽ nhận',
    received_amount: 'Số tiền đã nhận',
    you_send: 'Bạn gửi',
    fee: 'Phí',
    rate: 'Tỉ giá',
    you_pay: 'Bạn thanh toán',
    perform_time: 'Thời gian thực hiện',
    direction: 'Chiều chuyển tiền',
    bank_acc_owner: 'Chủ tài khoản nhận',
    amount: 'Số tiền',
    bank_acc_number: 'Số tài khoản ngân hàng/IBAN',
    bank_name: 'Tên ngân hàng',
    transfer_note: 'Nội dung chuyển khoản/ Tham chiếu',
  },
  transaction_complete: {
    title: 'Hoàn tất!',
    subtitle: 'Người nhận đã nhận tiền thành công.',
    go_homepage: 'Về trang chủ',
  },
  trx_cancel: {
    confirm_title: 'Bạn đã chắc chắn?',
    confirm_msg_1:
      'Nếu bạn đã thanh toán, nhớ nhấn nút "<b style=\'color: #00BD90\'>Tôi đã chuyển tiền</b>"',
    confirm_msg_2:
      'Nếu bạn đã thanh toán cho người nhận được chỉ định, vui lòng không hủy giao dịch.',
    confirm_checkbox: 'Tôi xác nhận chưa thanh toán',
    btn: 'Huỷ giao dịch',
    back: 'Không phải bây giờ',
    confirm_description: [
      'Nếu bạn đã thanh toán, nhớ nhấn nút "<span style="color: #00BD90">Tôi đã chuyển tiền</span>"',
      'Nếu bạn đã thanh toán cho người nhận được chỉ định, vui lòng không huỷ giao dịch.',
    ],
    reason_title: 'Lý do huỷ lệnh',
    reason_list: [
      { value: 'no_longer_want_to_trade', label: 'Tôi không muốn giao dịch nữa' },
      {
        value: 'payment_platform_issue',
        label: 'Nền tảng thanh toán xảy ra lỗi kỹ thuật hoặc lỗi mạng',
      },
      {
        value: 'payment_method_issue',
        label:
          'Có sự cố với phương thức thanh toán của người được chỉ định dẫn đến không thanh toán được',
      },
      { value: 'other', label: 'Lý do khác' },
    ],
    reason_placeholder: 'Vui lòng nhập lý do',
    cancel_transaction_successful: 'Giao dịch đã được huỷ thành công',
  },
  continue: 'Tiếp tục',
  cancel: 'Huỷ',
  order_dispute: {
    title: 'Cần thêm thời gian xử lý',
    subtitle: 'Trọng tài của chúng tôi đang liên hệ để xác minh khoản thanh toán của bạn.',
  },
  transaction_history: {
    title: 'Lịch sử giao dịch',
    status: {
      in_progress: 'Đang thực hiện',
      all: 'Tất cả',
      completed: 'Hoàn tất',
      canceled: 'Bị hủy',
    },
    received: 'Người nhận đã nhận tiền',
    in_progress: 'Đang thực hiện',
    success: 'Hoàn tất',
    cancel: 'Đã hủy',
  },
  transaction_onramp_failed: {
    failed: 'Thất bại',
    try_again:
      'Rất tiếc, chúng tôi chưa tìm được yêu cầu chuyển tiền của người dùng khác ở chiều ngược lại để thực hiện ghép nối. Vui lòng thử lại với một số tiền khác nhỏ hơn!',
    how_it_works: 'Xem cách chúng tôi hoạt động',
    new_transfer: 'Giao dịch mới',
  },
  safe_transactions: {
    title: 'Safe transactions',
    items: [
      {
        title: 'Xác minh danh tính',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> đảm bảo mỗi khách hàng khi giao dịch đều:',
        items: ['Xác minh khuôn mặt', 'Xác minh thông tin lý lịch'],
        sub_description: 'nhằm cung cấp tính minh bạch và tin cậy của nền tảng.',
      },
      {
        title: 'Quản lý rủi ro nghiêm ngặt',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> giới hạn số tiền giao dịch theo thông tin định danh, nhiều điểm yêu cầu xác minh khuôn mặt và kiểm tra chống rửa tiền để đảm bảo tiền của bạn được an toàn.',
      },
      {
        title: 'Hỗ trợ khách hàng 24/7',
        description:
          '<span class="font-semibold text-iw-primary">Instantwire</span> cung cấp dịch vụ khách hàng trực tuyến 24/7 để đảm bảo các vấn đề của bạn được giải quyết 1 cách nhanh chóng và dễ dàng.',
      },
    ],
  },
  ongoing_transaction: {
    title: 'Có 1 giao dịch đang thực hiện.',
    msg: (id: string | number) =>
      `Bạn đang có giao dịch <b>#${id}</b> chưa hoàn tất. Vui lòng kiểm tra và hoàn tất trước khi tạo giao dịch mới`,
    check_transaction: 'Kiểm tra giao dịch',
    close: 'Đóng',
  },
  discount: {
    msg: (points: number) => `Giảm giá  bằng ${points} điểm Wire`,
    tooltip: [
      {
        VND: 'Sử dụng tối đa 100 wire points <span class="text-iw-saffron-yellow">(~5 USD)</span> cho giá trị giao dịch từ 500 nghìn VND đến 10 triệu VND',
        USD: 'Sử dụng tối đa 100 wire points <span class="text-iw-saffron-yellow">(~5 USD)</span> cho giá trị giao dịch từ 20$ đến 500$',
      },
      {
        VND: 'Sử dụng tối đa 200 wire points <span class="text-iw-saffron-yellow">(~10 USD)</span> cho giá trị giao dịch từ 10 triệu VND đến 20 triệu VND',
        USD: 'Sử dụng tối đa 200 wire points <span class="text-iw-saffron-yellow">(~10 USD)</span> cho giá trị giao dịch từ 500$ đến 1000$',
      },
      {
        VND: 'Không giới hạn số wire point được sử dụng để giảm giá cho giá trị giao dịch trên 20 triệu VND',
        USD: 'Không giới hạn số wire point được sử dụng để giảm giá cho giá trị giao dịch trên 1000$',
      },
    ],
    field: 'Giảm giá bằng điểm Wire',
    discount_amount: 'Giảm giá',
  },
  transfer_received_verify: {
    title: 'Bạn muốn xác nhận?',
    subtitle: (recipient: string) =>
      `Hãy chắc chắn <b>${recipient}</b> đã nhận tiền thành công trước khi bấm xác nhận để tránh rủi ro mất tiền.`,
    not_received: 'Người nhận chưa nhận tiền.',
    not_received_sufficient: 'Người nhận nhận thiếu tiền.',
    received: (accountName: string, amount: string, currency: string) =>
      `<b>${accountName}</b> đã nhận đủ và chính xác số tiền <b>${amount}</b> ${currency}.`,
    confirm: 'Tôi chắc chắn',
    verify_again: 'Không, tôi cần kiểm tra lại',
    dispute_title: 'Chúng tôi rất tiếc vì vấn đề bạn gặp phải!',
    dispute_subtitle: 'Biên lai chứng minh tiền đã được chuyển đi bạn có thể xem',
    copy_and_dispute: 'Sao chép mã giao dịch & khiếu nại',
    check_again: 'Tôi cần kiểm tra và sẽ thông báo sau',
    received_lt_50: 'Nhận thiếu <b>dưới 50%</b> giá trị khoản tiền',
    received_gt_50: 'Nhận thiếu <b>trên 50%</b> giá trị khoản tiền',
    received_insignificant_amount: 'Nhận <b>không đáng kể</b>(dưới 3%)',
  },
  transfer_contact_reason: {
    title: 'Lý do liên hệ',
    reason: 'Lý do',
    not_received: 'Người nhận chưa nhận tiền',
    not_received_sufficient: 'Người nhận nhận thiếu tiền',
    upload_evidence_instruction:
      'Tải ảnh sao kê chứng minh người nhận không có giao dịch nhận tiền nào chính xác với yêu cầu chuyển khoản của bạn',
    upload_btn: 'Tải lên bằng chứng',
    confirm: 'Xác nhận',
    cancel: 'Quay lại sau',
  },
  here: 'Tại đây',
  search: 'Tìm kiếm',
  referee_list: {
    list: 'Danh sách giới thiệu',
    user: 'Người dùng',
    email: 'Email',
    status: 'Trạng thái',
    transferred: 'Đã giao dịch +$7',
    approved: 'Đã KYC +$2',
    invited: 'Đã mời',
    people: 'người dùng',
    close: 'Đóng',
  },
  coming_soon: {
    title: 'Tính năng chuyển tiền của Instantwire sẽ sớm ra mắt!',
    description:
      'Chúng tôi đang làm việc chăm chỉ để đảm bảo tính năng chuyển tiền nhanh chóng và an toàn của Instantwire sẽ chính thức hoạt động trong thời gian tới. Hãy theo dõi chúng tôi để cập nhật ngày ra mắt và sẵn sàng trải nghiệm dịch vụ ưu việt cùng nhiều ưu đãi hấp dẫn!',
    follow: 'Theo dõi chúng tôi qua kênh chính thức',
  },
}

export default locale
