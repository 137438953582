import {
  useContext,
  useEffect,
  useState,
} from 'react'

import { ActionCableContext } from '@/contexts/action-cable'
import { Subscription } from '@rails/actioncable'

interface IUserAuthorizeWebsocket<T> {
  channel: string
  params: object
  initialData?: any
  enabled: boolean
  transformResponse?: (data: any) => T
  callback?: (data: T) => void
}

const defaultTransformResponse = (data: any) => data

export const useAuthorizeWebsocket = <T>(props: IUserAuthorizeWebsocket<T>) => {
  const {
    channel,
    params,
    initialData,
    enabled,
    callback,
    transformResponse = defaultTransformResponse,
  } = props
  const [data, setData] = useState<T>()
  const [receivedData, setReceivedData] = useState<any>()
  const { consumer } = useContext(ActionCableContext)
  const [subscription, setSubscription] = useState<Subscription>()

  useEffect(() => {
    if (data) return

    setData(initialData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  useEffect(() => {
    if (!consumer || !enabled || subscription) return

    const sub = consumer.subscriptions.create({
      channel: channel,
      ...params,
    }, {
      received(receivedData: any) {
        setReceivedData((prevReceivedData: any) => {
          // take the later data base on timestamp
          if (!prevReceivedData || prevReceivedData['message_sent_at'] < receivedData['message_sent_at'])
            return { ...prevReceivedData, ...receivedData }
          else
            return { ...receivedData, ...prevReceivedData }
        })
      },
    })
    setSubscription(sub)

    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumer, enabled])

  useEffect(() => {
    if (!receivedData) return

    setData(transformResponse(receivedData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedData])

  useEffect(() => {
    if (callback && data) {
      callback(data)
    }
  }, [callback, data])

  return { data }
}
