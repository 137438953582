import { ReactElement, createContext, useState } from 'react'
import { IconButton, Snackbar, Stack, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NotiIcon from '@/assets/noti-icon.svg'
import Image from 'next/image'

type NotificationContextValue = {
  addNotification: (text: string) => void
}

const initContextState: NotificationContextValue = {
  addNotification: () => undefined,
}

export const NotificationContext = createContext<NotificationContextValue>(initContextState)

const NotificationProvider = ({ children }: HocProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const theme = useTheme()

  const handleOpen = (text: string) => {
    setOpen(true)
    setContent(text)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const value = {
    addNotification: handleOpen,
  }

  return (
    <NotificationContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{ className: 'bg-iw-mint-cream rounded-lg' }}
        message={
          <Stack direction="row" alignItems="center" spacing={2}>
            <Image src={NotiIcon} alt="noti" />
            <Typography color={theme.palette.grey[600]}>{content}</Typography>
          </Stack>
        }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
