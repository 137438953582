import React, { FC, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import { TransitionProps } from '@mui/material/transitions'

import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type CustomDialogProps = {
  title?: string
  body: ReactNode
  isOpened?: boolean
  handleClose: () => void
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  'data-testid'?: string
  className?: string
  transparentBackground?: boolean
  paperStyle?: any
  bodyDivider?: boolean
}

const CustomDialog: FC<CustomDialogProps> = ({
  title,
  body,
  isOpened,
  handleClose,
  transparentBackground,
  paperStyle,
  maxWidth = 'xs',
  bodyDivider = true,
  ...dialogProps
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      {...dialogProps}
      aria-labelledby="instantwire-dialog-title"
      open={!!isOpened}
      onClose={handleClose}
      keepMounted
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      sx={{
        '& .MuiPaper-root': {
          position: { xs: 'fixed', sm: 'static' },
          bottom: { xs: 0, sm: 'auto' },
          left: { xs: 0, sm: 'auto' },
          right: { xs: 0, sm: 'auto' },
          borderRadius: {
            xs: '16px 16px 0 0',
            sm: '16px',
          },
          backgroundColor: transparentBackground ? 'rgba(0, 0, 0, 0.19)' : '#FFFFFF',
          boxShadow: transparentBackground ? 'none' : undefined,
          height: 'auto',
          overflow: 'hidden',
          ...paperStyle,
        },
      }}
    >
      {title && (
        <>
          <DialogTitle
            sx={{ m: 0, p: 1.5 }}
            id="instantwire-dialog-title"
            className="bg-[#F0F2F3] text-base"
          >
            {title}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose} className="absolute top-1 right-1">
            <CloseIcon />
          </IconButton>
        </>
      )}
      <DialogContent className="p-0" dividers={bodyDivider}>
        {body}
      </DialogContent>
    </Dialog>
  )
}

export default CustomDialog
