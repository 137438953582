import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
} from 'react'

import { DEFAULT_LOCALE } from '@/constants/index'
import useLang from '@/hooks/useLang'

interface LanguageContextValues {
  handleChangeLanguage: (lang: string) => void;
  lang: string
}

export const LanguageContext = createContext({} as LanguageContextValues)

function Provider({ children }: HocProps): ReactElement {
  const [lang, setLang] = useLang()

  const handleChangeLanguage = useCallback(
    (value: string) => {
      localStorage.setItem('lang', value)
      setLang(value)
    },
    [setLang],
  )

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      handleChangeLanguage(DEFAULT_LOCALE)
    }
  }, [handleChangeLanguage])

  const contextValues = {
    handleChangeLanguage, lang,
  }

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  )
}

export default Provider
