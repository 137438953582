import { createContext, ReactElement, useCallback, useEffect, useState } from 'react'

import { setCookie } from 'cookies-next'

import { useUser } from '@/hooks/use-user'
import { useUserSocket } from '@/hooks/use-user-socket'
import useSessionRefresh from '@/hooks/use-refresh-session'

type UserStoreContextValue = {
  referralEmail: string | undefined | null
  referralCode: string | undefined | null
}

const initContextState: UserStoreContextValue = {
  referralEmail: undefined,
  referralCode: undefined,
}

const UserStoreContext = createContext<UserStoreContextValue>(initContextState)

const UserStoreProvider = ({ children }: HocProps): ReactElement => {
  const [userStore, setUserStore] = useState<UserStoreContextValue>(initContextState)
  const { refetch: refetchUser } = useUser()
  const userSocketCallback = useCallback(
    (data: any) => {
      refetchUser()
    },
    [refetchUser],
  )

  useEffect(() => {
    if (!userStore.referralEmail) {
      const requestQuery = new URLSearchParams(document.location.search)
      if (requestQuery.get('referral') !== null) {
        setUserStore({
          ...userStore,
          referralEmail: requestQuery.get('referral'),
        })
      }
    }

    if (!userStore.referralCode) {
      const requestQuery = new URLSearchParams(document.location.search)
      const referralCode = requestQuery.get('referral_code')
      if (referralCode !== null) {
        setUserStore({
          ...userStore,
          referralCode,
        })
        setCookie('referral_code', referralCode)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useUserSocket(userSocketCallback)

  useSessionRefresh()

  return <UserStoreContext.Provider value={userStore}>{children}</UserStoreContext.Provider>
}

export default UserStoreProvider
