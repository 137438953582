import { useSession } from 'next-auth/react'
import { useQuery } from 'react-query'
import { authorizeRequest } from '../utils/apis'

interface IUseAuthorizeFetch {
  path: string
  method: string
  params?: Record<string, any>
  transformResponse?: (data: any, headers: any, status?: number) => any
  allowAnonymous?: boolean
  enabled?: boolean
}

export const useAuthorizeRequest = (props: IUseAuthorizeFetch) => {
  const { path, params, enabled = true } = props
  const { data: session, status, update } = useSession()

  return useQuery([path, params], () => {
    return authorizeRequest(props, session?.accessToken, update)
  }, { enabled: enabled && status !== 'loading', retry: 0 })
}

