import { createContext, useEffect, useState } from 'react'
import { useSession } from 'next-auth/react'
import { Consumer } from '@rails/actioncable'

type ActionCableContextType = {
  consumer: Consumer | undefined
}

const initContextState: ActionCableContextType = {
  consumer: undefined,
}

const websocketBaseURL = process.env.NEXT_PUBLIC_BACKEND_URL?.replace('https', 'wss').replace(
  'http',
  'ws',
)

const ActionCableContext = createContext<ActionCableContextType>(initContextState)

const ActionCableProvider = ({ children }: HocProps) => {
  const { data: session } = useSession()
  const [consumer, setConsumer] = useState<Consumer>()

  const loadConsumer = async () => {
    const { createConsumer } = await import('@rails/actioncable')
    return createConsumer
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && session?.accessToken && consumer === undefined) {
      loadConsumer().then((createConsumer) => {
        setConsumer(
          createConsumer(`${websocketBaseURL}/cable?access_token=${session?.accessToken}`),
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  const contextValue = {
    consumer,
  }

  return <ActionCableContext.Provider value={contextValue}>{children}</ActionCableContext.Provider>
}

export { ActionCableContext, ActionCableProvider }
