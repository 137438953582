import '@/wallet/ui/styles.scss'
import '@/root/src/styles/global.scss'
import '@fontsource-variable/inter'
import '@fontsource-variable/montserrat'

import { useMemo } from 'react'

import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import { SnackbarProvider } from 'notistack'
import PropTypes from 'prop-types'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import BootIntercom from '@/pages/_bootIntercom'
import LanguageProvider from '@/providers/language'
import SnackBar from '@/providers/notification'
import ThemeProvider from '@/providers/theme'
import UserStoreProvider from '@/providers/user-store'
import { createEmotionCache } from '@/utils/createEmotionCache'
import { isRunningInCypress, onIntercomWidgetHidden, onIntercomWidgetShowed } from '@/utils/helpers'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { ErrorBoundary, Provider } from '@rollbar/react'
import { IntercomProvider } from '@thanhvo102/react-use-intercom'

import { ActionCableProvider } from '../contexts/action-cable'
import NotificationProvider from '../providers/notification-provider'
import KycSuccessModal from '@/components/kyc-success-modal'
import { MenuProfileProvider } from '@/providers/menu-profile'
import { rollbarClientConfig } from '@/utils/rollbar'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache
}

const INTERCOM_APP_ID = isRunningInCypress
  ? Cypress.env('INTERCOM_APP_ID')
  : process.env.NEXT_PUBLIC_INTERCOM_APP_ID || ''

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const queryClient = useMemo(() => new QueryClient(), [])

  return (
    <Provider config={rollbarClientConfig}>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Instantwire</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=0"
            />
          </Head>
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            apiBase={`https://${INTERCOM_APP_ID}.intercom-messenger.com`}
            widgetUrl={'https://renec.foundation/intercom-shim.latest.js'}
            onHide={onIntercomWidgetHidden}
            onShow={onIntercomWidgetShowed}
          >
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps.dehydratedState}>
                <ThemeProvider>
                  <LanguageProvider>
                    <SnackbarProvider maxSnack={3} className="snackbar-container">
                      <SessionProvider session={pageProps.session}>
                        <ActionCableProvider>
                          <UserStoreProvider>
                            <BootIntercom />
                            <NextNProgress color="#1F5F8D" />
                            <MenuProfileProvider>
                              <NotificationProvider>
                                <Component {...pageProps} />
                                <KycSuccessModal />
                              </NotificationProvider>
                            </MenuProfileProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                          </UserStoreProvider>
                        </ActionCableProvider>
                      </SessionProvider>
                      <SnackBar />
                    </SnackbarProvider>
                  </LanguageProvider>
                </ThemeProvider>
              </Hydrate>
            </QueryClientProvider>
          </IntercomProvider>
        </CacheProvider>
      </ErrorBoundary>
    </Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
