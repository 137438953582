import { useRouter } from 'next/router'
import { useEffect, useState, useCallback } from 'react'
import useLocalStorage from './useLocalStorage'
import { setCookie } from 'cookies-next'

const useLang = () => {
  const router = useRouter()
  const [languageStored, setLanguageStored] = useLocalStorage('language', 'en')
  const [lang, setLang] = useState(languageStored)

  const changeLanguage = useCallback(() => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: lang })
  }, [router, lang])

  useEffect(() => {
    setLanguageStored(lang)
    changeLanguage()
    setCookie('locale', lang)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  return [lang, setLang] as const
}

export default useLang
